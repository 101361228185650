import { TS_ItemType } from './TS_ItemType';
import { TS_Item }     from './TS_Item';

export class TS_Hotel extends TS_Item {
    type = TS_ItemType.HOTEL;
    
    dateFrom : string;
    dateUntil : string;
    imageURL : string;
    description : string;
    variantID : string;
    variantTitle : string;
    itemCategory3: string;
    itemBrand: string;
    discountSumForAnalytics: number;
}