import i18next               from 'i18next';
import React                 from 'react';
import { Form, FormikProps } from 'formik';

import Row                 from 'Components/Forms/Row';
import { SalutationField } from 'Components/Forms/SelectField';
import TextField           from 'Components/Forms/TextField';
import { Keys }            from 'Translation/translations.de.json.keys';

import BirthdayFields from './BirthdayFields';
import { Passenger }  from '../Models/Passenger';
import { Meta }       from '../Models/Meta';
import styles         from './Forms.module.scss';
import SelectFieldOption from '../../../../Models/SelectFieldOption';
import { WorkflowService } from '../../../../State/WorkflowService';
import { NationalitySelect } from '../../../../Components/Forms/NationalitySelect';

export const PassengerForm : React.FunctionComponent<{
    property : string;
    label : string;
    passenger : Passenger;
    formikProps : FormikProps<Meta>;
    workflow : WorkflowService;
    countries : SelectFieldOption[],
}> = ( props ) => {

    const { passenger, property, label, formikProps, countries, workflow } = props;

    return (
        <Form className={ styles.form }>
            <Row label={ label } className={ styles.row }>
                <div>
                    <div className={ styles.fieldGroup }>
                        <div className={ styles.salutationContainer }>
                            <SalutationField
                                className={ styles.salutation }
                                label={ i18next.t( Keys.WORKFLOW.Participants.Forms.PassengerForm.title ) } name={ property + 'salutation' } isChild={ passenger.isChild() }/>
                        </div>
                        <div className={ styles.firstname }>
                            <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.PassengerForm.firstname ) } name={ property + 'firstname' }/>
                        </div>
                        <div className={ styles.surname }>
                            <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.PassengerForm.surname ) } name={ property + 'surname' }/>
                        </div>
                    </div>
                    <div className={ styles.fieldGroup100 }>
                        { (passenger.isChild() || passenger.dateOfBirthNeeded || !!workflow.showParticipantBirthday) &&
                            <BirthdayFields
                                day={ property + 'bday' }
                                month={ property + 'bmonth' }
                                year={ property + 'byear' }
                                formikProps={ formikProps }/>
                        }
                        {!!workflow.showParticipantNationality &&
                        <NationalitySelect  values={ countries } label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.nationality.label ) } name={ property + 'nationality' }/>
                        }
                    </div>
                    {passenger.passportNeeded && <div className={ styles.fieldGroupWrap }>
                        <div className={ styles.passportNumber }>
                            <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.PassengerForm.passportNumber ) } name={ property + 'passportNumber' }/>
                        </div>
                        <div className={ styles.passportValidThru }>
                            <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.PassengerForm.passportValidThru ) } name={ property + 'passportValidThru' }/>
                        </div>
                    </div>}
                </div>
            </Row>
        </Form>
    );
};