import React from 'react';

import { conditionalStyles } from 'Helpers/conditionalStyles';
import pepxpressStyles from './UpsellLayout-pepxpress.module.scss';
import pepxciteStyles from './UpsellLayout-pepxcite.module.scss';
import { ThemeProps } from '../../Context/AppContext';

interface Props {
  top?: JSX.Element;
  left?: JSX.Element;
  bottom?: JSX.Element;
  isTwoColumn?: boolean;
}

const UpsellLayout: React.FunctionComponent<Props & ThemeProps> = p => {
  const styles = p.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const css = conditionalStyles(styles);
  const isTwoColumn = p.isTwoColumn;

  const layout = css('layout', {
    'layout--2col': isTwoColumn
  });

  return (
    <div className={layout}>
      <div className={styles.top}>{p.top}</div>
      <div className={styles.leftRight}>
        <div className={styles.left}>{p.left}</div>
      </div>
      <div className={styles.bottom}>{p.bottom}</div>
    </div>
  );
};

export default UpsellLayout;
