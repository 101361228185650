import React, { FunctionComponent } from 'react';

import pepXpressStyles from './PepText-pepxpress.module.scss';
import pepXciteStyles from './PepText-pepxcite.module.scss';
import { withTheme } from '../Context/withTheme';
import { ThemeProps } from '../Context/AppContext';

interface Props {
    text?: string | null;
}

const PepText : FunctionComponent<Props & ThemeProps> = (props) => {
    const styles = props.theme === 'pepxcite' ? pepXciteStyles : pepXpressStyles;
    const getCustomText = (): JSX.Element => {
        const splitText: string[] | undefined = props.text!.split('X');
        return <span>
            {splitText && splitText[0]}<span className={ styles.x }>X</span>{splitText && splitText[1]}
        </span>;
    };

    return props.text ? getCustomText() : <span>
        pep<span className={ styles.x }>X</span>press
    </span>;
};

export default withTheme(PepText);