import { IsEmail, IsNotEmpty, MinLength } from 'class-validator';
import { ValidateIf }          from 'class-validator';
import i18next                 from 'i18next';
import { Keys }                from 'Translation/translations.de.json.keys';

import { CountryRestriction } from './CountryRestriction';
import { IsPhoneNumber } from './IsPhoneNumber';
import workflow             from 'State/WorkflowService';


export class BusinessPartner {

    affiliateMode : boolean;

    noCompanyNeeded? : boolean;

    salutation : string;

    @ValidateIf( bp => isAffiliate( bp ) )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.surname.empty ) } )
    surname : string;

    @ValidateIf( bp => isAffiliate( bp ) )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.firstname.empty ) } )
    firstname : string;

    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.street.empty ) } )
    street : string;

    @ValidateIf( bp => isAffiliate( bp ) && !bp.noCompanyNeeded )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.organisation.empty ) } )
    company : string;

    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.streetNumber.empty ) } )
    number : string;

    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.postcode.empty ) } )
    postcode : string;

    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.city.empty ) } )
    city : string;

    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.country.empty ) } )
    @CountryRestriction( { message : i18next.t( Keys.COMMON.Validation.country.empty ) } )
    country : string;

    @ValidateIf( _ => { return validateNationality() } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.nationality.empty ) } )
    @MinLength(1, { message : i18next.t( Keys.COMMON.Validation.nationality.empty ) } )
    nationality : string;

    @ValidateIf( bp => {
         return (bp.phone.length > 0)
    } )

    @IsPhoneNumber( { message : i18next.t( Keys.COMMON.Validation.phone.invalid ) } )
    phone : string;

    @ValidateIf( bp => isAffiliate( bp ) )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.email.empty ) } )
    @IsEmail( {}, { message : i18next.t( Keys.COMMON.Validation.email.invalid ) } )
    email : string;

    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.mobile.empty ) } )
    @IsPhoneNumber( { message : i18next.t( Keys.COMMON.Validation.mobile.invalid ) } )
    mobile : string;

    hasNewsletterSubscription : boolean;

    get isAffiliate() : boolean {
        return isAffiliate( this );
    }
}

function isAffiliate( bp : BusinessPartner | any ) {
    return !!(bp.affiliateMode && bp.affiliateMode);
}
function validateNationality() {
    return workflow.showParticipantNationality;
}

