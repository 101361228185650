import { registerDecorator, ValidationOptions } from "class-validator";

export function IsTSCompatible( validationOptions? : ValidationOptions ) {
    return function ( object : Object, propertyName : string ) {
        registerDecorator( {
            name         : "IsTSCompatible",
            target       : object.constructor,
            propertyName : propertyName,
            constraints  : [],
            options      : validationOptions,
            validator    : {
                validate( value ) {
                    //eslint-disable-next-line no-useless-escape
                    return !/[0-9\\!"§$%&/()*=?#´`_.,;:|\[\]<>^°]/.test( value );
                }
            }
        } );
    };
}