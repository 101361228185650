import { ValidationArguments } from 'class-validator';
import { ValidationOptions }   from "class-validator";
import { registerDecorator }   from "class-validator";
import PhoneNumber             from "awesome-phonenumber";

export function IsPhoneNumber( validationOptions? : ValidationOptions ) {
    return function ( object : Object, propertyName : string ) {
        registerDecorator( {
            name         : "IsPhoneNumber",
            target       : object.constructor,
            propertyName : propertyName,
            constraints  : [],
            options      : validationOptions,
            validator    : {
                validate( value, validationArguments : ValidationArguments ) {
                    
                    if ( !value ) {
                        return true;
                    }
                    
                    const object      = validationArguments.object as any;
                    const countryCode = object.country && 'x' !== object.country.toLowerCase() // omg TS
                                        ? object.country
                                        : undefined;
                    
                    var pn = new PhoneNumber( value, countryCode );
                    // console.log( 'IsPhoneNumber', value, countryCode, pn.toJSON() );
                    return pn.isValid();
                }
            }
        } );
    };
}
