import { TS_ItemType } from './TS_ItemType';
import { TS_Item }     from './TS_Item';

export class TS_Flight extends TS_Item {
    type = TS_ItemType.FLIGHT;

    dateFrom : string;
    dateUntil : string;
    timeFrom: string;
    timeUntil: string;
    imageURL : string;
    description : string;
    descriptionList : string[];
    variantID : string;
    variantTitle : string;
    departureAirport: string;
    destinationAirport: string;
    departureTime: string;
    arrivalTime: string;
    cabinClass: string;
    flightNumber: string;
    flightStopovers: Array<Array<string>> | undefined;
}
