import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Offer } from '../../../../Models/UI/Offer';
import { OfferCard } from './OfferCard';
import pepxpressStyles from '../Upsell-pepxpress.module.scss';
import pepxciteStyles from '../Upsell-pepxcite.module.scss';
import { ThemeProps } from '../../../../Context/AppContext';
interface Props {
  headline: string;
  offers: Offer[];
  button?: Offer;
}
export const OfferLayout = (props: Props & ThemeProps) => {
  const { offers, button, headline, theme } = props;
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;

  return (
    <>
      {offers && offers.length > 0 && (
        <>
          <h2 className={styles.headline}>{headline}</h2>

          <Row className="mb-3 mr-0">
            {offers.map((item, i) => (
              <Col xs={{ size: 12 }} sm={{ size: 6 }} md={{ size: 4 }} key={'offer' + i}>
                <OfferCard offer={item} theme={theme} />
              </Col>
            ))}
          </Row>

          {button && (
            <Row className="mb-5">
              <Col md="4">
                <Button block color="primary" onClick={() => window.open(button.infoURL, '_blank')}>
                  {button.title}
                </Button>
              </Col>
              <Col md="4"></Col>
              <Col md="4"></Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
