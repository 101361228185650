import React from 'react';
import { FaInfoCircle } from 'react-icons/all';
import { Collapse } from 'reactstrap';
import { PaymentType } from 'Models/UI/PaymentType';

import pepxpressStyles from './PaymentType-pepxpress.module.scss';
import pepxciteStyles from './PaymentType-pepxcite.module.scss';
import { ThemeProps } from '../../../Context/AppContext';
import { withTheme } from '../../../Context/withTheme';
import DOMPurify from "dompurify";

// import iconBankTransfer from './Icons/bank-transfer-update.png';
// import iconCreditCard   from './Icons/credit-card-update.png';
// import iconKlarna       from './Icons/klarna.png';

interface IconImgProperties {
  src: string;
  width?: number;
  height?: number;
}

interface PaymentTypeWithIconProps {
  pt: PaymentType;
  showInformationToggle?: boolean;
  // Toggle the Paymentinfo
  isVisible?: boolean;
  toggleVisibility: () => void;
}

const PaymentTypeWithIcon: React.FunctionComponent<
  PaymentTypeWithIconProps & ThemeProps
> = props => {
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const title = props.pt.title || '';
  const images = props.pt.imageURLs || [];

  const showInformationToggle = props.showInformationToggle || false;
  const hasDescription = props.pt.description || false;
  const isToggleAvailable = !!(showInformationToggle && hasDescription);
  const isCollapseVisible = props.isVisible || false;

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.title} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}} />
        {images.length > 0 && (
          <div className={styles.containerImageToggle}>
            {images.map((img, i) => {
              return (
                <div key={'paymentIcon_' + i} className={styles.icon} title={title}>
                  <img alt={'Symbol für ' + title} src={img} />
                </div>
              );
            })}

            {isToggleAvailable && (
              <div className={styles.toggleContainer}>
                <span className={styles.optionInfoLink} tabIndex={0} onClick={props.toggleVisibility}>
                  <FaInfoCircle />
                </span>
              </div>
            )}
          </div>
        )}

      </div>

      <div>
        <Collapse
          className={'mt-2'}
          isOpen={isCollapseVisible}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.pt.description) }}
        ></Collapse>
      </div>
    </div>
  );
    
  /* function toggle(e: React.MouseEvent) {
    e.preventDefault();
    setIsPaymentInfoVisible(!isPaymentInfoVisible);
  } */
};

export default withTheme(PaymentTypeWithIcon);
