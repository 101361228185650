import i18next from 'i18next';
import React, { useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { Alert, Button, FormGroup, Label } from 'reactstrap';
import { IsNotEmpty } from 'class-validator';
import { Form } from 'formik';

import { join } from 'Helpers/conditionalStyles';
import { SelectField } from 'Components/Forms/SelectField';
import { AddonEvent } from 'Models/UI/AddonEvent';
import { AddonInfoSeats, Day, TS_AddonEvent } from 'Models/TravelSeller/Models/TS_AddonEvent';
import SelectFieldOption from 'Models/SelectFieldOption';
import { Keys } from 'Translation/Setup';
import pepxpressStyles from './Component-pepxpress.module.scss';
import pepxciteStyles from './Component-pepxcite.module.scss';
import FormCollapsible from '../FormCollapsible';
import FormConfiguration from '../FormConfiguration';
import withFormik from '../withFormik';
import { withTheme } from '../../../../Context/withTheme';
import { ThemeProps } from '../../../../Context/AppContext';
import workflow from 'State/WorkflowService';
import app from 'State/AppService';
import { FaArrowRight } from 'react-icons/all';
import { plainToClass } from 'class-transformer';

const Key = Keys.WORKFLOW.AdditionalServices.AddonEvent;

export class AddonEventFormModel extends AddonEvent {
  @IsNotEmpty({ message: i18next.t(Key.selectedDateIsEmpty) })
  selectedDate: string;

  @IsNotEmpty({ message: i18next.t(Key.selectedVariantIsEmpty) })
  selectedVariant: string;
}

const defaults = new AddonEventFormModel();
defaults.selectedDate = '';
defaults.selectedVariant = '';
defaults.selectedShow = '';

const pls = { value: '', label: i18next.t(Key.defaultOption) };

const AddonEventFormInner = withFormik<AddonEventFormModel>(
  AddonEventFormModel,
  defaults
)(
  (props: {
    item: TS_AddonEvent;
    values: { selectedDate: string; selectedVariant: string; selectedShow: string };
    onChange: FormConfiguration<any>['onChange'];
    saveStep: () => void;
    styles?: {
      row?: string;
      row__withBorder?: string;
      row__withChildren?: string;
      iframe?: string;
      iframe__container?: string;
      button?: string;
      buttonBottomContainer?: string;
      buttonSmall?: string;
      formGroup?: string;
    };
  }) => {
    const { values: propsValues, onChange } = props;
    const [showHallPlan, setShowHallPlan] = useState<boolean>(false);
    const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
    const [floorPlanError, setFloorPlanError] = useState<boolean>(false);

    useEffect(() => {
      const timerRef = timer.current;
      return () => {
        if (!!timerRef) {
          clearTimeout(timerRef);
        }
      };
    }, []);

    let days: SelectFieldOption[] = useMemo(() => {
      return [
        pls,
        ...(Array.isArray(props.item.dates)
          ? props.item.dates
              .filter((day: Day) => !!day)
              .map((day: Day) => ({ value: day.date, label: day.date, id: '' }))
          : Object.keys(props.item.dates)
              .map(day => ({
                value: (props.item.dates as Record<string, Day>)[day]?.date,
                label: (props.item.dates as Record<string, Day>)[day]?.date,
                id: day
              }))
              .filter(day => !!day?.value && !!day?.label))
      ];
    }, [props.item.dates]);

    const selectedDate = useMemo(() => {
      return Array.isArray(props.item.dates)
        ? (props.item.dates as Day[]).find((day: Day) => day.date === props.values.selectedDate)
        : Object.entries(props.item.dates as Record<string, Day>)
            .filter(entry => !!entry[0])
            .find(([_, day]: [string, Day]) => day?.date === props.values.selectedDate)?.[1];
    }, [props.item.dates, props.values.selectedDate]);

    const variants = useMemo(() => {
      let currentVariants: SelectFieldOption[] = [pls];
      if (!!selectedDate) {
        currentVariants = [
          ...currentVariants,
          ...(Array.isArray(selectedDate.variants)
            ? selectedDate.variants.map(variant => ({ value: variant, label: variant }))
            : Object.keys(selectedDate.variants).map((key: string) => ({
                value: (selectedDate.variants as Record<string, string>)[key],
                label: (selectedDate.variants as Record<string, string>)[key]
              })))
        ];
      }
      return currentVariants;
    }, [selectedDate]);

    const addonId = useMemo(() => {
      return props.item.id;
    }, [props.item.id]);

    const showId = useMemo(() => {
      return !!selectedDate && !Array.isArray(selectedDate.variants)
        ? Object.entries(selectedDate.variants).find(([_, variant]: [string, string]) => {
            return props.values.selectedVariant === variant;
          })?.[0]
        : undefined;
    }, [props.values.selectedVariant, selectedDate]);

    const handleHallPlan = (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      if (!!addonId && !!showId) {
        app.activateLoader();
        setShowHallPlan(true);
        const newItem = plainToClass(
          AddonEventFormModel,
          Object.assign({}, propsValues, { selectedShow: showId })
        );
        onChange(newItem, {});
        timer.current = setTimeout(() => {
          if (app.isLoading) {
            app.deactivateLoader();
          }
        }, 15000);
      }
    };

    const validateOnApplySelection = (): boolean => {
      const foundItem = workflow.selectedItems.find(singleItem => singleItem.id === props.item.id);
      if (!!foundItem) {
        const numberOfPassengers = workflow.currentMeta?.passengers?.length || 0;
        return !(
          (foundItem.addonInfo as AddonInfoSeats)?.addonInfoSeats?.length !== numberOfPassengers ||
          !workflow.booking?.isValid
        );
      } else {
        return false;
      }
    };

    const applySelection = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
      e.preventDefault();
      e.stopPropagation();
      app.activateLoader();
      await props.saveStep();
      if (validateOnApplySelection()) {
        setFloorPlanError(false);
        setShowHallPlan(false);
        const newItem = plainToClass(
          AddonEventFormModel,
          Object.assign({}, propsValues, { selectedShow: showId })
        );
        onChange(newItem, {});
        app.deactivateLoader();
      } else {
        setFloorPlanError(true);
        app.deactivateLoader();
      }
    };

    return (
      <div>
        <Form>
          <div
            className={join(
              props.styles?.row || '',
              props.styles?.row__withBorder || '',
              props.styles?.row__withChildren || ''
            )}
          >
            <FormGroup>
              <Label for="selectedDate">{i18next.t(Key.day)}*</Label>
              <SelectField
                label={i18next.t(Key.day)}
                name={'selectedDate'}
                values={days}
                isDisabled={!!addonId && !!showId && showHallPlan}
              />
            </FormGroup>
            <FormGroup>
              <Label for="selectedVariant">{i18next.t(Key.time)}*</Label>
              <SelectField
                isDisabled={!selectedDate || (!!addonId && !!showId && showHallPlan)}
                label={i18next.t(Key.time)}
                name={'selectedVariant'}
                values={variants}
              />
            </FormGroup>
            {props.item?.floorplanReqired && !showHallPlan && (
              <FormGroup className={props.styles?.formGroup}>
                <Button
                  className={props.styles?.button}
                  color="primary"
                  disabled={!props.values.selectedDate || !props.values.selectedVariant}
                  onClick={handleHallPlan}
                >
                  {i18next.t(Key.chooseSeats)}
                  <FaArrowRight />
                </Button>
              </FormGroup>
            )}
          </div>
          {!!addonId && !!showId && showHallPlan && (
            <>
              {floorPlanError && (
                <Alert color="danger" className="my-3">
                  {i18next.t(Key.selectedShowWrongNrOfParticipants, {
                    seats: workflow.currentMeta?.passengers?.length || 0
                  })}
                </Alert>
              )}
              <div className={props.styles?.iframe__container}>
                <iframe
                  title="floorplan"
                  className={props.styles?.iframe}
                  src={`https://pepxpress.travelseller.net/render/stage_floorplan.x4?showid=${showId}&addonid=${addonId}&bsession=${workflow.bookingSessionId}`}
                  onLoad={() => app.deactivateLoader()}
                />
              </div>
              <div className={props.styles?.buttonBottomContainer}>
                <Button
                  className={join(
                    'mr-sm-2',
                    props.styles?.button || '',
                    props.styles?.buttonSmall || ''
                  )}
                  color="secondary"
                  onClick={(e: MouseEvent<HTMLButtonElement>): void => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowHallPlan(false);
                  }}
                >
                  {i18next.t(Key.cancel)}
                </Button>
                <Button
                  className={join(props.styles?.button || '', props.styles?.buttonSmall || '')}
                  color="primary"
                  onClick={applySelection}
                >
                  {i18next.t(Key.applySelection)}
                  <FaArrowRight />
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    );
  }
);

const AddonEventForm: React.FunctionComponent<FormConfiguration<AddonEventFormModel>> = (
  p: any & ThemeProps
) => {
  const styles = p.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;

  return (
    <div>
      <FormCollapsible item={p.item} isOpen={p.isSelected} isStartPrice={p.item.floorplanReqired}>
        <AddonEventFormInner {...p} styles={styles} />
      </FormCollapsible>
    </div>
  );
};
export default withTheme(AddonEventForm);
