import { Type }              from 'class-transformer';
import { IsDefined, IsNumber, IsString } from 'class-validator';
import { GtmDataLayerEvent } from 'Helpers/GtmDataLayer';
import {Ecommerce} from '../Objects/Ecommerce';

export class GtmCheckoutEvent implements GtmDataLayerEvent {

    @IsDefined()
    @IsString()
    event : string;

    @IsDefined()
    @IsString()
    checkout_step? : string;

    @IsNumber()
    value? : number;

    @IsDefined()
    @Type( () => Ecommerce )
    ecommerce? : Ecommerce;
}