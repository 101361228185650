import PaymentTypeWithIcon from 'Components/Workflow/PaymentTypeWithIcon/PaymentTypeWithIcon';
import i18next from 'i18next';
import { PayloadPayment } from 'Models/UI/PayloadPayment';
import React from 'react';
import { action, computed, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import {
  Alert,
  Button,
  Input,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { Element as ScrollTo, scroller } from 'react-scroll';
import workflow, { Workflow, WorkflowService } from 'State/WorkflowService';
import { DisplayableError, ErrorHandler } from 'State/ErrorHandler';
import { AppService } from 'State/AppService';
import { join, conditionalStyles } from 'Helpers/conditionalStyles';
import { getValidatedInstance } from 'Helpers/validate';
import PepText from 'Components/PepText';
import Price from 'Components/Workflow/Price';
import Headline from 'Components/Workflow/Headline';
import DefaultLayout from 'Components/Workflow/DefaultLayout';
import Cart from 'Components/Workflow/Cart';
import Nav from 'Components/Workflow/Nav';
import { PaymentType } from 'Models/UI/PaymentType';
import { BonuscardOrder } from 'Models/UI/BonuscardOrder';
import { Donation } from 'Models/UI/Donation';
import { Coupon } from 'Models/UI/Coupon';
import { BookingItemGroup } from 'Models/UI/BookingItemGroup';
import { BookingItem } from 'Models/UI/BookingItem';
import { ItemType } from 'Models/UI/ItemType';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import { Keys } from 'Translation/Setup';
import api from '../../../API';

import bonuscardImg from './Images/bonuscard.png';
import foxImg from './Images/fox.jpg';
import pepxpressStyles from './Payment-pepxpress.module.scss';
import pepxciteStyles from './Payment-pepxcite.module.scss';

import { Meta } from './Models/Meta';
import { SetBonuscardRequest } from './Requests/SetBonuscardRequest';
import { GetBonuscardResponse } from './Requests/GetBonuscardResponse';
import { SetDonationRequest } from './Requests/SetDonationRequest';
import { GetDonationResponse } from './Requests/GetDonationResponse';
import { SetCouponRequest } from './Requests/SetCouponRequest';
import { FaInfoCircle } from 'react-icons/fa';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {LanguageContext} from "../../../Helpers/useLanguage";
import { DonationSelect } from '../../../Components/Forms/DonationSelect';
import { Formik } from 'formik';
import IBANPaymentForm from './Forms/IBANPaymentForm';
import * as Yup        from 'yup';

interface Props {
  workflow?: WorkflowService;
  errorHandler?: ErrorHandler;
  app?: AppService;
};

const Key = Keys.WORKFLOW.Payment;

@inject('errorHandler')
@inject('app')
@inject('workflow')
@observer
class Payment extends React.Component<
  Props & ThemeProps,
  {
    paymentAccountHolder: string,
    paymentIban: string,
    paymentBic: string,
    paymentAccept: boolean
  }
> {
  genericErrorName = 'PaymentError';

  SEPA_CREDIT_TRANSFER_ID = '3107';
  SEPA_DIRECT_DEBIT_ID = '1107';
  CREDIT_CARD_AMEX = '12107';

  @observable meta: Meta = getInitialMeta();
  @observable bonuscardResponse: GetBonuscardResponse = getInitialBonuscardResponse();
  @observable donationItems: Donation[] = [];
  @observable selectedDonationItem: Donation | null = null;
  @observable couponCode: string = '';
  @observable apiErrorMessage: string | null = null;
  @observable couponErrorMessage: string | null = null;
  @observable bonuscardErrorMessage: string | null = null;
  @observable donationErrorMessage: string | null = null;
  @observable wasSubmitted: boolean = false;
  @observable isBonuscardInfoVisible: boolean = false;
  @observable isDonationInfoVisible: boolean = false;
  @observable closeErrorModal: boolean = false;
  @observable visiblePaymentTypeId: string | null = null;
  @observable isIbanPaymentChecked: boolean = false;
  formikRef: any;
  
  constructor(props: Props & ThemeProps) {
    super (props);
    this.state = {
      paymentAccountHolder: '',
      paymentIban: '',
      paymentBic: '',
      paymentAccept: false
    }
    this.formikRef = React.createRef();
  }

  static contextType = LanguageContext
  context!: React.ContextType<typeof LanguageContext>

  componentDidMount() {
    const app = this.props.app!;
    const workflow = this.props.workflow!;
    const errorHandler = this.props.errorHandler!;
    const currentStep = workflow.currentStep!;
    const booking = workflow.booking!;

    app.trackTimeLoadStarted(currentStep);
    this.updateStep()
      .then(({lang}) => Promise.all(lang === 'en' ? [this.getDonation()] : [this.getBonuscard(), this.getDonation()]))
      .then(() => {
        if (workflow.isCurrentStepInitiallyVisited && !this.isDonationSelected) {
          return this.toggleDonation();
        } else {
          return Promise.resolve();
        }
      })
      .then(() => app.deactivateLoader())
      .then(() => app.trackTimeLoadFinished(currentStep))
      .then(() => app.tracking.sendCheckoutStepEvent(booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes, workflow?.paymentItem?.title))
      .then(() => {
        const iban = this.getIbanPaymentType();
        if (!this.selectedPaymentType && iban) {
          this.toggleItem(iban);
        }
      })
      .then(() => {
        const sepa = this.getSepaPaymentType();
        if (!this.selectedPaymentType && sepa) {
          this.toggleItem(sepa);
        }
      })
      .then(() => window.scrollTo(0, 0))
      .catch(e => errorHandler.catchError(e));
  }

  async updateStep() {
    const { workflow, errorHandler } = this.props;
    return workflow!
      .loadStep()
      .then(({language}) => {
        const globalLanguage: string = this.context || language;
        if (!!globalLanguage) {
          i18next.changeLanguage(globalLanguage);
        }
        this.parseMeta.bind(this)();
        return {lang: globalLanguage};
      })
      .catch(e => {
        errorHandler!.catchError(e);
        return {lang: ''}
      });
  }

  async parseMeta() {
    const meta = await getValidatedInstance<Meta, {}>(Meta, toJS(this.props.workflow!.currentMeta));

    action(() => {
      this.meta = meta;
    })();
  }

  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    const css = conditionalStyles(styles);
    
    const validationSchema = Yup.object().shape({
      paymentAccountHolder: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Bitte vollständiger Name eingeben'),
      paymentIban: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Bitte IBAN eingeben')
        .test('valid-iban', 'Invalid IBAN', async value => {
          if (!value) return false; // Skip validation if value is empty (already caught by 'required')
          try {
            const response = await fetch(`https://openiban.com/validate/${value}?getBIC=true&validateBankCode=true`);
            const data = await response.json();
            //console.log(data);
            
            return data.valid;
          } catch (error) {
            console.error('Error validating IBAN:', error);
            return false; // Consider the IBAN invalid if the API call fails
          }
        }),
      paymentBic: Yup.string()
        .min(2, 'Too Short!')
        .max(15, 'Too Long!')
        .required('Bitte BIC eingeben'),
      paymentAccept: Yup.bool()
        .oneOf([true], 'Bezahlbestätigung erforderlich')
        .required()
    });

    const left = (
      <div className={styles.container}>
        {this.donationItems && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.donation.headline)}</h5>

            <p className={styles.optionInfoContainer}>
              <span>
                <Trans i18nKey={Key.donation.intro} />                  
              </span>
              <span
                className={styles.optionInfoLink}
                tabIndex={0}
                onClick={this.toggleDonationInfo.bind(this)}
                onKeyPress={this.toggleDonationInfoOnEnter.bind(this)}
              >
                <FaInfoCircle />
              </span>
            </p>

            <Collapse isOpen={this.isDonationInfoVisible}>
              <div className={styles.optionDescriptionBottom}>
                <div className={styles.descriptionFox}>
                  <div className={styles.text}>
                    <p>
                      <strong>{i18next.t(Key.donation.detail1)}</strong>
                    </p>
                    <p>
                      <Trans i18nKey={Key.donation.detail2}>
                        Seit vielen Jahren unterstützt <PepText /> soziale Projekte für in Not geratene Kinder in Thailand und Südafrika. Die jährlich gespendeten Gelder kamen unter anderem durch Spenden unserer Reisegäste zusammen, die bei ihrer Reisebuchung die Möglichkeit haben freiwillig zwischen 1 € und 10 € pro Buchung für die Unterstützung sozialer Projekte zu spenden.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey={Key.donation.detail3}>
                        Dieser Betrag wurde dann von <PepText /> verdoppelt, sodass in der
                        Vergangenheit jährlich über 20.000 EUR gespendet werden konnten.
                      </Trans>
                    </p>
                    <p>
                      <a
                        href={process.env.PUBLIC_URL + '/Kurzinfo_FoxFoundation.pdf'}
                        target={'blank'}
                      >
                        {i18next.t(Key.donation.link)}
                      </a>
                    </p>
                  </div>
                  <div className={styles.img}>
                    <img src={foxImg} alt={i18next.t(Key.donation.alt)} />
                  </div>
                </div>
              </div>
            </Collapse>

            <Alert color="danger" isOpen={!!this.donationErrorMessage}>
              {this.donationErrorMessage}
            </Alert>

            <div
              className={css('optionInput', 'optionBorder', {
                'optionBorder--isSelected': this.isDonationSelected
              })}
              tabIndex={0}
              onKeyPress={this.toggleDonationOnEnter.bind(this)}
            >
              <input
                type={'checkbox'}
                id={'donationInput'}
                onChange={this.toggleDonation.bind(this)}
                checked={this.isDonationSelected}
              />
              <label htmlFor={'donationInput'} className={styles.optionLabel}>
                <div className={styles.donationInput}>
                  <DonationSelect
                    donations={this.donationItems}
                    label={i18next.t(Key.donation.label)}
                    id="donation.select"
                    selectedDonation={this.selectedDonationItem}
                    disabled={!toJS(this.isDonationSelected)}
                    onToggle={this.toggleDonation.bind(this)}
                    onChange={selectedDonationOption => {
                      if (selectedDonationOption) {
                        this.setSelectedDonation(selectedDonationOption);
                        this.updateDonation();
                      }
                    }} />
                </div>
              </label>
            </div>
          </div>
        )}

        {this.props.theme === 'pepxpress' && this.bonuscardResponse.offerBonuscard && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.bonuscard.headline)}</h5>

            <p className={styles.optionInfoContainer}>
              <span>
                {i18next.t(Key.bonuscard.savings)}:&nbsp;
                <Price amount={this.bonuscardResponse.bonuscardInfo.bonuscardSaving} prefix={''} />
              </span>
              <span
                className={styles.optionInfoLink}
                tabIndex={0}
                onClick={this.toggleBonuscardInfo.bind(this)}
                onKeyPress={this.toggleBonuscardInfoOnEnter.bind(this)}
              >
                <FaInfoCircle />
              </span>
            </p>

            <Collapse isOpen={this.isBonuscardInfoVisible}>
              <div className={styles.optionDescriptionBottom}>
                <div className={styles.descriptionBonuscard}>
                  <div className={styles.text}>
                    <p>
                      <strong>{i18next.t(Key.bonuscard.detail1)}</strong>
                    </p>
                    <ul>
                      <li>{i18next.t(Key.bonuscard.detail2)}</li>
                      <li>{i18next.t(Key.bonuscard.detail3)}</li>
                      <li>
                        <Trans i18nKey={Key.bonuscard.detail4}>
                          Nutzung als <PepText /> Legitimation
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey={Key.bonuscard.detail5}>
                          30 Euro <PepText /> Reisegutschein zur Begrüßung
                        </Trans>
                      </li>
                      <li>{i18next.t(Key.bonuscard.detail6)}</li>
                      <li>
                        <Trans i18nKey={Key.bonuscard.detail7}>
                          1,5% Ermäßigung auf jede <PepText /> Buchung
                        </Trans>
                      </li>
                      <li>
                        <a href={'https://www.pep-ausweis.de/'} target={'blank'}>
                          {i18next.t(Key.bonuscard.link)}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.img}>
                    <img src={bonuscardImg} alt={i18next.t(Key.bonuscard.alt)} />
                  </div>
                </div>
              </div>
            </Collapse>

            <Alert color="danger" isOpen={!!this.bonuscardErrorMessage}>
              {this.bonuscardErrorMessage}
            </Alert>

            <div
              className={css('optionInput', 'optionBorder', {
                'optionBorder--isSelected': this.isBonuscardSelected
              })}
              tabIndex={0}
              onKeyPress={this.toggleBonuscardOnEnter.bind(this)}
            >
              <input
                type={'checkbox'}
                id={'bonuscardInput'}
                onChange={this.toggleBonuscard.bind(this)}
                checked={this.isBonuscardSelected}
              />
              <label htmlFor={'bonuscardInput'} className={styles.optionLabel}>
                <div>
                  <span>{i18next.t(Key.bonuscard.label)}</span>
                  <Price
                    amount={this.bonuscardResponse.bonuscardInfo.bonuscardCosts}
                    prefix={'+'}
                  />
                </div>
              </label>
            </div>
          </div>
        )}

        {workflow.workflowName !== Workflow.EVENT && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.coupon.headline)}</h5>

            {this.appliedCouponCodes.map((code, i) => (
              <div key={'code_' + i}>
                <Alert>
                  <div className={styles.codeApplied}>
                    <span>{i18next.t(Key.coupon.wasApplied, { code })}</span>
                    <Button outline onClick={this.removeCoupon.bind(this)} size="sm">
                      {i18next.t(Key.coupon.remove)}
                    </Button>
                  </div>
                </Alert>
              </div>
            ))}

            <Alert color="danger" isOpen={!!this.couponErrorMessage}>
              {this.couponErrorMessage}
            </Alert>

            {!this.isCouponApplied && (
              <div className={styles.coupon}>
                <Input
                  placeholder={i18next.t(Key.coupon.placeholder)}
                  value={this.couponCode}
                  onChange={this.couponChange.bind(this)}
                />
                <Button outline onClick={this.couponApply.bind(this)}>
                  {i18next.t(Key.coupon.apply)}
                </Button>
              </div>
            )}
          </div>
        )}

        <div className={join(styles['section'], styles['info'])}>
          <div className={join(styles.line, styles['line--bold'])}>
            <span>{i18next.t(Key.coupon.total)}</span>
            {this.props.app!.isLoading ? (
              <span>...</span>
            ) : (
              <Price amount={this.props.workflow!.booking!.totalPrice} prefix={''} />
            )}
          </div>

          {this.isDepositPossible && (
            <div className={styles.line}>
              <span>{i18next.t(Key.coupon.deposit)}</span>
              {this.props.app!.isLoading ? (
                <span>...</span>
              ) : (
                <Price amount={this.meta.payment.deposit} prefix={''} />
              )}
            </div>
          )}

          <div className={styles.texts}>
            {this.meta.payment.paymentTexts.map((t, i) => (
              <div className={styles.text} key={i}>
                {t}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.section}>
          <h5>{i18next.t(Key.types.headline)}</h5>

          <ScrollTo name={this.genericErrorName}>
            <Alert color="danger" isOpen={this.wasSubmitted && !this.selectedPaymentType}>
              {i18next.t(Key.types.alert)}
            </Alert>
            <Alert color="danger" isOpen={this.wasSubmitted && 
                                          this.props.workflow!.selectedItems
                                          .filter(Payment.onlyPaymentTypes)
                                          .filter(i => i.id === this.SEPA_DIRECT_DEBIT_ID)
                                          .length > 0}>
              {i18next.t(Key.types.ibanDataNotComplete.alert)}
            </Alert>
          </ScrollTo>

          {!this.isSepaAvailable && (
            <div className="mb-2">
              <div className={join(styles['section'], styles['info'])}>
                <div className={join(styles.line, styles['line--bold'])}>
                  <span>{i18next.t(Key.types.sepaNotAvailable.headline)}</span>
                </div>

                <div className={styles.texts}>{i18next.t(Key.types.sepaNotAvailable.text)}</div>
              </div>
            </div>
          )}

          {this.paymentTypes.map((pt: PaymentType) => {
            return (
              <div
                className={css('optionInput', 'optionBorder', {
                  'optionBorder--isSelected': this.isPaymentTypeSelected(pt)
                })}
                key={'paymenttype_' + pt.id}
                tabIndex={0}
                onKeyPress={this.toggleItemOnEnter.bind(this, pt)}
              >
                <input
                  type={'radio'}
                  id={'paymenttype_' + pt.id}
                  onChange={this.toggleItem.bind(this, pt)}
                  checked={this.isPaymentTypeSelected(pt)}
                />
                <label htmlFor={'paymenttype_' + pt.id} className={styles.optionLabelWithCollapse}>
                  <PaymentTypeWithIcon  pt={pt}
                                        showInformationToggle={true}
                                        isVisible={pt.id === this.visiblePaymentTypeId}
                                        toggleVisibility={() => this.setVisiblePaymentTypeId(pt.id)}
                  />
                  <div>
                    <Formik enableReinitialize={ true }
                            initialValues = { this.getInitialValues() }
                            innerRef={ this.formikRef }
                            validationSchema={ validationSchema }
                            onSubmit={ (values, actions) => this.handleSubmit(values, actions) }
                            render={ formikProps => <>
                        <Collapse 
                            className={'mt-2'}
                            isOpen={pt.id === this.SEPA_DIRECT_DEBIT_ID && this.isPaymentTypeSelected(pt)}>
                              <IBANPaymentForm formikProps={formikProps} agreement={pt.agreement} />
                        </Collapse>
                      </> 
                    }/>
                  </div>
                </label>
              </div>
            );
          })}
        </div>

        <Modal isOpen={!!this.props.workflow!.paymentErrorKey && !this.closeErrorModal} size="lg">
          <ModalHeader>{i18next.t(Key.modal.headline)}</ModalHeader>
          <ModalBody>
            <p>{i18next.t(Key.modal.body1)}</p>
            <p>{i18next.t(Key.modal.body2)}</p>
            <p>
              <b>{i18next.t(Key.modal.body3)}</b>
            </p>
            <p>
              <b>{i18next.t(Key.modal.body4)}</b>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.closeErrorModalDialog()}>
              {i18next.t(Key.modal.chooseOtherPayment)}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );

    const top = (
      <div>
        <Headline text={i18next.t(Key.headline)} />
        {this.props.workflow!.paymentErrorKey && (
          <Alert color={'danger'}>{i18next.t(this.props.workflow!.paymentErrorKey)}</Alert>
        )}
      </div>
    );
    
    const right = <Cart bookingOnRequest={workflow.bookingOnRequest} />;

    const bottom = <Nav onPrev={this.prev.bind(this)} onNext={this.next.bind(this)} />;
    
    return (
      <>
        <Breadcrumbs next={() => this.next()} />
        <DefaultLayout top={top} left={left} right={right} bottom={bottom} />
      </>
    );
  }

  @action
  getInitialValues() {
    const iban = this.getIbanPaymentType();
    const isSelected = workflow.selectedItems
                          .filter(Payment.onlyPaymentTypes)
                          .filter(i => i.id === this.SEPA_DIRECT_DEBIT_ID).length > 0;

    if (isSelected && iban && iban.paymentIban !== null) {
      return iban
    }
    return this.state;
  }

  @action
  setVisiblePaymentTypeId(id: string | null) {
    if (this.visiblePaymentTypeId === id) {
      // If the same ID is clicked again, hide the details
      this.visiblePaymentTypeId = null;
    } else {
      this.visiblePaymentTypeId = id;
    }
  }

  prev() {
    this.props.workflow!.navigateToPrevStep();
  }

  @action
  next() {
    if (this.selectedPaymentType) {
      if (this.formikRef) {
        if (!this.formikRef.current.durty) {
          this.wasSubmitted = true;
        }
        this.formikRef.current?.submitForm();
      } 
      this.selectedPaymentType.id !== this.SEPA_DIRECT_DEBIT_ID && this.props.workflow!.navigateToNextStep();
    } else {
      this.wasSubmitted = true;
      console.log('Was Submitted');
      
      scroller.scrollTo(this.genericErrorName, {
        duration: 1000,
        smooth: true,
        offset: -20
      });
    }
  }

  @action
  async handleSubmit( values : any, actions:any ) {
    this.props.app!.activateLoader();
    try {
      this.state = {
        paymentAccountHolder: values.paymentAccountHolder,
        paymentIban: values.paymentIban,
        paymentBic: values.paymentBic,
        paymentAccept: values.paymentAccept
      };
      this.saveStep();
      workflow.navigateToNextStep();
    } catch (e) {
      console.error(e);
      actions.setSubmitting(false);
    }
    this.props.app!.deactivateLoader();
  }

  @action closeErrorModalDialog() {
    this.closeErrorModal = true;
  }

  @action
  async toggleDonationOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleDonation();
    }
  }

  @action
  async toggleBonuscardOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleBonuscard();
    }
  }

  @action
  async toggleDonation() {
    this.props.app!.activateLoader();
    const params: SetDonationRequest = {
      donation: !toJS(this.isDonationSelected) ? toJS(this.selectedDonationItem) : null
    };
    await this.executeDonationRequest(params);
    this.props.app!.deactivateLoader();
  }

  @action
  async updateDonation() {
    this.props.app!.activateLoader();
    const donation = toJS(this.isDonationSelected) ? toJS(this.selectedDonationItem) : null;
    const params: SetDonationRequest = {
      donation
    };
    await this.executeDonationRequest(params);
    this.props.app!.deactivateLoader();
  }

  @action
  async executeDonationRequest(params: SetDonationRequest) {
    try {
      this.props.app!.trackTimeSaveStarted(this.props.workflow!);
      await api.post('/donation', params);
      await this.updateStep();
      this.props.app!.trackTimeSaveFinished(this.props.workflow!);
    } catch (e) {
      action(() => {
        if (e instanceof DisplayableError) {
          this.donationErrorMessage = e.message;
        } else {
          console.error(e);
          this.donationErrorMessage = i18next.t(Key.apiError);
        }
      })();
    }
  }

  @action
  async toggleDonationInfo() {
    this.isDonationInfoVisible = !this.isDonationInfoVisible;
  }

  @action
  async toggleDonationInfoOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleDonationInfo();
    }
  }

  @action
  async toggleBonuscardInfo() {
    this.isBonuscardInfoVisible = !this.isBonuscardInfoVisible;
  }

  @action
  async toggleBonuscardInfoOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleBonuscardInfo();
    }
  }

  @action
  async toggleBonuscard() {
    this.props.app!.activateLoader();

    const params: SetBonuscardRequest = {
      bonuscard: !toJS(this.isBonuscardSelected)
    };

    try {
      this.props.app!.trackTimeSaveStarted(this.props.workflow!);
      await api.post('/bonuscard', params);
      await this.updateStep();
      this.props.app!.trackTimeSaveFinished(this.props.workflow!);
    } catch (e) {
      action(() => {
        if (e instanceof DisplayableError) {
          this.bonuscardErrorMessage = e.message;
        } else {
          console.error(e);
          this.bonuscardErrorMessage = i18next.t(Key.apiError);
        }
      })();
    }

    //if ( this.props.app!.isDev && params.bonuscard !== this.isBonuscardSelected ) {
    //    action( () => {
    //        this.donationErrorMessage = 'Das Buchungsystem lügt! Sagt der Status wäre OK, aber es hat gar nicht geklappt.';
    //    } )();
    //}

    this.props.app!.deactivateLoader();
  }

  @action setSelectedDonation(donation: Donation) {
    this.selectedDonationItem = donation;
  }

  @computed
  get isDepositPossible(): boolean {
    return !!(this.meta.payment.deposit && this.meta.payment.deposit !== 0);
  }

  @computed
  get isBonuscardSelected(): boolean {
    const booking = this.props.workflow!.booking!;
    const items = booking.items.filter(Payment.onlyBonuscardOrder);
    return items.length > 0;
  }

  @computed
  get isDonationSelected(): boolean {
    const booking = this.props.workflow!.booking!;
    const items = booking.items.filter(Payment.onlyDonation);
    return items.length > 0;
  }

  @computed
  get isCouponApplied(): boolean {
    return this.appliedCouponCodes.length > 0;
  }

  @computed
  get appliedCouponCodes(): string[] {
    const booking = this.props.workflow!.booking!;
    const items = booking.items.filter(Payment.onlyCoupon);
    return items.map(i => {
      return i.title;
    });
  }

  @action
  couponChange(e: React.FormEvent<HTMLInputElement>) {
    this.couponCode = e.currentTarget.value;
  }

  @action
  async couponApply(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    const couponCode = toJS(this.couponCode);

    if (couponCode !== '') {
      this.props.app!.activateLoader();

      try {
        const coupon = new Coupon();
        coupon.gutscheincode = couponCode;

        const params: SetCouponRequest = { coupon };
        this.props.app!.trackTimeSaveStarted(this.props.workflow!);
        const response = await api.post('/coupon', params);

        if (
          response &&
          response.response &&
          response.response.code &&
          response.response.code !== 200
        ) {
          action(() => {
            this.couponErrorMessage = response.response.message;
          })();
        } else {
          action(() => {
            this.couponErrorMessage = '';
          })();
        }

        await this.updateStep();
        this.props.app!.trackTimeSaveFinished(this.props.workflow!);
      } catch (e) {
        action(() => {
          if (e instanceof DisplayableError) {
            this.couponErrorMessage = e.message;
          } else {
            console.error(e);
            this.couponErrorMessage = i18next.t(Key.apiError);
          }
        })();
      }
      this.props.app!.deactivateLoader();
    }
  }

  @action
  async removeCoupon() {
    this.props.app!.activateLoader();
    try {
      const params: SetCouponRequest = { coupon: null };
      this.props.app!.trackTimeSaveStarted(this.props.workflow!);
      await api.post('/coupon', params);
      await this.updateStep();
      this.props.app!.trackTimeSaveFinished(this.props.workflow!);
    } catch (e) {
      action(() => {
        if (e instanceof DisplayableError) {
          this.couponErrorMessage = e.message;
        } else {
          console.error(e);
          this.couponErrorMessage = i18next.t(Key.apiError);
        }
      })();
    }
    this.props.app!.deactivateLoader();
  }

  isPaymentTypeSelected(item: BookingItem) {
    const isSelected =
      this.props
        .workflow!.selectedItems.filter(Payment.onlyPaymentTypes)
        .filter(i => i.id === item.id).length > 0;
    return isSelected;
  }

  async saveStep() {
    this.props.app!.activateLoader();
    this.props.app!.trackTimeSaveStarted(this.props.workflow!);

    action(() => {
      this.apiErrorMessage = null;
    })();

    let paymentTypeId = null;
    const paymentTypes = toJS(this.props.workflow!.selectedItems).filter(Payment.onlyPaymentTypes);
    if (paymentTypes.length === 1) {
      paymentTypeId = paymentTypes[0].id;
    }

    const payload = new PayloadPayment();
    payload.paymentType = paymentTypeId || '';
    
    payload.paymentAccountHolder = toJS(this.state.paymentAccountHolder) || '';
    payload.paymentIban = toJS(this.state.paymentIban) || '';
    payload.paymentBic = toJS(this.state.paymentBic) || '';

    await this.props.workflow!.saveStep(payload);
    console.log('payload Erweitert! ', payload);

    await this.parseMeta();
    this.props.app!.trackTimeSaveFinished(this.props.workflow!);
    this.props.app!.deactivateLoader();
  }

  static onlyPaymentTypes(i: any) {
    return i instanceof PaymentType;
  }

  static onlyBonuscardOrder(i: any) {
    return i instanceof BonuscardOrder;
  }

  static onlyDonation(i: any) {
    return i instanceof Donation;
  }

  static onlyCoupon(i: any) {
    return i instanceof Coupon;
  }

  @computed get paymentTypeGroup(): BookingItemGroup | null {
    const groups = this.props.workflow!.stepItems.filter(
      g => g.id === 'api-u-no-give-id-for-payment-group'
    );
    if (groups.length === 1) {
      return groups[0];
    }
    return null;
  }

  @computed get paymentItems(): PaymentType[] {
    if (!this.paymentTypeGroup) {
      return [];
    }
    return this.paymentTypeGroup.items.map(p => p as PaymentType);
  }

  // TODO ugly, but there is no better way to identify "sepa credit transfer"
  @computed get paymentTypes(): PaymentType[] {
    if (this.paymentItems) {
      // Sort the payments
      const paymentItems_reversed = this.paymentItems.sort().reverse();
      const paymentTypes = paymentItems_reversed.filter(
        pt => pt.id !== this.SEPA_DIRECT_DEBIT_ID && pt.id !== this.CREDIT_CARD_AMEX);

      const sepa_direct_debit = this.paymentItems.filter(pt => pt.id === this.SEPA_DIRECT_DEBIT_ID);
      const creditcard_amex = this.paymentItems.filter(pt => pt.id === this.CREDIT_CARD_AMEX);
      if (sepa_direct_debit.length === 1) {
        paymentTypes.unshift(sepa_direct_debit[0]);
      }
      if (creditcard_amex.length === 1) {
        paymentTypes.push(creditcard_amex[0]);
      }
      return paymentTypes;
    }
    return [];
  }

  getSepaPaymentType(): PaymentType | null {
    if (this.isSepaAvailable) {
      if (this.paymentTypes) {
        return this.paymentTypes.filter(pt => pt.id === this.SEPA_CREDIT_TRANSFER_ID)[0];
      }
    }
    return null;
  }
  
  @computed get isSepaAvailable(): boolean {
    if (this.paymentTypes) {
      return this.paymentTypes.filter(pt => pt.id === this.SEPA_CREDIT_TRANSFER_ID).length > 0;
    }
    return false;
  }

  getIbanPaymentType(): PaymentType | null {
    if (this.isIbanAvailable) {
      if (this.paymentTypes) {
        return this.paymentTypes.filter(pt => pt.id === this.SEPA_DIRECT_DEBIT_ID)[0];
      }
    }
    return null;
  }

  @computed get isIbanAvailable(): boolean {
    if (this.paymentTypes) {
      return this.paymentTypes.filter(pt => pt.id === this.SEPA_DIRECT_DEBIT_ID).length > 0;
    }
    return false;
  }

  async toggleItemOnEnter(item: BookingItemInterface, ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      this.toggleItem(item);
    }
  }

  toggleItem(item: BookingItemInterface) {
    this.props.app!.activateLoader();

    toJS(this.props.workflow!.selectedItems)
      .filter(Payment.onlyPaymentTypes)
      .forEach(i => this.props.workflow!.removeItem(i.id));

    this.props.workflow!.addItem<PaymentType>(PaymentType, item.id);

    this.saveStep()
      .catch(e => {
        action(() => {
          this.apiErrorMessage = e.message;
        })();
      })
      .then(() => {
        action(() => {
          this.props.app!.deactivateLoader();
        })();
      });
  }

  public async getBonuscard() {
    const plain = await api.get('/bonuscard', null);
    const bonuscardResponse = await getValidatedInstance<GetBonuscardResponse, {}>(
      GetBonuscardResponse,
      plain
    );

    action(() => {
      this.bonuscardResponse = bonuscardResponse;
    })();
  }

  public async getDonation() {
    let donationItems: Donation[] = [];
    let res: GetDonationResponse | null;

    const plain = await api.get('/donation', null);

    try {
      res = await getValidatedInstance<GetDonationResponse, {}>(GetDonationResponse, plain);

      if (res.response) {
        const donationItemInstances = res.response.map(item => getValidatedInstance<Donation, {}>(Donation, item));
        donationItems = await Promise.all(donationItemInstances);
      }
    } catch (e) {
      donationItems = [];
    }

    if (donationItems) {
      action(() => {
        this.donationItems = donationItems;
        this.selectedDonationItem = donationItems[0];
      })();
    } else {
      console.warn('No donations:');
    }
  }

  @computed
  get selectedPaymentType(): PaymentType | null {
    try {
      return this.props.workflow!.selectedItems.filter(
        i => i.type === ItemType.PAYMENT
      )[0] as PaymentType;
    } catch (e) {
      return null;
    }
  }
}

function getInitialMeta(): Meta {
  return {
    payment: {
      deposit: 0,
      depositPercentage: 0,
      paymentTexts: []
    }
  };
}

function getInitialBonuscardResponse(): GetBonuscardResponse {
  return {
    offerBonuscard: false,
    bonuscardInfo: {
      bonuscardCosts: 0,
      bonuscardSaving: 0
    }
  };
}
export default withTheme(Payment);