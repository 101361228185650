import mapAddonTransferOnewayOutbound from 'Models/Converter/AddonTransferOnewayOutbound/mapUiToTs';
import mapAddonTransferOnewayInbound from 'Models/Converter/AddonTransferOnewayInbound/mapUiToTs';
import mapAddonTransferRoundtrip from 'Models/Converter/AddonTransferRoundtrip/mapUiToTs';
import { TS_Request } from 'Models/TravelSeller/Models/TS_Request';
import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import { AddonTransferOnewayInbound } from 'Models/UI/AddonTransferOnewayInbound';
import { AddonTransferRoundtrip } from 'Models/UI/AddonTransferRoundtrip';
import { PayloadAddons } from 'Models/UI/PayloadAddons';
import { PayloadParticipants } from 'Models/UI/PayloadParticipants';
import { Request } from 'Models/UI/Request';
import { Mapping } from 'Helpers/Mapping';

export async function UiToTravelSellerConverter(input: Request): Promise<TS_Request> {
  const mapping = new Mapping<Request, TS_Request>();
  return mapping.of(Request, input).to(TS_Request).withValidation(converter);
}

async function converter(input: Request): Promise<TS_Request> {
  const output = new TS_Request();

  output.for = input.for;
  output.payload = input.payload;

  if (output.payload instanceof PayloadParticipants) {
    output.payload = updateChildSalutation(output.payload);
  }

  if (output.payload instanceof PayloadAddons) {
    output.payload = await mapItems(output.payload);
  }

  return output;
}

function updateChildSalutation(payload: PayloadParticipants): PayloadParticipants {
  payload.passengers = payload.passengers.map(p => {
    if (p.isChild()) {
      p.salutation = '3';
    }
    return p;
  });

  return payload;
}

async function mapItems(payload: PayloadAddons): Promise<PayloadAddons> {
  payload.addons = await Promise.all(
    payload.addons.map(async a => {
      if (a instanceof AddonTransferRoundtrip) {
        return mapAddonTransferRoundtrip(a);
      } else if (a instanceof AddonTransferOnewayOutbound) {
        return mapAddonTransferOnewayOutbound(a);
      } else if (a instanceof AddonTransferOnewayInbound) {
        return mapAddonTransferOnewayInbound(a);
      }
      return a;
    })
  );

  return payload;
}
