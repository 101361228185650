import { SelectField, SelectFieldProps } from 'Components/Forms/SelectField';
import React                             from 'react';
import { useTranslation }                from 'react-i18next';
import { Keys }                          from 'Translation/Setup';

export const NationalitySelect = ( p : SelectFieldProps ) => {

    const { t } = useTranslation();

    return (
        <SelectField { ...p } values={
            [ {
                value : '',
                label : t( Keys.COMPONENTS.NationalitySelect.plsSelect )
            }, ...p.values! ] }/>
    );
};
