import React           from 'react';
import transitionEvent from 'Helpers/transitionEvent';

export default class InitialLoadingScreenRemover extends React.Component<{}, {}> {
    
    private preloader : HTMLElement | null = null;
    private id : string                    = 'preloader';
    private keepTimeout : number           = 500;
    private fallbackTimeout : number       = 1500;
    
    constructor( props : {} ) {
        super( props );
        this.onTransitionEnd = this.onTransitionEnd.bind( this );
    }
    
    componentWillUnmount() {
        if ( this.preloader ) {
            this.preloader.style.display = 'block';
            this.preloader.className     = '';
        }
    }
    
    componentDidMount() {
        this.preloader = document.getElementById( this.id );
        
        if ( this.preloader ) {
            if ( transitionEvent ) {
                this.preloader.addEventListener( transitionEvent, this.onTransitionEnd );
            } else {
                setTimeout( this.onTransitionEnd.bind( this ), this.fallbackTimeout );
            }
            
            setTimeout( () => {
                this.preloader!.className = 'off';
            }, this.keepTimeout );
        }
    }
    
    onTransitionEnd() {
        if ( this.preloader ) {
            this.preloader.style.display = 'none';
            this.preloader.removeEventListener( transitionEvent, this.onTransitionEnd );
        }
    }
    
    render() {
        return this.props.children;
    }
}