// typescript + moment. convienience my ass
import isEmpty from 'validator/lib/isEmpty';
import moment  from 'moment';

export function toValidMomentOrNothing( str : any, format : string ) : moment.Moment | null {

    if ( !str || isEmpty( str ) || typeof str === 'undefined' ) {
        return null;
    }
    
    const m = moment( str, format, true );
    
    return m.isValid() ? m : null;
    
}

export function toValidFormatOrNothing( str : any,
                                        inputFormat : string,
                                        outputFormat : string ) : string | null {

    const m = toValidMomentOrNothing( str, inputFormat );
    
    return m ? m.format( outputFormat ) : null;
}

export function isValidMomentFormat( value : any, format : string ) : boolean {
    return value && value !== ''
           ? moment( value, format, true ).isValid()
           : false;
}

