import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { Offer } from "../../../Models/UI/Offer";
import workflow from "../../../State/WorkflowService";
import { OfferFlight } from "../../../Models/UI/OfferFlight";
import { OfferHotel } from "../../../Models/UI/OfferHotel";
import { BookingItemGroup } from "../../../Models/UI/BookingItemGroup";
import { OfferMoreHotel } from "../../../Models/UI/OfferMoreHotel";
import { OfferMoreFlight } from "../../../Models/UI/OfferMoreFlight";
import { OfferMoreEvent } from "../../../Models/UI/OfferMoreEvent";
import { OfferEvent } from "../../../Models/UI/OfferEvent";
import {OfferBanner} from "../../../Models/UI/OfferBanner";
import {Banner} from "../../../Models/UI/Banner";



export class UpsellState {
  @observable ready: boolean = false;
  @observable apiErrorMessage: string | null = null;

  @action readyNow() {
    this.ready = true;
  }

  @computed get itemGroup(): BookingItemGroup | null {
    const groups = workflow.stepItems.filter(
      g => g.id === "api-u-no-give-id-for-offer-group"
    );
    if (groups.length === 1) {
      return groups[0];
    }
    return null;
  }

  @computed get items(): Offer[] {
    if(this.itemGroup) {
      return this.itemGroup.items.map(item => item as Offer);
    }
    return [];
  }

  @computed get hotels() {
    return this.items.filter((i: any) => {
      return i instanceof OfferHotel;
    });
  }
  @computed get hotelMore() {
    return this.items.filter((i: any) => {
      return i instanceof OfferMoreHotel;
    })[0];
  }

  @computed get flights() {
    return this.items.filter((i: any) => {
      return i instanceof OfferFlight;
    });
  }

  @computed get flightMore() {
    return this.items.filter((i: any) => {
      return i instanceof OfferMoreFlight;
    })[0];
  }

  @computed get events() {
    return this.items.filter((i: any) => {
      return i instanceof OfferEvent;
    });
  }

  @computed get eventMore() {
    return this.items.filter((i: any) => {
      return i instanceof OfferMoreEvent;
    })[0];
  }

  @computed get banners() {
    return this.items.filter((i: any) => {
      return i instanceof OfferBanner;
    }) as Banner[];
  }
}

export const upsellState = createContext(new UpsellState());
