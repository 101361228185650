/*
  
  var r = { a:1, b: {b1:11, b2: 99}};
  var s = "b.b2";
  
  var value = s.split('.').reduce((a, b) => a[b], r);
  --> 99
  
  * */

type ObjectOrProp = { [ key : string ] : {} | any };

export function getPropertyByPath( dotNotation : string, obj : ObjectOrProp ) : boolean | ObjectOrProp {
    try {
        return dotNotation.split( '.' ).reduce( ( a : { [ key : string ] : {} }, b : string ) => a[ b ], obj );
    } catch ( e ) {
        return false;
    }
}