import md5 from 'md5';

import { GtmDataLayer }                  from 'Helpers/GtmDataLayer';
import { Booking }                       from 'Models/UI/Booking';
import { GtmCheckoutEvent }    from './Events/GtmCheckoutEvent';
import { Page }                from './Objects/Page';
import { User }                from './Objects/User';
import { BookingItemInterface } from '../UI/BookingItemInterface';
import {Ecommerce} from "./Objects/Ecommerce";
import {EcommerceItem} from "./Objects/EcommerceItem";
import {ItemType} from "../UI/ItemType";
import {action} from "mobx";
import {PageEvent} from "./Events/PageEvent";

export class DataLayer extends GtmDataLayer {

    private user : User;

    public setUser( email : string ) {
        const u   = new User();
        u.id      = md5( email );
        u.ktype   = '';
        u.super_ktype = '';
        this.user = u;
    }

    private createEcommerce(booking: Booking | null, availableAdditionalServicesTypes: BookingItemInterface[]): Ecommerce {
        const bookingItems = booking?.items || [];
        const mainItem = bookingItems.find(item => item.type === ItemType.HOTEL);
        const roomItems = bookingItems.filter(item => item.type === ItemType.ROOM);
        const insuranceItem = bookingItems.find(item => item.type === ItemType.INSURANCE);
        const donationItem = bookingItems.find(item => item.type === ItemType.DONATION);
        const additionalServiceItems = availableAdditionalServicesTypes.filter(service => (!!booking?.items &&
          booking?.items?.filter(item => item.type === service.type)?.length > 0) && service.type !== ItemType.CATERING);
        const trainItem = bookingItems.find(item => item.type === ItemType.TRAIN);
        const flightItems = bookingItems.filter(item => item.type === ItemType.FLIGHT);
        const arrivalTotalPrice = [...flightItems, trainItem].reduce((total: number, current) =>
          total + (current?.totalPrice || 0), 0);

        let flightType = '';
        const ecommerce = new Ecommerce();
        let finalProductPrice = (mainItem?.totalPrice ? Number(mainItem?.totalPrice) + arrivalTotalPrice : 0) - (typeof mainItem?.discountSumForAnalytics === 'string' ?
          parseFloat(mainItem?.discountSumForAnalytics) :
          (mainItem?.discountSumForAnalytics || 0));
        if (finalProductPrice < 0) {
            finalProductPrice = 0;
        }
        // product
        const ecommerceItem = new EcommerceItem();
        ecommerceItem.item_id = mainItem?.id || '';
        ecommerceItem.item_name = mainItem?.title || '';
        ecommerceItem.currency = 'EUR';
        ecommerceItem.discount = mainItem?.discountSumForAnalytics || 0;
        ecommerceItem.index = 0;
        ecommerceItem.price = finalProductPrice;
        ecommerceItem.quantity = mainItem?.count || 1;
        ecommerceItem.location_id = booking?.destination || '';
        ecommerceItem.item_brand = mainItem?.itemBrand || '';
        ecommerceItem.item_variant = mainItem?.variantTitle || '';
        ecommerceItem.item_category = booking?.workflow || '';
        ecommerceItem.item_category2 = roomItems.map(roomItem => roomItem?.itemCategory2 || '').join(',');
        ecommerceItem.item_category3 = mainItem?.itemCategory3 || ''
        ecommerceItem.item_category4 = flightType;
        ecommerceItem.item_category5 = flightItems.length > 0 ? flightItems.reduce((total: string, current: BookingItemInterface, idx: number) => {
            return `${total}${current.flightNumber || ''}-${current.departureAirport || ''}-${current.destinationAirport ||''}${idx < flightItems.length - 1 ? ' | ' : ''}`;
        }, '') : '';
        ecommerce.items = [ecommerceItem];

        // insurance
        if (!!insuranceItem) {
            let finalPrice = (insuranceItem?.totalPrice || 0) - (typeof insuranceItem?.discountSumForAnalytics === 'string' ?
              parseFloat(insuranceItem?.discountSumForAnalytics) :
              (insuranceItem?.discountSumForAnalytics || 0));
            if (finalPrice < 0) {
                finalPrice = 0;
            }
            const ecommerceInsuranceItem = new EcommerceItem();
            ecommerceInsuranceItem.item_id = insuranceItem?.id || '';
            ecommerceInsuranceItem.item_name = insuranceItem?.title || '';
            ecommerceInsuranceItem.currency = 'EUR';
            ecommerceInsuranceItem.index = 1;
            ecommerceInsuranceItem.discount = insuranceItem?.discountSumForAnalytics || 0;
            ecommerceInsuranceItem.item_brand = insuranceItem?.itemBrand || '';
            ecommerceInsuranceItem.price = finalPrice;
            ecommerceInsuranceItem.quantity = insuranceItem?.count || 0;
            ecommerceInsuranceItem.item_variant = mainItem?.variantTitle || '';
            ecommerceInsuranceItem.location_id = booking?.destination || '';
            ecommerceInsuranceItem.item_category = '';
            ecommerceInsuranceItem.item_category2 = insuranceItem?.itemCategory2 || '';
            ecommerceInsuranceItem.item_category3 = insuranceItem?.itemCategory3 || '';
            ecommerceInsuranceItem.item_category4 = flightType;
            ecommerceInsuranceItem.item_category5 = flightItems.length > 0 ? flightItems.reduce((total: string, current: BookingItemInterface, idx: number) => {
                return `${total}${current.flightNumber || ''}-${current.departureAirport || ''}-${current.destinationAirport ||''}${idx < flightItems.length - 1 ? ' | ' : ''}`;
            }, '') : '';
            ecommerce.items = [...ecommerce.items, ecommerceInsuranceItem];
        }

        const additionalBookedItems = [...additionalServiceItems];
        if (!!trainItem) {
            additionalServiceItems.push(trainItem);
        }
        if (!!insuranceItem) {
            additionalServiceItems.push(insuranceItem);
        }
        if (!!donationItem) {
            additionalServiceItems.push(donationItem);
        }
        const additionalEcommerceItems = additionalBookedItems.map((additionalItem, idx) => {
            let additionalFinalPrice = (additionalItem?.totalPrice || 0) - (typeof additionalItem?.discountSumForAnalytics === 'string' ?
              parseFloat(additionalItem?.discountSumForAnalytics) :
              (additionalItem?.discountSumForAnalytics || 0));
            if (additionalFinalPrice < 0) {
                additionalFinalPrice = 0;
            }
            const ecommerceItem = new EcommerceItem();
            ecommerceItem.item_id = additionalItem.id || '';
            ecommerceItem.item_name = additionalItem.title || '';
            ecommerceItem.currency = 'EUR';
            ecommerceItem.discount = additionalItem?.discountSumForAnalytics || 0;
            ecommerceItem.index = idx + 1;
            ecommerceItem.price = additionalFinalPrice;
            ecommerceItem.location_id = booking?.destination || '';
            ecommerceItem.quantity = additionalItem.count || 1;
            ecommerceItem.item_brand = '';
            ecommerceItem.item_variant = additionalItem?.variantTitle || '';
            ecommerceItem.item_category = '';
            ecommerceItem.item_category2 = '';
            ecommerceItem.item_category3 = '';
            ecommerceItem.item_category4 = '';
            ecommerceItem.item_category5 = '';
            return ecommerceItem;
        })

        ecommerce.items = [...ecommerce.items, ...additionalEcommerceItems];
        return ecommerce;
    }

    public addPageAndUser(booking : Booking | null) {
        const currentLayer = this.getDataLayer();
        if (!currentLayer.some(event => !!event?.page?.name && event.page.name === '/checkout')) {
          const workflow = booking?.workflow && booking?.workflow !== '' ? booking.workflow : 'unknown';
          const page = new Page();
          page.name = '/checkout';
          page.type = 'checkout';
          page.language = 'de';
          page.country = 'DE';
          page.IBE = workflow;
          page.status = '';
          const pageEvent = new PageEvent();
          pageEvent.page = page;
          pageEvent.user = this.user;

          super.push<PageEvent>(pageEvent);
        }
    }

    @action
    public sendBeginCheckoutEvent(booking: Booking | null, checkoutStepName: string, availableAdditionalServicesTypes: BookingItemInterface[]) {
        this.clearDataLayer();

        const checkoutEvent = new GtmCheckoutEvent();
        checkoutEvent.event = 'begin_checkout';
        checkoutEvent.checkout_step = checkoutStepName;
        checkoutEvent.value = booking?.totalPrice || 0;
        checkoutEvent.ecommerce = this.createEcommerce(booking, availableAdditionalServicesTypes);
        checkoutEvent.ecommerce.currency = 'EUR';

        super.push<GtmCheckoutEvent>(checkoutEvent);
    }

    @action
    public sendCheckoutStepEvent(booking: Booking | null, checkoutStepName: string, availableAdditionalServicesTypes: BookingItemInterface[], paymentType?: string) {
        this.clearDataLayer();

        const checkoutEvent = new GtmCheckoutEvent();
        checkoutEvent.event = 'checkout_custom';
        checkoutEvent.checkout_step = checkoutStepName;
        if (checkoutStepName !== "übersicht") {
            checkoutEvent.value = booking?.totalPrice || 0;
        }
        checkoutEvent.ecommerce = this.createEcommerce(booking, availableAdditionalServicesTypes);
        if (checkoutStepName === "übersicht") {
            checkoutEvent.ecommerce.value = booking?.totalPrice || 0;
        }
        checkoutEvent.ecommerce.currency = 'EUR';
        checkoutEvent.ecommerce.payment_type = paymentType || '';

        super.push<GtmCheckoutEvent>(checkoutEvent);
    }

    @action
    public sendPurchaseEvent(booking: Booking | null, checkoutStepName: string, availableAdditionalServicesTypes: BookingItemInterface[], paymentType: string, coupon?: string, coupon_value?: number) {
        this.clearDataLayer();

        const checkoutEvent = new GtmCheckoutEvent();

        checkoutEvent.event = 'purchase';
        checkoutEvent.checkout_step = checkoutStepName;
        checkoutEvent.ecommerce = this.createEcommerce(booking, availableAdditionalServicesTypes);
        checkoutEvent.ecommerce.currency = 'EUR';
        checkoutEvent.ecommerce.value = booking?.totalPrice || 0;
        checkoutEvent.ecommerce.tax = booking?.tax || 0;
        checkoutEvent.ecommerce.shipping = booking?.shipping || 0;
        checkoutEvent.ecommerce.transaction_id = booking?.bookingNumber || '';
        checkoutEvent.ecommerce.payment_type = paymentType || '';
        checkoutEvent.ecommerce.coupon = coupon || '';
        checkoutEvent.ecommerce.coupon_value = coupon_value || 0;
        super.push<GtmCheckoutEvent>(checkoutEvent);
    }
}
