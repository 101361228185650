import { IsNumber }  from 'class-validator';
import { IsString }  from 'class-validator';
import { IsDefined } from 'class-validator';

export class EcommerceItem {

    @IsDefined()
    @IsString()
    item_id : string;

    @IsDefined()
    @IsString()
    item_name : string;

    @IsDefined()
    @IsString()
    currency : string;

    @IsNumber()
    discount : number;

    @IsDefined()
    @IsNumber()
    index : number;

    @IsString()
    item_brand : string;

    @IsString()
    item_category : string;

    @IsString()
    item_category2 : string;

    @IsString()
    item_category3 : string;

    @IsString()
    item_category4 : string;

    @IsString()
    item_category5 : string;

    @IsString()
    location_id : string;

    @IsDefined()
    @IsString()
    item_variant : string;

    @IsDefined()
    @IsNumber()
    price : number;

    @IsDefined()
    @IsNumber()
    quantity : number;
}