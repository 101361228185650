import axios, { AxiosResponse, Method } from 'axios';

import { TravelSellerToUiConverter } from 'Models/Converter/TravelSellerToUiConverter';
import { getValidatedInstance }      from 'Helpers/validate';
import { UiToTravelSellerConverter } from 'Models/Converter/UiToTravelSellerConverter';
import { TS_Response }               from 'Models/TravelSeller/API/TS_Response';
import { Payload }                   from 'Models/UI/Payload';
import { Response }                  from 'Models/UI/Response';
import { Request }                   from 'Models/UI/Request';
import { DisplayableError }          from 'State/ErrorHandler';
import app                           from 'State/AppService';

class Api {
    
    readonly baseUrl : string;
    public bookingSessionId : string | null = null;
    
    constructor() {
        try {
            this.baseUrl = this.getApiUrl();
        } catch ( e ) {
            throw new Error( 'Failed to retrieve API Url.' + e.message );
        }
    }
    
    public setBookingSessionId( id : string ) {
        this.bookingSessionId = id;
    }
    
    public async loadStep( step : string, pepxcite?: boolean ) {
        
        const data : Request = await getValidatedInstance<Request, {}>( Request, {
            for     : step,
            payload : null,
            brand   : pepxcite ? 'pepxcite' : 'pepxpress'
        } );
        
        return this.processRequest( this.post( '/loadStep', data ), null );
    }
    
    public async saveStep( step : string, payload : Payload ) : Promise<Response> {
        try {
            
            const requestUI : Request = await getValidatedInstance<Request, {}>( Request, {
                for : step,
                payload
            } );
            
            const requestTS = await UiToTravelSellerConverter( requestUI );
            
            return this.processRequest( this.post( '/saveStep', requestTS ) );
            
        } catch ( e ) {
            console.warn( 'Save step failed:', e );
            throw e;
        }
    }

    public async bookCheck(step: string | null) {
        return this.processRequest(this.get('/bookCheck', null), step);
    }
    
    private async processRequest( request : Promise<any>, step?: string | null ) : Promise<Response> {
        
        let responsePlain : any,
            responseInstance : TS_Response,
            responseConverted : Response,
            responseErrorMsg : string | null;
        
        try {
            responsePlain = await request;
        } catch ( e ) {
            throw e;
        }
        
        responseErrorMsg = this.getErrorMessage( responsePlain );
        
        if ( responseErrorMsg ) {
            throw new DisplayableError( responseErrorMsg );
        }

        if (step) {
            responsePlain = {
                ...responsePlain,
                for: step
            };
        }
        
        try {
            responseInstance  = await getValidatedInstance<TS_Response, {}>( TS_Response, responsePlain );
            responseConverted = await TravelSellerToUiConverter( responseInstance );
        } catch ( e ) {
            console.error( e );
            throw new DisplayableError( 'Die Antwort des Buchungsystems konnte nicht verarbeitet werden.' );
        }
        
        if ( responseConverted.errorCalls.length > 0 ) {
            const error = responseConverted.errorTexts[ 0 ];
            console.error( error );
            throw new DisplayableError( error );
        }
        
        return responseConverted;
    }
    
    public async post( url : string, data : any ) {
        return this.request( 'POST', url, data );
    }
    
    public async get( url : string, data : any ) {
        return this.request( 'GET', url, data );
    }
    
    private async request( method : Method, url : string, data : any ) {
        
        if ( !this.bookingSessionId ) {
            throw new Error( 'No Booking-Session-ID was given.' );
        }
        
        let response : AxiosResponse;
        
        try {
            response = await axios.request( {
                method, data,
                url     : this.baseUrl + url,
                timeout : 150000,
                headers : {
                    'API-Booking-Session'         : this.bookingSessionId,
                    'Content-Type'                : 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin' : '*',
                }
            } );
        } catch ( e ) {
            console.warn( e );
            throw new DisplayableError( 'Die Anfrage an das Buchungsystem ist fehlgeschlagen.' );
        }
        
        return response.data;
    }
    
    private getErrorMessage( obj : any ) : string | null {
        
        if ( typeof obj !== 'object' ) {
            return null;
        }
        
        let errorObject : any;
        
        if ( obj.hasOwnProperty( 'error' ) ) {
            errorObject = obj.error;
        }
        
        if ( obj.hasOwnProperty( 'booking' ) ) {
            errorObject = obj.booking;
        }
        
        if ( !errorObject ) {
            return null;
        }
        
        const errorMsg : string = errorObject.message || errorObject.errortext || null;
        
        return errorMsg;
    }
    
    private getApiUrl() {
        if ( app.config.apiUrl === '' ) {
            throw new Error( 'Url is empty.' );
        }
        
        return app.config.apiUrl;
    }
}

const api = new Api();

export default api;
