import React from 'react';
import { useTranslation } from "react-i18next";
import { Input, Label } from "reactstrap";
import { createUniqueId } from "../../../../Helpers/id";
import { useLanguage } from "../../../../Helpers/useLanguage";
import { TrainBundle } from "../../../../Models/TravelSeller/Models/TS_AddonTrain";
import styles from '../Train.module.scss';
import { Keys } from 'Translation/Setup';
import Price from "../../../../Components/Workflow/Price";

interface Props {
    bundles: TrainBundle[];
    selectedBundle: TrainBundle;
    onChangeSelectedBundle: (bundle: TrainBundle) => void;
}

export const TrainTariff = (props: Props) => {
    const language = useLanguage();
    const { t } = useTranslation();
    const uID = createUniqueId('trainConnection');
    const { bundles, selectedBundle, onChangeSelectedBundle } = props;

    return <div className={styles.trainBundleContainer}>
        {bundles.map(bundle => {
        const isBundleSelected = bundle.id === selectedBundle.id;
        const currentBundle = bundle.bundle[0];
        const totalPrice = bundle.bundle.reduce((total, current) => {
            return total + current.tarife.reduce((innerTotal, innerCurrent) => {
                return innerTotal + (innerCurrent.price.amount || 0)
            }, 0)
        }, 0)

        return currentBundle.tarife.map(tariff => 
            <div key={tariff.displayInfo.displayName} className={styles.trainBundleCard} onClick={(e) => {
                e.preventDefault();
                onChangeSelectedBundle(bundle)}
            }>
                <div className={styles.trainBundleCard__direction}>{currentBundle.direction}</div>
                <div className={styles.trainBundleCard__name}>{tariff.displayInfo.displayName}</div>
                <div className={styles.trainBundleCard__conditions}>Bedingungen</div>
                <div className={styles.trainDescriptionHtml}>
                    <ul>
                    {tariff.displayInfo.displayTexts.map(displayText => <li>{language !== 'en' ? displayText.shortText : displayText.translations.en.shortText}</li>)}
                    </ul>
                </div>
                <hr />
                <div className={styles.connectionItemInput}>
                    <Input
                        id={uID + '_' + bundle.id + '_' + currentBundle.direction + '_' + tariff.displayInfo.displayName + '_bundle'}
                        type={'radio'}
                        value={bundle.id}
                        checked={isBundleSelected}
                    />
                    <Label for={uID + '_' + bundle.id + '_' + currentBundle.direction + '_' + tariff.displayInfo.displayName + '_bundle'}>
                        {t(Keys.WORKFLOW.Train.trainConnection.connection.from)}{' '}
                        <span className={styles.connectionItemInputPrice}>
                            <Price amount={totalPrice} />
                        </span>
                    </Label>
                </div>
            </div>
        );
        }
        )}
    </div>;
};