import { IsBoolean, IsNumber, ValidateNested } from 'class-validator';
import { Type }                                from 'class-transformer';

export class GetBonuscardResponse {
    
    @IsBoolean()
    offerBonuscard : boolean;
    
    @ValidateNested()
    @Type( () => UnnecessaryNestingFromAPI )
    bonuscardInfo : UnnecessaryNestingFromAPI
}

class UnnecessaryNestingFromAPI {
    
    @IsNumber()
    bonuscardCosts : number;
    
    @IsNumber()
    bonuscardSaving : number;
}