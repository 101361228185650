import React                            from 'react';
import { action, computed, observable } from 'mobx';
import { observer, useLocalStore }      from 'mobx-react';
import { Trans, useTranslation }        from 'react-i18next';
import { Input }                        from 'reactstrap';

import { appRouter, appRoutes } from 'routes';
import { Keys }                 from 'Translation/Setup';
import app                      from 'State/AppService';
import workflow                 from 'State/WorkflowService';
import { Workflow }             from 'Components/Workflow';
import PepText                  from 'Components/PepText';
import { PaymentCheck }         from 'Workflow/PaymentCheck';

import screen from './bsessid-wide.png';
import styles from './Router.module.scss';

class RouterState {

    @observable _bookingSession : string | null = null;

    @computed get entryUrl() {
        return appRouter.buildUrl( appRoutes.WORKFLOW_ENTRY.name, this.urlParams );
    }

    @computed get urlParams() {
        return {
            data : this.bookingSessionId
        };
    }

    @computed get isInWorkflow() {
        return workflow.currentStep !== null;
    }

    @computed get isPaymentCheck() {
        return app.state && app.state.name === appRoutes.PAYMENT_CHECK.name;
    }

    @computed get isLoading() {
        return app.isLoading;
    }

    @action updateBookingSessionId( s : string ) {
        this._bookingSession = s;
    }

    @computed get bookingSessionId() {
        return this._bookingSession
               ? this._bookingSession
               : workflow.bookingSessionId || '972108e2bfe39176dcfb5374d3a1bc22';
    }
}

const RouterComponent = () => {

    const state = useLocalStore( () => new RouterState() );
    const { t } = useTranslation();
    
    if ( state.isPaymentCheck ) {
        return <PaymentCheck/>;
    }
    
    if ( state.isInWorkflow ) {
        return <>
            <Workflow/>
            { state.isLoading && <div className={ styles.preloader }>
                <div className={ styles.spinner }>
                    <div className={ styles.spinnerBounce1 }></div>
                    <div className={ styles.spinnerBounce2 }></div>
                </div>
            </div> }
        </>;
    }

    return <>
        <h4>
            <Trans i18nKey={ Keys.COMPONENTS.Router.headline }>
                Willkommen in der <PepText/> IBE
            </Trans>
        </h4>
        <p>
            <Trans i18nKey={ Keys.COMPONENTS.Router.explanation }>
                Um einzutreten benötigst du eine sog.
                <strong>Buchungs-Session-ID</strong>
                - diese kannst du dir aus der URL der Buchen-Seite von
                <a href="https://www.pepxpress.com/"
                   target="_blank"
                   rel="noopener noreferrer">
                    https://www.pepxpress.com/
                </a>
                kopieren. Hier findest du sie:
            </Trans>

        </p>

        <p>
            <img src={ screen }
                 alt={ t( Keys.COMPONENTS.Router.screenshot ) }
                 className={ styles.img }/>
        </p>

        <p>
            { t( Keys.COMPONENTS.Router.inputLabel ) }<br/>
            <Input type="text"
                   placeholder={ 'abcdefghijklmnop1234567890' }
                   value={ state.bookingSessionId }
                   onChange={ handleChange }
                   onKeyPress={ handleKeypress }/>
        </p>

        <p className={ styles.break }>
            { t( Keys.COMPONENTS.Router.callToAction ) }
            <br/>
            <a href={ state.entryUrl }>
                { state.entryUrl }
            </a>
        </p>

        <p>{ t( Keys.COMPONENTS.Router.byebye ) }</p>
    </>;

    function handleChange( e : React.ChangeEvent<HTMLInputElement> ) {
        state.updateBookingSessionId( e.target.value );
    }

    function handleKeypress( e : React.KeyboardEvent ) {
        if ( e.key === "Enter" ) {
            appRouter.navigate(
                appRoutes.WORKFLOW_ENTRY.name,
                state.urlParams, { replace : true }
            );
        }
    }
};

const Router = observer( RouterComponent );
export default Router;
