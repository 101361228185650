import { BookingItemInterface } from "./BookingItemInterface";
import { SelectType } from "./SelectType";

export class BookingItemGroup {
  id: string;

  items: BookingItemInterface[];

  private names: { [type: string]: string } = {
    1: "Verpflegung",
    2: "Ausflüge",
    3: "Wellness",
    4: "Transfer",
    6: "Verpflegung",
    10: "Sonstige",
    11: "Visum",
    16: "Veranstaltungen", // TODO rename?
    17: "Transfer",
    8: "Bahn",
    102: "Versicherung",
    107: "Zahlart",
    32: "FLEXtarif"
  };

  get name() {
    return this.firstItem
      ? this.names.hasOwnProperty(this.firstItem.type)
        ? this.names[this.firstItem.type]
        : null
      : null;
  }

  get isMultipleSelection() {
    return this.firstItem
      ? this.firstItem.selectType === SelectType.MULTIPLE
      : null;
  }

  get isSingleSelection() {
    return this.firstItem
      ? this.firstItem.selectType === SelectType.SINGLE
      : null;
  }

  get isSelectionObligatory() {
    return this.firstItem ? this.firstItem.mandatory === true : null;
  }

  get firstItem() {
    return this.items.length > 0 ? this.items[0] : null;
  }
}
