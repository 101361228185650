import { IsBoolean, IsNotEmpty, IsNumber, IsString, MinLength, ValidateIf } from 'class-validator';

import i18next  from 'i18next';
import { Keys } from 'Translation/Setup';

import { IsTsBirthday }   from './IsTSBirthday';
import { IsTSCompatible } from './IsTSCompatible';
import workflow             from 'State/WorkflowService';
import { IsPassportExpiryDate } from './IsPassportExpiryDate';

export class Passenger {

    isBusinessPartner : boolean;

    @IsNumber()
    type : number;

    @ValidateIf( p => { return isAdult( p ) && isNotBusinessPartner( p ) } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.salutation.empty ) } )
    salutation : string;

    @ValidateIf( p => { return isNotBusinessPartner( p ) } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.surname.empty ) } )
    @IsTSCompatible( { message : i18next.t( Keys.COMMON.Validation.surname.onlyLetters ) } )
    surname : string;

    @ValidateIf( p => { return isNotBusinessPartner( p ) } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.firstname.empty ) } )
    @IsTSCompatible( { message : i18next.t( Keys.COMMON.Validation.firstname.onlyLetters ) } )
    firstname : string;

    // @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.country.empty ) } )
    // country : string;

    @ValidateIf( p => { return validateBirthday(p) } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.day.empty ) } )
    bday : number;

    @ValidateIf( p => { return validateBirthday(p) } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.month.empty ) } )
    bmonth : number;

    @ValidateIf( p => { return validateBirthday(p) } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.year.empty ) } )
    @IsTsBirthday( { message : i18next.t( Keys.COMMON.Validation.date.invalid ) } )
    byear : number;

    @ValidateIf( _ => { return validateNationality() } )
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.nationality.empty ) } )
    @MinLength(1, { message : i18next.t( Keys.COMMON.Validation.nationality.empty ) } )
    nationality : string;

    @IsBoolean()
    passportNeeded: boolean;

    @ValidateIf(isPassportNeeded)
    @IsString()
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.passportNumber.empty ) } )
    passportNumber: string;

    @ValidateIf(isPassportNeeded)
    @IsPassportExpiryDate({ message : i18next.t( Keys.COMMON.Validation.passportValidThru.invalid ) })
    @IsNotEmpty( { message : i18next.t( Keys.COMMON.Validation.passportValidThru.empty ) } )
    passportValidThru: string;

    isChild() : boolean {
        return isChild( this );
    }

    isAdult() : boolean {
        return isAdult( this );
    }



    dateOfBirthNeeded : boolean;
}

function isChild( passenger : Passenger | any ) {
    return passenger.type && '1' !== passenger.type.toString(); // API does mix string and number...
}

function isAdult( passenger : Passenger | any ) {
    return !isChild( passenger );
}

function isNotBusinessPartner( passenger : Passenger | any ) {
    return !passenger.isBusinessPartner;
}

function validateBirthday(passenger : Passenger) {
    return isChild( passenger ) || workflow.showParticipantBirthday || passenger.dateOfBirthNeeded;
}

function validateNationality() {
    return workflow.showParticipantNationality;
}

function isPassportNeeded(passenger: Passenger) {
    return passenger.passportNeeded;
}
