export class TS_ItemType {
  static CATERING = '1';
  static TRIP = '2';
  static WELLNESS = '3';
  static TRANSFER_ROUNDTRIP = '4';
  static TRANSFER_ONEWAY_OUTBOUND = '17';
  static TRANSFER_ONEWAY_INBOUND = '34';
  static HOUSING = '5';
  static DINNER = '6';
  static FLIGHT = '7';
  static TRAIN = '8';
  static BUS = '9';
  static VISA = '11';
  static ARRIVAL_BY_BUS = '12';
  static CATEGORY_EVERY_DAY = '13';
  static CATEGORY_MINUS_ONE_DAY = '14';
  static CATERING_MINUS_ONE_DAY = '15';
  static EVENT = '16';
  static PRODUCT_WITH_PA_NO = '22';
  static CHARGES = '23';
  static MISC = '10';
  static NOTE = '19';
  static DEPARTURE_BY_BUS = '24';
  static FLEX_BUS_TRAVEL = '25';
  static FLEX_PEP = '32';

  static OFFER_HOTEL = '26';
  static OFFER_MORE_HOTEL = '27';
  static OFFER_EVENT = '28';
  static OFFER_MORE_EVENT = '29';
  static OFFER_FLIGHT = '30';
  static OFFER_MORE_FLIGHT = '31';
  static OFFER_BANNER = '33';

  static HOTEL = '100';
  static INSURANCE = '102';
  static ROOM = '103';
  static INCL = '112';
  static PAYMENT = '107';
  static DONATION = '104';
  static BONUSCARD_ORDER = '105';
  static BONUSCARD_SAVING = '106';
  static COUPON = '108';
  static PAYMENT_INFO = '109';
  static DEPOSIT = '113';
  static SPECIAL = '114';
  static EARLY_BIRD = '115';
  static EXTRA_NIGHTS = '116';
}
