import React                    from 'react';
import i18next                  from 'i18next';
import { Form, Field, FormikProps, ErrorMessage }    from 'formik';
import TextField                from 'Components/Forms/TextField';
import { Keys }                 from 'Translation/translations.de.json.keys';
import styles                   from './Forms.module.scss';
//import { conditionalStyles }    from 'Helpers/conditionalStyles';

export const IBANPaymentForm: React.FunctionComponent<{
    formikProps : FormikProps<any>;
    agreement: string;
}> = props => {
    return (
        <Form className={styles.form}>
            <div role="group">
                <TextField label={i18next.t(Keys.WORKFLOW.Payment.Forms.IBANPaymentForm.paymentAccountHolder)} className={styles.textfield} name="paymentAccountHolder" />
                <TextField label={i18next.t(Keys.WORKFLOW.Payment.Forms.IBANPaymentForm.paymentIban)} className={styles.textfield} name="paymentIban"/>
                <TextField label={i18next.t(Keys.WORKFLOW.Payment.Forms.IBANPaymentForm.paymentBic)} className={styles.textfield} name="paymentBic"/>
                
                <label htmlFor="paymentAccept">
                    <Field type="checkbox" name="paymentAccept" className={styles.checkbox}/>
                    { props.agreement }
                </label>
                <div className={styles.errortext}>
                    <ErrorMessage name="paymentAccept" className="invalid-feedback" />
                </div>
            </div>
        </Form>
    );
};

export default IBANPaymentForm;