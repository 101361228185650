import React, { useCallback, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Element as ScrollTo, scroller } from 'react-scroll';
import { Alert } from 'reactstrap';
import { debounce } from 'lodash-es';

import { conditionalStyles, join } from 'Helpers/conditionalStyles';
import DefaultLayout from 'Components/Workflow/DefaultLayout';
import Headline from 'Components/Workflow/Headline';
import Nav from 'Components/Workflow/Nav';
import Cart from 'Components/Workflow/Cart';
import { Addon } from 'Models/UI/Addon';
import { AddonEvent } from 'Models/UI/AddonEvent';
import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import { AddonTransferRoundtrip } from 'Models/UI/AddonTransferRoundtrip';
import { BookingItem } from 'Models/UI/BookingItem';
import { BookingItemGroup } from 'Models/UI/BookingItemGroup';
import { PayloadAddons } from 'Models/UI/PayloadAddons';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import app from 'State/AppService';
import workflow, {Workflow} from 'State/WorkflowService';
import errorHandler from 'State/ErrorHandler';
import { Keys } from 'Translation/Setup';

import AddonDefaultComponent from './AddonDefault/Component';
import AddonTransferRoundtripForm from './AddonTransferRoundtrip/Component';
import AddonEventForm from './AddonEvent/Component';
import AddonTransferOnewayOutboundForm from './AddonTransferOnewayOutbound/Component';
import AddonTransferOnewayInboundForm from './AddonTransferOnewayInbound/Component';
import AddonFlexPepForm from './AddonFlexPep/Component';
import { AdditionalServicesState } from './AdditionalServicesState';
import pepxpressStyles from './AdditionalServices-pepxpress.module.scss';
import pepxciteStyles from './AdditionalServices-pepxcite.module.scss';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import _ from 'lodash';
import { AddonFlexPep } from '../../../Models/UI/AddonFlexPep';
import PepText from '../../../Components/PepText';
import AddonBusForm from './AddonBus/AddonBus';
import { AddonBus } from '../../../Models/UI/AddonBus';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import { useLanguage } from '../../../Helpers/useLanguage';
import { AddonTransferOnewayInbound } from '../../../Models/UI/AddonTransferOnewayInbound';
import { ItemType } from '../../../Models/UI/ItemType';

const AdditionalServices = observer((props: ThemeProps) => {
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const css = conditionalStyles(styles);
  const scrollElementPrefix = 'scrollElement_';

  const state = useLocalStore(() => new AdditionalServicesState());

  const { t, i18n } = useTranslation();
  const iframeSrcLanguage = useLanguage();

  const saveStep = useCallback(async () => {
    app.trackTimeSaveStarted(workflow);

    state.updateApiErrorMessage(null);

    Object.values(state.addonsToSave).forEach(a => workflow.updateSelectedItem(a));

    const payload = new PayloadAddons();
    payload.addons = workflow.selectedItems;
    await workflow.saveStep(payload);

    app.trackTimeSaveFinished(workflow);
  }, [state]);

  const removeAllItems = useCallback(async () => {
    app.activateLoader();
    state.groups.forEach(group => {
      group.items.forEach(item => {
        if (!!workflow.selectedItems?.find(selectedItem => selectedItem.id === item.id)) {
          workflow.removeItem(item.id);
        }
      });
    });
    await state.validateGroups();
    try {
      await saveStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
    }
    app.deactivateLoader();
  }, [saveStep, state]);

  useEffect(() => {
    if (workflow.isCurrentStepExcluded) {
      workflow.navigateToNextStep(true);
      return;
    }
    //
    if (workflow.booking && workflow.booking.ritMode !== 'RIT') {
      workflow.addeExcludeStep('pep-train'); //TODO need logic for this
    }

    (async () => {
      try {
        await app.trackTimeLoadStarted(workflow.currentStep!);
        const { language } = await workflow.loadStep(props.theme === 'pepxcite');
        const globalLanguage = iframeSrcLanguage || language;
        if (!!globalLanguage) {
          await i18n.changeLanguage(globalLanguage);
        }

        const currentStep = workflow.currentStep!;
        const booking = workflow.booking!;

        await app.setInitToDone();
        await app.trackTimeLoadFinished(currentStep);
        await app.tracking.addPageAndUser(workflow.booking);
        if (workflow.currentStepIndex === 1 ||
          (workflow.currentStepIndex === 2 &&
            !!workflow.excludedSteps['pep-train'] &&
            workflow.booking?.workflow === Workflow.HOTEL)) {
          await app.tracking.sendBeginCheckoutEvent(
            booking,
            workflow.currentStepTrackingCustomEvent,
            workflow.getAvailableAdditionalServicesTypes
          );
        } else {
          await app.tracking.sendCheckoutStepEvent(
            booking,
            workflow.currentStepTrackingCustomEvent,
            workflow.getAvailableAdditionalServicesTypes
          );
        }

        if (state.groups.length > 0) {
          if (workflow.currentStepType === 'backward') {
            await removeAllItems();
          }
          app.deactivateLoader();
          workflow.setAvailableAdditionalServicesTypes(
            state.groups.reduce((total: BookingItemInterface[], current: BookingItemGroup) => {
              return current.items?.length > 0 ? [...total, ...current.items] : [...total];
            }, [])
          );
        } else {
          workflow.excludeCurrentStep();
          workflow.navigateToNextStep(true);
        }

        window.scrollTo(0, 0);
      } catch (e) {
        errorHandler.catchError(e);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Breadcrumbs next={next} />
      <DefaultLayout
        top={renderTop()}
        left={renderContent()}
        right={renderRight()}
        bottom={renderBottom()}
      />
    </>
  );

  function renderContent() {
    return (
      <div className={styles.container}>
        {state.groups.map((group, groupIndex) => {
          const groupError = state.groupErrorMessages.hasOwnProperty(group.id)
            ? state.groupErrorMessages[group.id]
            : false;

          const itemErrors = getItemErrorsForGroup(group);
          const hasError = !!(groupError || itemErrors);

          const items = _.orderBy(group.items, ['sortNumber'], ['asc']);

          return (
            <div key={groupIndex} className={styles.group}>
              {group.firstItem instanceof AddonFlexPep ? (
                <h5>
                  <PepText text={group.name} />
                </h5>
              ) : (
                <h5>
                  {group.name}
                  {group.isSelectionObligatory && '*'}
                </h5>
              )}

              <ScrollTo name={scrollElementPrefix + group.id}>
                <Alert color="danger" isOpen={state.showValidation && hasError}>
                  {groupError}
                  {itemErrors && (
                    <div className={styles.small}>
                      {itemErrors.map((e, i) => (
                        <div key={'e_' + groupIndex + '_' + i}>{e}</div>
                      ))}
                    </div>
                  )}
                </Alert>
              </ScrollTo>

              {[
                ...items.filter(i => !i.id.startsWith('d-')),
                ...items.filter(i => i.id.startsWith('d-'))
              ].map((item, itemIndex) => {
                const isDisabledForMusicals =
                  item instanceof AddonEvent &&
                  item.type === ItemType.EVENT &&
                  (item as AddonEvent).dates.length === 0;

                return (
                  <>
                    <div
                      className={join(
                        styles.customRadio,
                        css('customRadio', {
                          'customRadio--flex-pep': item instanceof AddonFlexPep
                        }),
                        css('customRadio', {
                          'customRadio--disabled': isDisabledForMusicals
                        })
                      )}
                      key={item.id + '_' + itemIndex}
                      tabIndex={0}
                      onKeyPress={e => toogleOnKeypressEnter(e, group, item)}
                    >
                      <input
                        type={
                          group.isMultipleSelection ||
                          (item.hasOwnProperty('mandatory') && !item.mandatory)
                            ? 'checkbox'
                            : 'radio'
                        }
                        id={item.id}
                        onChange={() => toggleItem(group, item)}
                        checked={isItemSelected(item)}
                        disabled={isDisabledForMusicals}
                      />
                      <label
                        htmlFor={item.id}
                        className={css('service', { 'service--isSelected': isItemSelected(item) })}
                      >
                        {renderItem(item)}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>
          );
        })}

        <div className={styles.note}>{t(Keys.WORKFLOW.AdditionalServices.mandatoryNote)}</div>
      </div>
    );
  }

  function renderTop() {
    return (
      <>
        {app.isDoingInitLoading ? (
          <Headline text={'...'} />
        ) : (
          <Headline text={t(Keys.WORKFLOW.AdditionalServices.headline)} />
        )}
      </>
    );
  }

  function renderRight() {
    return <Cart bookingOnRequest={workflow.bookingOnRequest} />;
  }

  function renderBottom() {
    return (
      <>
        <Alert color="danger" isOpen={state.showValidation && state.hasErrors}>
          {t(Keys.WORKFLOW.AdditionalServices.globalFormError)}
        </Alert>

        <Alert color="danger" isOpen={state.showValidation && state.apiErrorMessage !== null}>
          <strong>{t(Keys.WORKFLOW.AdditionalServices.apiError)} </strong>
          <br />
          {state.apiErrorMessage}
        </Alert>
        {workflow.excludedSteps.hasOwnProperty('pep-train') && (
          <Nav onCloseWindow={() => window?.top?.close()} onNext={() => next()} />
        )}
        {!workflow.excludedSteps.hasOwnProperty('pep-train') && (
          <Nav onPrev={() => workflow.navigateToPrevStep()} onNext={() => next()} />
        )}
      </>
    );
  }

  function renderItem(item: BookingItem) {
    const props = {
      showValidation: state.showValidation,
      isSelected: isItemSelected(item),
      onChange: debounce(state.handleAddonChange.bind(state), 250)
    };

    if (item.id.startsWith('d-')) {
      return <AddonDefaultComponent item={item} />;
    } else if (item instanceof AddonBus) {
      return <AddonBusForm item={item} {...props} />;
    } else if (item instanceof AddonEvent) {
      return <AddonEventForm item={item} {...props} saveStep={saveStep} />;
    } else if (item instanceof AddonTransferRoundtrip) {
      return <AddonTransferRoundtripForm item={item} {...props} />;
    } else if (item instanceof AddonTransferOnewayOutbound) {
      return <AddonTransferOnewayOutboundForm item={item} {...props} />;
    } else if (item instanceof AddonTransferOnewayInbound) {
      return <AddonTransferOnewayInboundForm item={item} {...props} />;
    } else if (item instanceof AddonFlexPep) {
      return <AddonFlexPepForm item={item} />;
    } else {
      return <AddonDefaultComponent item={item} />;
    }
  }

  function scrollToFirstError() {
    if (state.hasErrors) {
      scroller.scrollTo(scrollElementPrefix + state.firstErrorId, {
        duration: 750,
        smooth: true,
        offset: -30
      });
    }
  }

  async function next() {
    state.updateShowValidation(true);

    await state.validateSelectedItems();
    await state.validateGroups();

    if (state.hasGroupErrors || state.hasItemErrors) {
      state.updateShowValidation(true);
      scrollToFirstError();
      return;
    }

    app.activateLoader();

    try {
      await saveStep();
      workflow.navigateToNextStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
      app.deactivateLoader();
    }
  }

  function getItemErrorsForGroup(g: BookingItemGroup) {
    if (state.itemErrorMessages.hasOwnProperty(g.id)) {
      const errors = Object.keys(state.itemErrorMessages[g.id]);
      if (errors.length > 0) {
        return errors.map(field => {
          return state.itemErrorMessages[g.id][field];
        });
      }
    }
    return null;
  }

  async function toogleOnKeypressEnter(
    ev: React.KeyboardEvent,
    group: BookingItemGroup,
    item: BookingItemInterface
  ) {
    if (ev.key === 'Enter') {
      await toggleItem(group, item);
    }
  }

  async function toggleItem(group: BookingItemGroup, item: BookingItemInterface) {
    app.activateLoader();

    state.removeItemError(group);

    if (group.isMultipleSelection || (item.hasOwnProperty('mandatory') && !item.mandatory)) {
      // == toggle single items

      if (workflow.isItemSelected(item.id)) {
        console.group('toggleItem - single remove');
        workflow.removeItem(item.id);
      } else {
        console.group('toggleItem - single add');
        workflow.addItem<Addon>(Addon, item.id);
      }
    } else {
      // == replace one item
      console.group('toggleItem - replace');
      group.items.forEach(i => workflow.removeItem(i.id));
      state.removeItemError(group);
      workflow.addItem<Addon>(Addon, item.id);
    }

    console.groupEnd();

    await state.validateSelectedItems();
    await state.validateGroups();

    try {
      await saveStep();
    } catch (e) {
      state.updateApiErrorMessage(e.message);
    }

    app.deactivateLoader();
  }

  function isItemSelected(item: BookingItem): boolean {
    return state.selectedItems.filter(i => i.id === item.id).length > 0;
  }
});
export default withTheme(AdditionalServices);
