import { isDev }                        from 'env';
import { action, observable, computed } from 'mobx';
import { persist }                      from 'mobx-persist';
import { Route }                        from "routes";

import SelectFieldOption from 'Models/SelectFieldOption';
import { AppConfig }     from 'Models/UI/AppConfig';
import { DataLayer }     from 'Models/Tracking/DataLayer';

import { WorkflowService } from './WorkflowService';

type TimeTracking = { [ routeId : string ] : number };

export class AppService {
    
    @observable config : AppConfig;
    @observable state : Route;
    @observable isLoading : boolean  = false;
    @observable isInitDone : boolean = false;
    
    // TODO we might need a separate handling for caches?
    // config, user, countries are basically all type of
    // cacheable "static" configuration per workflow
    
    @persist @observable user : string | null                      = null;
    @persist( 'list' ) @observable countries : SelectFieldOption[] = [];
    
    private _tracking : DataLayer;
    private _pageLoadStarted : TimeTracking = {};
    private _pageSaveStarted : TimeTracking = {};
    
    constructor() {
        const json  = (document.querySelector( 'script[data-name="appConfig"]' ) || { innerHTML : '' }).innerHTML;
        this.config = JSON.parse( json );
        
        this._tracking = new DataLayer( this.config.gtmParams );
    }
    
    get tracking() : DataLayer {
        if ( this.user ) {
            this._tracking.setUser( this.user );
        }
        return this._tracking;
    }
    
    @action
    setState(state : Route) {
        this.state = state;
    }
    
    @action
    activateLoader() {
        this.isLoading = true;
    }
    
    @action
    deactivateLoader() {
        this.isLoading = false;
    }
    
    @action
    setInitToDone() {
        this.isInitDone = true;
    }
    
    @computed
    get isDoingInitLoading() {
        if ( this.isInitDone ) {
            return false;
        }
        return this.isLoading;
    }
    
    get isDev() : boolean {
        console.warn( 'You asked IBE if isDevServer - use with caution!' );
        return isDev;
    }
    
    public async trackTimeLoadFinished( name : string ) {
        
        this.measureTime( {
            list : this._pageLoadStarted,
            step : name,
            log  : 'Step load time'
        } );
    }
    
    public trackTimeLoadStarted( name : string ) {
        this._pageLoadStarted[ name ] = this.timeNow;
    }
    
    public trackTimeSaveStarted( workflow : WorkflowService ) {
        this._pageSaveStarted[ workflow.currentStep || '' ] = this.timeNow;
    }
    
    public trackTimeSaveFinished( workflow : WorkflowService ) {
        this.measureTime( {
            list : this._pageSaveStarted,
            step : workflow.currentStep || '',
            log  : 'Step save time'
        } );
    }
    
    private measureTime( obj : { list : TimeTracking, step : string, log : string } ) {
        let time;
        if ( obj.list.hasOwnProperty( obj.step ) ) {
            time = this.timeNow - obj.list[ obj.step ];
            delete obj.list[ obj.step ];
            console.info( obj.log + ' "' + obj.step + '" -->', (time / 1000).toFixed( 2 ) );
        } else {
            console.error( 'Starting function for "' + obj.log + '" / "' + obj.step + '" was not called!' );
        }
        return time;
    }
    
    private get timeNow() {
        return new Date().getTime();
    }
    
    @action
    public setUser( u : string ) {
        this.user = u;
        this._tracking.setUser( u );
    }
    
    @action
    public setCountries( countries : SelectFieldOption[] ) {
        this.countries = countries || [];
    }
}

const app = new AppService();

export default app;
