import React from 'react';

import AdditionalServices from 'Workflow/Steps/AdditionalServices/AdditionalServices';
import { Entry } from 'Workflow/Steps/Entry/Entry';
import Participants from 'Workflow/Steps/Participants/Participants';
import Insurance from 'Workflow/Steps/Insurance/Insurance';
import Overview from 'Workflow/Steps/Overview/Overview';
import Payment from 'Workflow/Steps/Payment/Payment';
import Summary from 'Workflow/Steps/Summary/Summary';
import Train from 'Workflow/Steps/Train/Train';
import Upsell from './Steps/Upsell/Upsell';
import RailFly from './Steps/RailFly/RailFly';

const entry = <Entry />; //workaround

export const HotelFlightWorkflowSteps: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-rail-fly': <RailFly />,
  'pep-additional-services': <AdditionalServices />,
  'pep-participants': <Participants />,
  'pep-insurance': <Insurance />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const HotelWorkflowSteps: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-train': <Train />,
  'pep-additional-services': <AdditionalServices />,
  'pep-participants': <Participants />,
  'pep-insurance': <Insurance />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const FlightWorkflowSteps: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-participants': <Participants />,
  'pep-rail-fly': <RailFly />,
  'pep-insurance': <Insurance />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const EventWorkflowSteps: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-additional-services': <AdditionalServices />,
  'pep-participants': <Participants />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const HotelFlightWorkflowStepsEN: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-rail-fly': <RailFly />,
  'pep-additional-services': <AdditionalServices />,
  'pep-participants': <Participants />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const HotelWorkflowStepsEN: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-train': <Train />,
  'pep-additional-services': <AdditionalServices />,
  'pep-participants': <Participants />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const FlightWorkflowStepsEN: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-participants': <Participants />,
  'pep-rail-fly': <RailFly />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const EventWorkflowStepsEN: { [key: string]: JSX.Element } = {
  'pep-entrance': entry,
  'pep-additional-services': <AdditionalServices />,
  'pep-participants': <Participants />,
  'pep-payment': <Payment />,
  'pep-overview': <Overview />,
  'pep-summary': <Summary />,
  'pep-upsell': <Upsell />
};

export const trackingEventMapping: { [key: string]: string } = {
  'pep-rail-fly': 'rail and fly',
  'pep-additional-services': 'zusatzleistungen',
  'pep-participants': 'teilnehmer',
  'pep-insurance': 'reiseschutz',
  'pep-payment': 'zahlung',
  'pep-overview': 'übersicht',
  'pep-summary': 'bestätigung',
  'pep-train': 'bahn'
}
