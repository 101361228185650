import { Route as Route5, Router, createRouter } from 'router5';

import browserPlugin   from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';

export interface Route extends Route5 {
    params? : Record<string, any>; // attention! TODO params is not in types!
    meta? : Record<string, any>; // attention! TODO params is not in types!
}

class AppRoutes {
    
    INDEX : Route = {
        name : 'index',
        path : '/'
    };
    
    ROUTE_NOT_FOUND : Route = {
        name : 'routeNotFound',
        path : '/routeNotFound'
    };
    
    WORKFLOW_ENTRY : Route = {
        name : 'workflowEntry',
        path : '/workflow/entrance/:data'
    };
    
    WORKFLOW_STEP : Route = {
        name : 'workflowStep',
        path : '/workflow/:step'
    };
    
    PAYMENT_CHECK : Route = {
        name : 'paymentCheck',
        path : '/pc/:bookingSession/:status'
    };
}

const appRoutes = new AppRoutes();

const appRouter : Router = createRouter( Object.values( appRoutes ), {
    defaultRoute  : appRoutes.ROUTE_NOT_FOUND.name,
    autoCleanUp   : true,
    allowNotFound : false
} );

appRouter.usePlugin( browserPlugin( { useHash : true } ) );
appRouter.usePlugin( listenersPlugin() );

export { appRouter, appRoutes };
