import { TS_Item }          from './TS_Item';
import { TS_ItemInterface } from './TS_ItemInterface';

export class TS_Addon extends TS_Item implements TS_ItemInterface {
    id : string;
    type : string;
    mandatory : boolean;
    selectType : string;
    groupID : string;
    title : string;
    description : string;
    totalPrice : number;
    infoURL : string;
}