import { ValidateNested } from 'class-validator';
import { Type }           from 'class-transformer';

import { PaymentInfo } from './PaymentInfo';

export class Meta {
    
    @ValidateNested()
    @Type( () => PaymentInfo )
    payment : PaymentInfo;
    //
    //@ValidateNested()
    //@Type( () => BonuscardInfo )
    //bonuscard : BonuscardInfo | null;
    //
    //coupon : string | null;
    //
    //donation : boolean | null;
}