import { Type }             from 'class-transformer';
import { TS_ItemInterface } from 'Models/TravelSeller/Models/TS_ItemInterface';
import { TS_ItemType }      from './TS_ItemType';
import { TS_Addon }         from './TS_Addon';

export class Day {
    date : string; // '01.01.2019'
    variants : string[] | Record<string, string>; // [ "Sonntag 14:00 Uhr", "Sonntag 19:00 Uhr", ... ]
}

export type AddonInfoSeats = {
    addonInfoSeats: string[]
}

export class TS_AddonEvent extends TS_Addon implements TS_ItemInterface {
    type = TS_ItemType.EVENT;
    
    @Type( () => Day )
    dates : Day[] | Record<string, Day>;

    floorplanReqired: boolean;
    
    selectedDate : string;
    selectedVariant : string;
    selectedShow : string;
    addonInfo?: AddonInfoSeats;
}
