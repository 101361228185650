import { TS_ItemType } from './TS_ItemType';
import { TS_Addon } from './TS_Addon';
import { Type } from 'class-transformer';

export class DumbNesting {
  @Type(() => DumbNestingAgain)
  addonInfoTransfer: DumbNestingAgain;
}

export class DumbNestingAgain {
  flightNr: string;
  flightTime: string;
}

export class TS_AddonTransferOnewayOutbound extends TS_Addon {
  type = TS_ItemType.TRANSFER_ONEWAY_OUTBOUND;

  public static FORMAT = 'YYYY-MM-DD HH:mm';

  // these are for reading the fields
  @Type(() => DumbNesting)
  addonInfo: DumbNesting;

  // these are for saving the fields
  flightNr: string;
  flightTime: string;
}
