import React        from 'react';
import { Collapse } from 'reactstrap';

import { Item }     from 'Models/UI/Item';
import AddonDefault from './AddonDefault/Component';

type Props = {
    item : Item;
    isOpen : boolean;
    isStartPrice?: boolean;
}

export default class FormCollapsible extends React.Component<Props> {
    render() {
        return <div>
            <AddonDefault item={ this.props.item } isStartPrice={this.props.isStartPrice}/>
            <Collapse isOpen={ this.props.isOpen }>
                { this.props.children }
            </Collapse>
        </div>;
    }
}