import { registerDecorator, ValidationOptions } from "class-validator";

export function CountryRestriction( validationOptions? : ValidationOptions ) {
    return function ( object : Object, propertyName : string ) {
        registerDecorator( {
            name         : "CountryRestriction",
            target       : object.constructor,
            propertyName : propertyName,
            constraints  : [],
            options      : validationOptions,
            validator    : {
                validate( value : any ) {
                    // TODO load countries upfront and use Array.contains here
                    if ( !value || value === 'X' ) {
                        return false;
                    }
                    return true;
                }
            }
        } );
    };
}
