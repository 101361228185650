import React, { ReactNode } from 'react';

import Price from 'Components/Workflow/Price';
import { conditionalStyles, join } from 'Helpers/conditionalStyles';
import { BookingItem } from 'Models/UI/BookingItem';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';

interface Props {
  children?: ReactNode;
  item: BookingItem;
  isSelected: boolean;
  label: string | null;
  toggle: (item: BookingItemInterface) => Promise<void>;
  styles: { [key: string]: string };
}

export const Box = (props: Props) => {
  const { children, item, toggle, label, isSelected, styles } = props;
  const css = conditionalStyles(styles);

  const hasPrice = item.totalPrice && item.totalPrice > 0;
  const classNames = css('insurance', 'insurance--fullWidth', {
    'insurance--isSelected': isSelected
  });

  return (
    <div className={classNames} tabIndex={0} onKeyPress={e => e.key === 'Enter' && toggle(item)}>
      {children}

      {label && (
        <div className={join(styles.insuranceInput)}>
          <input type={'radio'} id={item.id} onChange={() => toggle(item)} checked={isSelected} />
          <label htmlFor={item.id} className={styles.insuranceLabel}>
            <div>
              <span>{label}</span>
              {!!hasPrice && <Price amount={item.totalPrice} prefix={'+ '} />}
            </div>
          </label>
        </div>
      )}
    </div>
  );
};
