import { classToClass } from 'class-transformer';

import { toValidFormatOrNothing } from 'Helpers/moment';
import { Mapping } from 'Helpers/Mapping';

import { AddonTransferOnewayOutbound as OnewayUi } from 'Models/UI/AddonTransferOnewayOutbound';

import {
  TS_AddonTransferOnewayOutbound as OnewayTs,
  DumbNestingAgain
} from 'Models/TravelSeller/Models/TS_AddonTransferOnewayOutbound';

export default async function (input: OnewayTs) {
  return new Mapping<OnewayTs, OnewayUi>()
    .of(OnewayTs, input)
    .to(OnewayUi)
    .withValidation(async (input: OnewayTs) => ({
      ...classToClass<OnewayTs>(input),
      inboundNr: getInfo(input).flightNr || '',
      inboundDate: toTimeString(getInfo(input).flightTime),
      inboundTime: toDateString(getInfo(input).flightTime),
      isRoundtrip: false,
      travelEnd: '', // TODO how to fill these?
      travelStart: ''
    }));
}

function getInfo(input: OnewayTs): DumbNestingAgain {
  try {
    // TS does not always provide it....
    return input.addonInfo.addonInfoTransfer;
  } catch (e) {
    return new DumbNestingAgain();
  }
}

function toTimeString(str: string): string {
  return toValidFormatOrNothing(str, OnewayTs.FORMAT, OnewayUi.FORMAT_DAY) || str;
}

function toDateString(str: string): string {
  return toValidFormatOrNothing(str, OnewayTs.FORMAT, OnewayUi.FORMAT_TIME) || str;
}
