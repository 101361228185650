import React, { createContext, PropsWithChildren, useContext } from 'react';

export const LanguageContext = createContext<string>('');

export const LanguageProvider = ({ children, language }: PropsWithChildren<{language: string}>): JSX.Element => {

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): string => {
  return useContext<string>(LanguageContext);
};
