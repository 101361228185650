import { TS_ItemInterface } from '../../../../Models/TravelSeller/Models/TS_ItemInterface';

export class GetDonationResponse {
    response : TS_ItemInterface[];
    // yeah lets call it "response" here, why not.
    // consistent api interface would be boring right?
    // thanks.
    
    constructor() {
        this.response = [];
    }
    
    getItems() {
        return this.response;
    }
}