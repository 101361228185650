import i18next                            from 'i18next';
import React                              from 'react';
import { Field, FormikProps } from 'formik';

import ValidationMessage     from 'Components/Forms/ValidationMessage';
import { getPropertyByPath } from 'Helpers/objectStructure';
import SelectFieldOption     from 'Models/SelectFieldOption';
import { Keys }              from 'Translation/translations.de.json.keys';

import { Meta } from '../Models/Meta';
import styles   from './BirthdayFields.module.scss';

const days : SelectFieldOption[] = [ { value : '', label : i18next.t( Keys.WORKFLOW.Participants.Forms.BirthdayFields.day ) } ];
for ( let i = 1; i < 32; i++ ) {
    days.push( { value : i.toString(), label : pad( i, 2 ) } );
}

const months : SelectFieldOption[] = [ { value : '', label : i18next.t( Keys.WORKFLOW.Participants.Forms.BirthdayFields.month ) } ];
for ( let i = 1; i < 13; i++ ) {
    months.push( { value : i.toString(), label : pad( i, 2 ) } );
}

const years : SelectFieldOption[] = [ { value : '', label : i18next.t( Keys.WORKFLOW.Participants.Forms.BirthdayFields.year ) } ];
const thisYear                    = new Date().getFullYear();
for ( let i = thisYear; i > thisYear - 100; i-- ) {
    years.push( { value : i.toString(), label : i.toString() } );
}

interface BirthdayFieldsProps {
    day : string;
    month : string;
    year : string;
    formikProps : FormikProps<Meta>;
}

interface OneFieldProps {
    name : string;
    hasError : boolean;
    values : SelectFieldOption[];
}

const OneField : React.FunctionComponent<OneFieldProps> = ( props : OneFieldProps ) => {
    return (
        <Field name={ props.name } render={ ( { field } : any ) => {
            const { values, hasError } = props;
            return <div>
                <select { ...field } className={ 'form-control' + (hasError ? ' is-invalid' : '') }>
                    { values.map( ( v, i ) => <option key={ i } value={ v.value! }>{ v.label }</option> ) }
                </select>
            </div>;
        } }/>
    );
};

const BirthdayFields : React.FunctionComponent<BirthdayFieldsProps> = ( props : BirthdayFieldsProps ) => {

    const { day, month, year, formikProps } = props;
    const { touched, errors }               = formikProps;

    const wasTouched : boolean = (!!getPropertyByPath( day, touched ))
                                 && (!!getPropertyByPath( month, touched ))
                                 && (!!getPropertyByPath( year, touched ));

    const errorDay   = getPropertyByPath( day, errors );
    const errorMonth = getPropertyByPath( month, errors );
    const errorYear  = getPropertyByPath( year, errors );

    const hasErrorDay   = !!errorDay;
    const hasErrorMonth = !!errorMonth;
    const hasErrorYear  = !!errorYear;

    const hasErrors : boolean = hasErrorDay || hasErrorMonth || hasErrorYear;

    const isErrorVisible = wasTouched && hasErrors;

    return (
        <div>

            <div className={ styles.group }>
                <OneField name={ day } hasError={ isErrorVisible } values={ days }/>
                <OneField name={ month } hasError={ isErrorVisible } values={ months }/>
                <OneField name={ year } hasError={ isErrorVisible } values={ years }/>
            </div>

            { isErrorVisible && <div>
                { errorDay && <ValidationMessage text={ errorDay.toString() }/> }
                { errorMonth && <ValidationMessage text={ errorMonth.toString() }/> }
                { errorYear && <ValidationMessage text={ errorYear.toString() }/> }
            </div> }

        </div>
    );

};

function pad( num : number, size : number ) : string {
    let s = num.toString();
    while ( s.length < size ) s = "0" + s;
    return s;
}

export default BirthdayFields;