import { IsDefined, IsOptional } from 'class-validator';

export class User {
    
    @IsDefined()
    id : string;

    @IsOptional()
    ktype : string;

    @IsOptional()
    super_ktype : string;
}