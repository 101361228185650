import React                 from 'react';
import { Field, FieldProps } from 'formik';
import { Input }             from 'reactstrap';

import styles                from './TextField.module.scss';
import ValidationMessage     from 'Components/Forms/ValidationMessage';
import { getPropertyByPath } from 'Helpers/objectStructure';

interface TextFieldProps {
    label : string;
    name : string;
    note? : string;
    className? : string;
    isDisabled? : boolean;
    mask? : any;
    hideInput? : boolean;
}

const TextField : React.FunctionComponent<TextFieldProps> = ( props : TextFieldProps ) => {
    
    return (
        <Field name={ props.name } render={ ( { field, form } : FieldProps ) => {
            
            const isDisabled = props.isDisabled || false;
            const hideInput  = props.hideInput || false;
            const note       = props.note || null;
            
            const isErrorVisible = !!(getPropertyByPath( props.name, form.touched )
                                      && getPropertyByPath( props.name, form.errors ));
            
            return <div className={ props.className || '' }>
                
                <Input { ...field }
                       disabled={ isDisabled }
                       type={ hideInput ? 'hidden' : 'text' }
                       placeholder={ props.label }
                       invalid={ isErrorVisible }/>
                
                { isErrorVisible &&
                  <ValidationMessage text={ getPropertyByPath( props.name, form.errors ).toString() }/> }
                
                { note &&
                  <div className={ styles.note }>{ note }</div> }
            </div>;
        } }/>
    );
    
};

export default TextField;
