import { classToClass } from 'class-transformer';

import { Mapping } from 'Helpers/Mapping';
import { toValidFormatOrNothing } from 'Helpers/moment';

import { AddonTransferRoundtrip as RoundtripUi } from 'Models/UI/AddonTransferRoundtrip';

import { TS_AddonTransferRoundtrip as RoundtripTs } from 'Models/TravelSeller/Models/TS_AddonTransferRoundtrip';

export default async function (input: RoundtripUi) {
  return new Mapping<RoundtripUi, RoundtripTs>()
    .of(RoundtripUi, input)
    .to(RoundtripTs)
    .withValidation(async (input: RoundtripUi) => ({
      ...classToClass<RoundtripUi>(input),
      addonInfo: {
        addonInfoTransfer: {
          flightNr: input.inboundNr,
          flightTime: toDateString(input.inboundDate + ' ' + input.inboundTime),
          flightNrReturn: input.outboundNr,
          flightTimeReturn: toDateString(input.outboundDate + ' ' + input.outboundTime)
        }
      },
      flightNr: input.inboundNr,
      flightTime: toDateString(input.inboundDate + ' ' + input.inboundTime),
      flightNrReturn: input.outboundNr,
      flightTimeReturn: toDateString(input.outboundDate + ' ' + input.outboundTime)
    }));
}

function toDateString(str: string): string {
  return (
    toValidFormatOrNothing(
      str,
      RoundtripUi.FORMAT_DAY + ' ' + RoundtripUi.FORMAT_TIME,
      RoundtripTs.FORMAT
    ) || str
  );
}
