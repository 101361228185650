import i18next from "i18next";
import React from "react";
import { Input } from "reactstrap";
import { Keys } from "Translation/Setup";
import { TrainDirection } from "../../../../Models/TravelSeller/Models/TS_AddonTrain";

const trainDirection = [
  { label: i18next.t(Keys.WORKFLOW.Train.trainDirection.outbound), value: TrainDirection.OUTBOUND },
  { label: i18next.t(Keys.WORKFLOW.Train.trainDirection.incoming), value: TrainDirection.INBOUND }
];
interface Props {
  onChange: (value: TrainDirection) => void;
  value: TrainDirection;
}
export const RailFlyDirectionField = (props: Props) => {
  const value = props.value || "";

  const handleChange = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
    <Input type="select" value={value} onChange={handleChange}>
      {trainDirection.map((o, i) => (
        <option key={i} value={o.value!}>
          {o.label}
        </option>
      ))}
    </Input>
  );
};
