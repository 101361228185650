import React                       from 'react';
import { action, observable }      from 'mobx';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation }          from 'react-i18next';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { Language } from 'Translation/Setup';
import { isDev }    from 'env';
import app          from 'State/AppService';

import styles from './DevTools.module.scss';

type DevToolsProps = {
    children : React.ReactNode;
};

class DevToolsState {
    @observable isLanguageDropDownOpen = false;

    @action toggleLanguageDropDown() {
        this.isLanguageDropDownOpen = !this.isLanguageDropDownOpen;
        console.log( 'toggleLanguageDropDown', this );
    }
}

const DevToolsComponent = ( props : DevToolsProps ) => {

    const state    = useLocalStore( () => new DevToolsState() );
    const { i18n } = useTranslation();

    if ( !isDev ) {
        return <>
            { props.children }
        </>
    }

    return <>
        { props.children }

        <div className={ styles.container }>
            <div className={ styles.info }>IBE DevTools - won't render in production. Right, better double
                check that one... <span role="img" aria-label="goofy face">🤪</span>
            </div>
            <div className={ styles.languageSelection }>
                <Dropdown isOpen={ state.isLanguageDropDownOpen }
                          toggle={ () => state.toggleLanguageDropDown() }>
                    <DropdownToggle caret>
                        Language → { i18n.language && i18n.language.toLocaleUpperCase() }
                    </DropdownToggle>
                    <DropdownMenu>
                        { [ Language.DE, Language.EN ].map( lang =>
                            <DropdownItem
                                key={ 'btn_' + lang }
                                disabled={ i18n.language === lang }
                                onClick={ () => changeLanguage( lang ) }>
                                Switch to → <strong>{ lang.toLocaleUpperCase() }</strong>
                            </DropdownItem> ) }
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    </>;

    async function changeLanguage( l : string ) {
        app.activateLoader();
        await i18n.changeLanguage( l );
        app.deactivateLoader();
    }
};

const DevTools = observer( DevToolsComponent );
export default DevTools;
