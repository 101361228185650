import React, { useState } from 'react';

import Price from 'Components/Workflow/Price';
import { conditionalStyles } from 'Helpers/conditionalStyles';

import pepxpressStyles from './Component-pepxpress.module.scss';
import pepxciteStyles from './Component-pepxcite.module.scss';
import { FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Keys } from 'Translation/Setup';
import { AddonFlexPep } from '../../../../Models/UI/AddonFlexPep';
import { ThemeProps } from '../../../../Context/AppContext';
import { withTheme } from '../../../../Context/withTheme';

type Properties = {
  item: AddonFlexPep;
};
const AddonFlexPepForm: React.FunctionComponent<Properties & ThemeProps> = props => {
  const { t } = useTranslation();
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const css = conditionalStyles(styles);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { item } = props;

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={styles.content}>
        {item.title && (
          <div className={styles.content__header}>
            {props.theme !== 'pepxcite' && <h5 className={styles.content__header__title}>{props.item.title}</h5>}
            {item.infoURL && (
              <a
                href={item.infoURL.trim()}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.content__header__icon}
              >
                <FaInfoCircle />
              </a>
            )}
          </div>
        )}
        {item.descriptionList && (
          <div className={styles.descriptions}>
            <ul>
              {item.descriptionList.map((description, index) => (
                <li key={description} className={css({ 'd-none': index > 0 && !isExpanded })}>
                  {description}
                </li>
              ))}
            </ul>
          </div>
        )}
        <button className={styles.info} onClick={toggle}>
          {isExpanded
            ? t(Keys.WORKFLOW.AdditionalServices.readLess)
            : t(Keys.WORKFLOW.AdditionalServices.readMore)}
        </button>
      </div>
      <div className={styles.header}>
        <span>{t(Keys.WORKFLOW.AdditionalServices.AddonFlexPep.addToCart)}</span>
        <Price amount={props.item.totalPrice} prefix={''} />
      </div>
    </>
  );
};
export default withTheme(AddonFlexPepForm);
