import { action, observable } from 'mobx';
import { ExtendableError }    from "ts-error";

export class ExpectedError extends ExtendableError {}

export class RouteNotFoundError extends ExtendableError {}

export class EnpointError extends ExtendableError {}

export class DisplayableError extends ExtendableError {}

export class ErrorHandler {
    
    @observable error : Error | null = null;
    
    @action catchError( e : Error ) {
        console.error(e);
        this.error = e;
    }
    
    @action clear() {
        this.error = null;
    }
}

const errorHandler = new ErrorHandler();
export default errorHandler;
