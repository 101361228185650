import { observer } from 'mobx-react';
import React        from 'react';
import app          from 'State/AppService';
import workflow     from 'State/WorkflowService';

export const PaymentCheck = observer( () => {
    
    app.activateLoader();
    workflow.finalizePayment();
    
    return <></>;
} );
