import React, {useEffect} from 'react';
import { useTranslation }                from 'react-i18next';
import { Alert, Input }                  from 'reactstrap';
import {Formik, FormikProps}             from 'formik';
import { observer, useLocalStore }       from 'mobx-react';
import { Element as ScrollTo, scroller } from 'react-scroll';

import app          from 'State/AppService';
import workflow     from 'State/WorkflowService';
import errorHandler from 'State/ErrorHandler';

import { getSalutationLabel }  from 'Components/Forms/SelectField';
import DefaultLayout           from 'Components/Workflow/DefaultLayout';
import Headline                from 'Components/Workflow/Headline';
import Row                     from 'Components/Forms/Row';
import Nav                     from 'Components/Workflow/Nav';
import Cart                    from 'Components/Workflow/Cart';
import { PayloadParticipants } from 'Models/UI/PayloadParticipants';
import { Keys }                from 'Translation/Setup';
import { ParticipantsState }   from 'Workflow/Steps/Participants/ParticipantsState';

import styles                  from './styles.module.scss';
import formStyles              from './Forms/Forms.module.scss';
import { BusinessPartnerForm } from './Forms/BusinessPartnerForm';
import { PassengerForm }       from './Forms/PassengerForm';
import { Meta }                from './Models/Meta';
import BirthdayFields from './Forms/BirthdayFields';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { NationalitySelect } from '../../../Components/Forms/NationalitySelect';
import { withTheme } from 'Context/withTheme';
import {ThemeProps} from "../../../Context/AppContext";
import {useLanguage} from "../../../Helpers/useLanguage";
import TextField from '../../../Components/Forms/TextField';

const Participants = observer( function Participants(props: ThemeProps) {
    const genericErrorName = 'GenericError';
    const theme = props.theme;

    const state = useLocalStore( () => new ParticipantsState() );
    const { t, i18n } = useTranslation();
    const iframeSrcLanguage = useLanguage();

    useEffect( () => {
        (async () => {
            try {
                app.trackTimeLoadStarted( workflow.currentStep! );

                const {language} = await workflow.loadStep(theme === 'pepxcite');
                const globalLanguage = iframeSrcLanguage || language;
                if (!!globalLanguage) {
                    await i18n.changeLanguage(globalLanguage);
                }

                await state.setValues( workflow.currentMeta );

                if ( state.values ) {
                    app.tracking.setUser( state.values.businessPartner.email );
                }
                await app.tracking.addPageAndUser(workflow.booking)
                await app.trackTimeLoadFinished( workflow.currentStep! );
                if (workflow.currentStepIndex === 1 || theme === 'pepxcite') {
                    await app.tracking.sendBeginCheckoutEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes);
                } else {
                    await app.tracking.sendCheckoutStepEvent(workflow.booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes);
                }

                window.scrollTo(0, 0);

                app.deactivateLoader();

            } catch ( e ) {
                errorHandler.catchError( e );
            }
        })();
    }, [ state, theme, i18n, iframeSrcLanguage ] );

    if ( !state.values ) {
        return (<>
            <Breadcrumbs next={next}/>
            <DefaultLayout
            top={ renderTop() }
            right={ renderRight() }
            left={ <div>...</div> }
            bottom={ <div>...</div> }/>
        </>);
    }

    return <Formik enableReinitialize={ true }
                   initialValues={ state.values }
                   onSubmit={ (values, actions) => onSubmit( values, actions ) }
                   validate={ values => onValidate( values ) }
                   render={ formikProps => <>
                       <Breadcrumbs next={() => next(formikProps)}/>
                       <DefaultLayout
                       top={ renderTop() }
                       right={ renderRight() }
                       left={ renderLeft( formikProps ) }
                       bottom={ renderBottom( formikProps ) }/></> }/>;

    function renderLeft( formikProps : FormikProps<Meta> ) {
        return <div className={ styles.container }>

            <h4>{ t( Keys.WORKFLOW.Participants.mainCustomer ) }</h4>

            <ScrollTo name={ genericErrorName }>
                <Alert color="danger" isOpen={ state.showErrors && state.hasBusinessPartnerError }>
                    { t( Keys.WORKFLOW.Participants.formMainError ) }
                </Alert>
            </ScrollTo>

            <BusinessPartnerForm
                bp={ state.values!.businessPartner }
                property={ 'businessPartner.' }
                countries={ app.countries }/>

            <h4>{ t( Keys.WORKFLOW.Participants.travellers ) }</h4>

            <ScrollTo name={ genericErrorName }>
                <Alert color="danger" isOpen={ state.showErrors && state.hasPassengersError }>
                    { t( Keys.WORKFLOW.Participants.formMainError ) }
                </Alert>
            </ScrollTo>

            <Row label={ 'Name 1' } className={ formStyles.row }>
                <div className={ formStyles.fieldGroup }>
                    <div className={ formStyles.salutationContainer }>
                        <Input className={ formStyles.salutation } type="text"
                               value={ getSalutationLabel( formikProps.values.businessPartner.salutation || '' ) }
                               disabled={ true }/>
                    </div>
                    <div className={ formStyles.firstname }>
                        <Input type="text"
                               value={ state.values!.businessPartner.firstname }
                               disabled={ true }/>
                    </div>
                    <div className={ formStyles.surname } >
                        <Input type="text"
                               value={ state.values!.businessPartner.surname }
                               disabled={ true }/>
                    </div>
                </div>
                <div className={ formStyles.fieldGroup100 }>
                    { state.values!.passengers.length > 0 && (workflow.showParticipantBirthday || state.values!.passengers[0].dateOfBirthNeeded) &&
                        <BirthdayFields
                            day={ 'passengers.0.bday' }
                            month={ 'passengers.0.bmonth' }
                            year={ 'passengers.0.byear' }
                            formikProps={ formikProps }/>
                    }
                    {workflow.showParticipantNationality &&
                        <NationalitySelect values={ app.countries } label={ t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.nationality.label ) } name={'passengers.0.nationality' }/>
                    }
                </div>
                {state.values!.passengers[0].passportNeeded && <div className={ formStyles.fieldGroupWrap }>
                    <div className={ formStyles.passportNumber }>
                        <TextField label={ t( Keys.WORKFLOW.Participants.Forms.PassengerForm.passportNumber ) } name={'passengers.0.passportNumber'}/>
                    </div>
                    <div className={ formStyles.passportValidThru }>
                        <TextField label={ t( Keys.WORKFLOW.Participants.Forms.PassengerForm.passportValidThru ) } name={'passengers.0.passportValidThru'}/>
                    </div>
                </div>}
            </Row>

            <div className={ styles.passengers }>
                { state.values!.passengers.map( ( p, i ) => {
                    if ( i === 0 ) {
                        return '';
                    }
                    return <div key={ 'pass_' + i } className={ styles.passenger }>
                        <PassengerForm
                            label={ `Name ${ i + 1 }*` }
                            passenger={ p }
                            formikProps={ formikProps }
                            property={ `passengers.${ i }.` }
                            workflow={workflow}
                            countries={ app.countries }
                            />
                    </div>
                } ) }
            </div>

        </div>
    }

    function renderTop() {
        return <>
            <Headline text={ t( Keys.WORKFLOW.Participants.headline ) }/>
        </>;
    }

    function renderRight() {
        return <>
            <Cart bookingOnRequest={workflow.bookingOnRequest}/>
        </>;
    }

    function renderBottom( formikProps : FormikProps<Meta> ) {
        return <>

            <div className={ styles.mandatoryNote }>
                { t( Keys.WORKFLOW.Participants.formMainError ) }

            </div>

            <Alert color="danger" isOpen={ state.showErrors }>
                { t( Keys.WORKFLOW.Participants.formMainError ) }
            </Alert>

            <Alert color="danger" isOpen={ state.apiErrorMessage !== null }>
                <strong>{ t( Keys.WORKFLOW.Participants.apiError ) }</strong><br/>
                { state.apiErrorMessage }
            </Alert>

            {state.isFirstStep &&
                <Nav
                    onCloseWindow={() => window.top.close()}
                    onNext={ () => next(formikProps) }/>
            }

            {!state.isFirstStep &&
                <Nav onPrev={ () => onPrev() }
                    onNext={ () => next(formikProps) }/>
            }


        </>;
    }

    function next(formikProps?: FormikProps<Meta>) {
        state.setWasSubmitted( true );
        formikProps && formikProps.submitForm();
    }

    async function onValidate( values : Meta ) {
        await state.setValues( values );
        if ( !state.isValid ) {
            throw state.errors; // formik needs it to display errors
        }
    }

    async function onSubmit( values : Meta, actions:any ) {

        app.activateLoader();

        actions.setSubmitting(true);

        state.updateApiErrorMessage(null);

        await state.setWasSubmitted( true );
        await state.setValues( values );

        console.log( 'onSubmit', values, state );

        if ( !state.isValid ) {
            app.deactivateLoader();
            scrollToError();
            return;
        }

        app.trackTimeSaveStarted( workflow );

        try {

            const payload           = new PayloadParticipants();
            payload.businessPartner = values.businessPartner;
            payload.passengers      = values.passengers;

            await workflow.saveStep( payload );

            workflow.navigateToNextStep();

        } catch ( e ) {
            state.updateApiErrorMessage(
                e.message || 'Ihre Daten konnten nicht gespeichert werden.'
            );
            actions.setSubmitting(false);
        }
        app.deactivateLoader();

        app.trackTimeSaveFinished( workflow );

    }

    function onPrev() {
        if ( !state.isFirstStep ) {
            workflow.navigateToPrevStep();
        }
    }

    function scrollToError() {
        scroller.scrollTo( genericErrorName, {
            duration : 1000,
            smooth   : true,
            offset   : -20
        } );
    }

} );

export default withTheme(Participants);
