import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import workflow from 'State/WorkflowService';
import app from 'State/AppService';
import { Keys } from 'Translation/Setup';
import { conditionalStyles } from 'Helpers/conditionalStyles';

import pepxpressStyles from './Breadcrumbs-pepxpress.module.scss';
import pepxciteStyles from './Breadcrumbs-pepxcite.module.scss';
import { ThemeProps } from '../../../Context/AppContext';
import { withTheme } from '../../../Context/withTheme';

const Steps = (Keys.COMMON.Steps as unknown) as { [key: string]: string };
interface Props {
  next?: () => void;
}

const BreadcrumbsComponent = (props: Props & ThemeProps) => {
  const { t } = useTranslation();
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const css = conditionalStyles(styles);
  let crumbs = Object.keys(workflow.getWorkflowSteps())
    .slice(1)
    .filter(s => !workflow.excludedSteps.hasOwnProperty(s));
  if (props.theme === 'pepxcite') {
    crumbs = crumbs.filter(step => step !== 'pep-upsell');
  }

  const activeIndex = crumbs.indexOf(workflow.currentStep!);
  // const isLastStep  = activeIndex === crumbs.length - 1;

  if (activeIndex === -1) {
    return <ul className={styles.crumbs}></ul>;
  }

  return (
    <ul className={styles.crumbs}>
      {crumbs.map((step: string, i) => {
        const number = <span className={styles.number}>{i + 1}</span>;
        const separator = <span className={styles.separator}>. </span>;
        const name = (
          <span className={styles.name}>{app.isDoingInitLoading ? '...' : t(Steps[step])}</span>
        );

        const isEnabled = i <= activeIndex + 1;
        const isDisabled = i > activeIndex + 1;
        const isActive = i === activeIndex;

        const classNames = css('crumb', {
          'crumb--isActive': isActive,
          'crumb--isDisabled': isDisabled,
          'crumb--isEnabled': isEnabled
        });

        return (
          <li key={step} className={classNames}>
            {isEnabled ? (
              <span
                className={styles.link}
                tabIndex={0}
                onKeyPress={e => navigate(e, step)}
                onClick={e => navigate(e, step)}
              >
                {number}
                {separator}
                {name}
              </span>
            ) : (
              <span className={styles.link}>
                {number}
                {separator}
                {name}
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );

  function navigate(e: React.KeyboardEvent | React.MouseEvent, step: string) {
    e.preventDefault();
    if (workflow.getIndexForStep(step) < workflow.currentStepIndex) {
      workflow.setStepType('backward');
      workflow.navigate(step, true);
    } else {
      workflow.setStepType('forward');
      props.next && props.next();
    }
  }
};

const Breadcrumbs = observer(BreadcrumbsComponent);
export default withTheme(Breadcrumbs);
