import {IsDefined, IsNumber, IsString} from 'class-validator';
import { Type } from 'class-transformer';
import {EcommerceItem} from "./EcommerceItem";

export class Ecommerce {
    @IsDefined()
    @Type( () => EcommerceItem )
    items : EcommerceItem[];

    @IsString()
    currency : string;

    @IsNumber()
    tax : number;

    @IsNumber()
    shipping : number;

    @IsString()
    transaction_id : string;

    @IsString()
    payment_type : string;

    @IsString()
    coupon : string;

    @IsNumber()
    coupon_value: number;

    @IsNumber()
    value: number;
}
