import { TS_RITDestination } from "./TS_RITDestination";
import { TS_RITOrigin } from "./TS_RITOrigin";

export class TS_BookInfo {
  static FORMAT_DATE: string = "DD.MM.YYYY";

  priceTotal: number;
  destination: string;
  ritDestination: TS_RITDestination;
  ritOrigin: TS_RITOrigin;
  ritMode: string;
  checkin: string;
  checkout: string;
  isValid: boolean;
  language: string;
}
