import React              from 'react';
import { useTranslation } from 'react-i18next';

import { getSalutationLabel } from 'Components/Forms/SelectField';
import { Keys }               from 'Translation/Setup';
import { BusinessPartner }    from 'Workflow/Steps/Participants/Models/BusinessPartner';

import styles from './ContactData.module.scss';

interface Props {
    person : BusinessPartner;
}

const ContactData = ( props : Props ) => {
    const { t }      = useTranslation();
    const { person } = props;
    
    return <div className={ styles.person }>
        <div>
            <h5>{ t( Keys.COMPONENTS.ContactData.payment.headline ) }</h5>
            <div>
                { concat( getSalutationLabel( person.salutation ), person.firstname, person.surname ) }
            </div>
            <div>
                { concat( person.street, person.number ) }
            </div>
            <div>
                { concat( person.postcode, person.city ) }
            </div>
        </div>
        <div>
            <h5>{ t( Keys.COMPONENTS.ContactData.contact.headline ) }</h5>
            <div>{ t( Keys.COMPONENTS.ContactData.contact.phone ) }: { person.phone }</div>
            <div>{ t( Keys.COMPONENTS.ContactData.contact.mobile ) }: { person.mobile }</div>
            <div>{ t( Keys.COMPONENTS.ContactData.contact.email ) }: { person.email }</div>
        </div>
    </div>;
    
    function concat( ...items : string[] ) {
        return items.join( ' ' );
    }
};

export default ContactData;
