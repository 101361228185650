import { IsDefined } from 'class-validator';

export class Page {

    @IsDefined()
    name : string;
    
    @IsDefined()
    type : string;

    @IsDefined()
    language : string;

    @IsDefined()
    country : string;
    
    @IsDefined()
    IBE : string;

    @IsDefined()
    status : string;
}