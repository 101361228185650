import React, { useEffect } from 'react';
import { useObserver }      from 'mobx-react';
import { Type }             from 'class-transformer';
import { IsDefined }        from 'class-validator';
import { ValidateNested }   from 'class-validator';

import SelectFieldOption  from 'Models/SelectFieldOption';
import { useTranslation } from 'react-i18next';
import { getInstance }    from 'Helpers/validate';
import app                from 'State/AppService';
import errorHandler       from 'State/ErrorHandler';
import workflow           from 'State/WorkflowService';
import {Keys} from 'Translation/Setup';

import styles from './styles.module.css';
import {useLanguage} from "../../../Helpers/useLanguage";

class Meta {

    @ValidateNested()
    @Type( () => User )
    businessPartner : User;

    countries : SelectFieldOption[];
}

class User {

    @IsDefined()
    email : string
}

export const Entry = () => {
    const { t, i18n } = useTranslation();
    const iframeSrcLanguage = useLanguage();

    useEffect( () => {
        app.trackTimeLoadStarted( workflow.currentStep! );
        workflow.resetBooking();
        workflow.setBookingSessionId( workflow.currentParams );
        workflow.loadStep()
                .then( ({language}) => {
                    const globalLanguage = iframeSrcLanguage || language;
                    if (!!globalLanguage) {
                        i18n.changeLanguage(globalLanguage);
                    }
                    return getMeta(workflow!.currentMeta);
                } )
                .then( meta => storeUser( meta ) )
                .then( meta => storeCountries( meta ) )
                .then( () => app.trackTimeLoadFinished( workflow.currentStep! ) )
                .then( () => workflow.navigateToNextStep( true ) )
                .catch( e => errorHandler.catchError( e ) );
    }, [iframeSrcLanguage, i18n]);

    return useObserver( () => (
        <div className={ styles.container }>
            { t( Keys.WORKFLOW.Entry.loading ) }
        </div>
    ) );

    function getMeta( from : object ) : Meta {
        return getInstance<Meta, {}>( Meta, from );
    }

    function storeUser( meta : Meta ) : Meta {
        app.setUser( meta.businessPartner.email || "" );
        return meta;
    }

    function storeCountries( meta : Meta ) : Meta {
        app.setCountries( meta.countries );
        return meta;
    }
};
