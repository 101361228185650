import React             from 'react';
import currencyFormatter from 'currency-formatter';

import styles from './Price.module.scss';

interface Props {
    amount : string | number;
    prefix? : string;
    suffix? : string;
}

const Price : React.FunctionComponent<Props> = ( props ) => {

    const prefix = props.prefix || '';
    const suffix = props.suffix || '';

    if ( props.amount === null ) {
        return <span></span>;
    } else if ( typeof props.amount === 'string' ) {
        return <span className={ styles.inclusive }>{ props.amount }</span>;
    } else {
        const formatted = currencyFormatter.format( props.amount as number, { code : 'EUR' } );
        return <span className={ styles.price }>{ prefix }{ formatted }{ suffix }</span>;
    }

};

export default Price;