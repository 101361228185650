import React from "react";
import { FaInfoCircle } from "react-icons/all";
import styles from "../Train.module.scss";

interface Props {
  url: string;
}

export const Info = ({ url }: Props) => {
  return (
    <a className={styles.trainInfo} href={url} target={"_blank"}>
      <FaInfoCircle />
    </a>
  );
};
