import i18next    from 'i18next';
import React      from 'react';
import { Form }   from 'formik';

import SelectFieldOption   from 'Models/SelectFieldOption';
import TextField           from 'Components/Forms/TextField';
import { SelectField }     from 'Components/Forms/SelectField';
import { SalutationField } from 'Components/Forms/SelectField';
import Row                 from 'Components/Forms/Row';
import { Keys }            from 'Translation/translations.de.json.keys';

import styles              from './Forms.module.scss';
import { BusinessPartner } from '../Models/BusinessPartner';

export const BusinessPartnerForm : React.FunctionComponent<{
    property : string,
    countries : SelectFieldOption[],
    bp : BusinessPartner
}> = ( p ) => {
    //const phoneMask  = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const property  = p.property;
    const countries = p.countries || [];
    const bp        = p.bp;
    return (
        <Form className={ styles.form }>
            <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.name ) } className={ styles.row }>
                <div className={ styles.fieldGroup }>
                    <div className={ styles.salutationContainer }>
                        <SalutationField
                            className={ styles.salutation }
                            label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.title ) } name={ property + 'salutation' }
                            isDisabled={ !bp.isAffiliate }/>
                    </div>
                    <TextField
                        className={ styles.firstname }
                        label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.firstname ) } name={ property + 'firstname' }
                        isDisabled={ !bp.isAffiliate }/>
                    <TextField
                        className={ styles.surname }
                        label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.surname ) } name={ property + 'surname' }
                        isDisabled={ !bp.isAffiliate }/>
                </div>
            </Row>

            { bp.isAffiliate && !bp.noCompanyNeeded && <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.employer.required ) } className={ styles.row }>
                <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.organisation ) } name={ property + 'company' }/>
            </Row> }

            <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.street.required ) } className={ styles.row }>
                <div className={ styles.fieldGroup + ' noWrap' }>
                    <TextField className={ styles.street } label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.street.label ) } name={ property + 'street' }/>
                    <TextField className={ styles.number } label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.streetNumber ) } name={ property + 'number' }/>
                </div>
            </Row>
            <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.town.required ) } className={ styles.row }>
                <div className={ styles.fieldGroup }>
                    <TextField className={ styles.postcode } label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.postcode ) } name={ property + 'postcode' }/>
                    <TextField className={ styles.city } label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.city ) } name={ property + 'city' }/>
                </div>
            </Row>
            <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.country.required ) } className={ styles.row }>
                <SelectField values={ countries } label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.country.label ) } name={ property + 'country' }/>
            </Row>
            <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.phone ) } className={ styles.row }>
                <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.phone ) } name={ property + 'phone' }/>
            </Row>
            <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.mobile.required ) } className={ styles.row }>
                <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.mobile.label ) } name={ property + 'mobile' }
                           note={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.mobile.emergency ) }/>
            </Row>
            { bp.isAffiliate && <Row label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.email.required ) } className={ styles.row }>
                <TextField label={ i18next.t( Keys.WORKFLOW.Participants.Forms.BusinessPartnerForm.email.label ) } name={ property + 'email' }/>
            </Row> }
        </Form>
    );
}
