import { registerDecorator, ValidationOptions } from "class-validator";
import { ValidationArguments } from 'class-validator';
import moment from "moment";

export function IsPassportExpiryDate(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator( {
            name: "IsPassportExpiryDate",
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                //eslint-disable-next-line no-empty-pattern
                validate({}, validationArguments: ValidationArguments) {
                    const object = validationArguments.object as any;
                    const passportValidThru = object.passportValidThru || null;

                    return passportValidThru && moment(passportValidThru, 'DD.MM.YYYY', true).isValid();
                }
            }
        } );
    };
}
