import React, { FunctionComponent } from 'react';

import styles   from './Row.module.scss';
import { join } from 'Helpers/conditionalStyles';

interface Props {
    label : string;
    className? : string;
}

const Row : FunctionComponent<Props> = ( props ) => {
    return (
        <div className={ join( styles.row, props.className || '' ) }>
            <div className={ styles.label }>{ props.label }</div>
            <div className={ styles.content }>{ props.children }</div>
        </div>
    );
};

export default Row;
