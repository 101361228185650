import React from 'react';
import {
  Card,
  CardImg,
  CardTitle,
  CardHeader,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardText
} from 'reactstrap';
import { Offer } from '../../../../Models/UI/Offer';
import pepxpressStyles from '../Upsell-pepxpress.module.scss';
import pepxciteStyles from '../Upsell-pepxcite.module.scss';
import Price from '../../../../Components/Workflow/Price';
import { useTranslation } from 'react-i18next';
import { Keys } from '../../../../Translation/Setup';
import { FaStar } from 'react-icons/fa';
import _ from 'lodash';
import { ThemeProps } from '../../../../Context/AppContext';

interface Props {
  offer: Offer;
}
const Key = Keys.WORKFLOW.Upsell;

export const OfferCard = (props: Props & ThemeProps) => {
  const { offer, theme } = props;
  const styles = theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const { t } = useTranslation();
  return (
    <Card className={styles.offerbox + ' mb-3'}>
      <a href={offer.infoURL} target="_blank" rel="noopener noreferrer">
        <CardImg src={offer.imageURL} />
      </a>
      <CardHeader className={styles.cardHeader}>
        <div>
          {_.fill(Array(3), 1).map((_, i) => (
            <FaStar key={'star' + i} />
          ))}
        </div>
        <div>
          <span>{t(Key.from)}&nbsp;</span>
          <span className={styles.price}>
            <Price amount={+offer.totalPrice} />
          </span>
        </div>
      </CardHeader>
      <CardBody className={styles.cardBody}>
        <CardTitle className={styles.cardTitle}>{offer.description}</CardTitle>
        <CardSubtitle className={styles.cardSubTitle}>{offer.title}</CardSubtitle>
        <div className={styles.cardDate}>
          {offer.dateFrom} - {offer.dateUntil}
        </div>
        <CardText className={styles.cardText} dangerouslySetInnerHTML={{ __html: offer.teaser }} />
      </CardBody>
      <CardFooter className={styles.cardFooter}>
        <a href={offer.infoURL} target="_blank" rel="noopener noreferrer">
          {t(Key.goToLink)}
        </a>
      </CardFooter>
    </Card>
  );
};
