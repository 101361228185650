import React, { useState } from 'react';
import Price from 'Components/Workflow/Price';
import { Item } from 'Models/UI/Item';
import workflow from 'State/WorkflowService';
import pepxpressStyles from './Component-pepxpress.module.scss';
import pepxciteStyles from './Component-pepxcite.module.scss';
import { FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Keys } from 'Translation/Setup';
import { ThemeProps } from '../../../../Context/AppContext';
import { withTheme } from '../../../../Context/withTheme';
import { AddonEvent } from '../../../../Models/UI/AddonEvent';
import { ItemType } from '../../../../Models/UI/ItemType';

type Properties = {
  item: Item;
  isStartPrice?: boolean;
};
const Component: React.FunctionComponent<Properties & ThemeProps> = props => {
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const bookingItem = workflow.selectedItems?.find(
    selectedItem => selectedItem.id === props.item.id
  );

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isDisabledForMusicals =
    props.item instanceof AddonEvent &&
    props.item.type === ItemType.EVENT &&
    (props.item as AddonEvent).dates.length === 0;

  const isMusical = props.item instanceof AddonEvent;

  return (
    <>
      <div className={styles.header}>
        <span>{props.item.title}</span>
        <span>
          {props.isStartPrice && !bookingItem && !isDisabledForMusicals && (
            <span>{t(Keys.WORKFLOW.AdditionalServices.from)}&nbsp;</span>
          )}
          {!isDisabledForMusicals && (
            <Price
              amount={!!bookingItem ? bookingItem.totalPrice || 0 : props.item.totalPrice}
              prefix={''}
            />
          )}
          {isDisabledForMusicals && t(Keys.WORKFLOW.AdditionalServices.musicalNotAvailable)}
        </span>
      </div>
      {props.item.description && !isMusical && (
        <>
          <div className={isExpanded ? '' : 'text-truncate'}>
            <>
              <FaInfoCircle className="mr-1" />
              <span> {props.item.description}</span>
            </>
            <br />
            {props.item.infoURL && isExpanded && (
              <a
                href={props.item.infoURL}
                className="alert-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.item.infoURL}
              </a>
            )}
          </div>
          <button className={styles.info} onClick={toggle}>
            {isExpanded
              ? t(Keys.WORKFLOW.AdditionalServices.readLess)
              : t(Keys.WORKFLOW.AdditionalServices.readMore)}
          </button>
        </>
      )}
      {props.item.description && isMusical && (
        <>
          <span dangerouslySetInnerHTML={{ __html: props.item.description }} />
          <br />
          {props.item.infoURL && isExpanded && (
            <a
              href={props.item.infoURL}
              className="alert-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.item.infoURL}
            </a>
          )}
        </>
      )}
    </>
  );
};

export default withTheme(Component);
