import { BookingItemInterface } from "./BookingItemInterface";
import { RITDestination } from "./RITDestination";
import { RITOrigin } from "./RITOrigin";
import { TC } from "./TC";

export class Booking {
  static FORMAT_DATE: string = "DD.MM.YYYY";

  isOk: boolean | null = null;
  totalPrice: number = 0;
  tax: number = 0;
  shipping: number = 0;
  transaction_id: string = '';
  coupon: string = '';
  coupon_value: number = 0;
  payment_type: string = '';
  bookingNumber: string | null = null;
  bookingOnRequest: boolean = false;
  destination: string | null = null;
  travelStart: string | null = null;
  travelEnd: string | null = null;
  workflow: string | null = null;
  ritDestination: RITDestination | null = null;
  ritOrigin: RITOrigin | null = null;
  ritMode: string | null = null;
  items: BookingItemInterface[] = [];
  termsAndConditionList: TC[];
  needPaxDateOfBirth: boolean = false;
  needPaxNationality: boolean = false;
  isValid: boolean = true;
  bookFailedMessageHtml: string = '';
  jumpToStep: string = '';
  language: string = '';
}
