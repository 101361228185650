import React from 'react';

import { conditionalStyles } from 'Helpers/conditionalStyles';
import styles                from './DefaultLayout.module.scss';

const css = conditionalStyles( styles );

interface Props {
    top? : JSX.Element;
    left? : JSX.Element;
    right? : JSX.Element;
    bottom? : JSX.Element;
    isTwoColumn? : boolean;
}

const DefaultLayout : React.FunctionComponent<Props> = ( p ) => {
    
    const isTwoColumn = p.isTwoColumn || true;
    
    const layout = css( 'layout', {
        'layout--2col' : isTwoColumn === true
    } );
    
    return <div className={ layout }>
        <div className={ styles.top }>{ p.top }</div>
        <div className={ styles.leftRight }>
            <div className={ styles.left }>{ p.left }</div>
            <div className={ styles.right }>{ p.right }</div>
        </div>
        <div className={ styles.bottom }>{ p.bottom }</div>
    </div>;
}

export default DefaultLayout;
