import React from "react";
import Autosuggest from "react-autosuggest";
import SelectFieldOption from "../../Models/SelectFieldOption";
import styles from "./AutosuggestWrapper.module.scss";

interface Props {
  preSelectedValue: string;
  values: SelectFieldOption[];
  id: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const AutosuggestWrapper = (props: Props) => {
  const values = props.values;
  const preSelectedValue = props.preSelectedValue || "";
  const placeholder = props.placeholder ? props.placeholder : "";

  const [value, setValue] = React.useState(preSelectedValue);
  const [suggestions, setSuggestions] = React.useState<SelectFieldOption[]>([]);

  React.useEffect(() => {
    setValue(preSelectedValue);
  }, [preSelectedValue]);

  const clearValue = () => {
    setValue("");
    props.onChange("");
  };

  const onChangeValue = (event: any, obj: any) => {
    setValue(obj.newValue);
  };

  const onBlurValue = (event: any, obj: any) => {
    if (
      !!obj &&
      !!obj.highlightedSuggestion &&
      !!obj.highlightedSuggestion.label
    ) {
      setValue(obj.highlightedSuggestion.label);
      // props.onChange(obj.highlightedSuggestion.value);
    }
  };

  const onSuggestionSelected = (event: any, obj: any) => {
    props.onChange(obj.suggestion.value);
  };

  const onSuggestionsFetchRequested = (obj: any) => {
    setSuggestions(getSuggestions(obj.value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (obj: any) => {
    return obj.label;
  };

  const renderSuggestion = (obj: any) => {
    return <div>{obj.label}</div>;
  };

  const getSuggestions = (value: any) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength === 0) {
      return [];
    } else {
      return values.filter(
        (val: SelectFieldOption) =>
          !!val &&
          !!val.label &&
          val.label.toLowerCase().slice(0, inputLength) === inputValue
      );
    }
  };

  const inputProps = {
    placeholder: placeholder,
    value: value,
    onChange: onChangeValue,
    onBlur: onBlurValue
  };

  return (
    <>
      <div>
        <Autosuggest
          id={props.id}
          suggestions={suggestions}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          highlightFirstSuggestion
          theme={styles}
        />
        <i className={styles.times} onClick={() => clearValue()}>
          &times;
        </i>
      </div>
    </>
  );
};
