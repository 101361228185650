import React from 'react';
import { TiTick } from 'react-icons/ti';
import { observer } from 'mobx-react';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import { ExtraNights as ExtraNightsItem } from 'Models/UI/ExtraNights';

@observer
export default class ExtraNights extends React.Component<
  {
    items: BookingItemInterface[];
    styles: { [key: string]: string };
  },
  {}
> {
  render() {
    return (
      <ul className={this.props.styles.items}>
        {this.extraNights.map(item => {
          return (
            <li className={this.props.styles.item} key={'extraNight_' + item.id}>
              <TiTick /> <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
            </li>
          );
        })}
      </ul>
    );
  }

  get extraNights() {
    return this.props.items.filter(i => i instanceof ExtraNightsItem);
  }
}
