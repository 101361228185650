import { TS_ItemInterface } from "Models/TravelSeller/Models/TS_ItemInterface";
import { TS_ItemType } from "./TS_ItemType";
import { TS_Addon } from "./TS_Addon";
import { Type } from "class-transformer";

export class SelectionPerDirection {
  classCode: number; // 'FIRST'
  stationCode: string; // 'NurembergHBF'

  train: TrainConnectionItem;
  // ot trainId : string;

  cabinCategory: number; // 'SleepWagon'
  seatCategory: number; // 'Window'
  area: number; // 'SmokingArea'

  searchHour: number;

  get hasStepOne(): boolean {
    return !!this.classCode && !!this.stationCode;
  }

  get hasStepTwo(): boolean {
    return !!this.train && Object.keys(this.train).length !== 0;
  }

  get hasStepThree(): boolean {
    return (
      this.cabinCategory != null &&
      this.seatCategory != null &&
      this.area != null
    );
  }

  get isReadyToBook(): boolean {
    return this.hasStepOne && this.hasStepTwo && this.hasStepThree;
  }
}

export class TrainConnectionItem {
  trainID: string;
  departureTime: string;
  arrivalTime: string;
  duration: string;
  changes: string;
  name: string;
  price: number;
  @Type(() => TrainDetailConnectionItem)
  details:TrainDetailConnectionItem[];
}

export class TrainDetailConnectionItem {
  detID: string;
  departure: string;
  departureTime: string;
  arrival: string;
  arrivalTime: string;
  name: string;
}

export enum TrainDirection {
  ROUNDTRIP = "both",
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

export class TrainBundle {
  id: string;
  @Type(() => TrainBundleItem)
  bundle: TrainBundleItem[];
}

export class TrainBundleItem {
  direction: string;
  @Type(() => TrainBundleTariff)
  tarife: TrainBundleTariff[];
}

export class TrainBundleTariff {
  @Type(() => TrainBundleTariffDisplayInfo)
  displayInfo: TrainBundleTariffDisplayInfo;
  @Type(() => TrainBundleTariffPrice)
  price: TrainBundleTariffPrice;
}

export class TrainBundleTariffDisplayInfo {
  displayName: string;
  @Type(() => TrainBundleTariffDisplayText)
  displayTexts: TrainBundleTariffDisplayText[];
}

export class TrainBundleTariffDisplayText {
  title: string;
  priority: number;
  shortText: string;
  longText: string;
  translations: {
    en: TrainBundleTariffDisplayTextTranslations
  };
}

export class TrainBundleTariffDisplayTextTranslations {
  title: string;
  shortText: string;
  longText: string;
}

export class TrainBundleTariffPrice {
  amount: number;
  @Type(() => TrainBundleTariffPriceVatDetails)
  vatDetails: TrainBundleTariffPriceVatDetails[];
  currency: string;
}

export class TrainBundleTariffPriceVatDetails {
  country: string;
  vatAmount: number;
  vatGross: number;
  vatNet: number;
  vatRate: number;
}

export class TS_AddonTrain extends TS_Addon implements TS_ItemInterface {
  type = TS_ItemType.TRAIN; // TODO - wrong type from travelseller - should be TS_ItemType.TRAIN

  descriptionDetail: string[];

  dir: TrainDirection;

  // will only be filled by TS
  @Type(() => TrainConnectionItem)
  departingTrains: TrainConnectionItem[];
  @Type(() => TrainConnectionItem)
  returningTrains: TrainConnectionItem[];

  // will be filled by IBE (and preselection by TS)
  @Type(() => SelectionPerDirection)
  departingSelection: SelectionPerDirection;
  @Type(() => SelectionPerDirection)
  returningSelection: SelectionPerDirection;

  @Type(() => TrainBundle)
  bundles: TrainBundle[];
  trainBundleSelected: string;

  get isReadyToBook(): boolean {
    return (
      this.departingSelection.isReadyToBook ||
      this.returningSelection.isReadyToBook
    );
  }
}
