import { IsDefined } from 'class-validator';
import {Page} from "../Objects/Page";
import {User} from "../Objects/User";

export class PageEvent {

  @IsDefined()
  page : Page;

  @IsDefined()
  user : User;
}
