import { TS_ItemType } from 'Models/TravelSeller/Models/TS_ItemType';

const keys   = Object.keys( TS_ItemType );
const values = Object.values( TS_ItemType );

const itemTypesByValue : { [ value : string ] : string } = {};
//eslint-disable-next-line no-empty-pattern
keys.forEach( ( {}, i ) => {
    itemTypesByValue[ values[ i ] ] = keys[ i ];
} );

export class ItemType extends TS_ItemType {
    
    public static getByValue( v : string ) {
        try {
            return itemTypesByValue[ v ];
        } catch ( e ) {
            return null;
        }
    }
}