import 'reflect-metadata';
import React from 'react';
import 'typeface-droid-sans';
import 'typeface-open-sans-condensed';
import App from 'Components/App';
import * as serviceWorker from './serviceWorker';
import { render } from 'react-dom';
import {LanguageProvider} from "./Helpers/useLanguage";

const getTheme = async () => {
  console.log("location.href", document.location.href)
  const language = new URLSearchParams(window.location.search).get('lang');
  if (document.location.href.indexOf('pepxcite') > -1) {
    const theme = await import('./Themes/Pepxcite/Pepxcite');
    return {Theme: theme.default, language};
  } else {
    const theme = await import('./Themes/Pepxpress/Pepxpress');
    return {Theme: theme.default, language};
  }
};

getTheme().then(({Theme, language}) =>
  render(
    <Theme>
      <LanguageProvider language={language || ''}>
        <App language={language} />
      </LanguageProvider>
    </Theme>,
    document.getElementById('app')
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes withValidation some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
