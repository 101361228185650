import { plainToClass } from 'class-transformer';
import { ClassType } from 'class-transformer/ClassTransformer';
import { withFormik as formik } from 'formik';
import { merge } from 'lodash-es';

import { getValidationErrorsPlain } from 'Helpers/validate';
import FormConfiguration from './FormConfiguration';

const withFormik = <I>(itemClass: ClassType<I>, defaults: I) =>
  formik({
    isInitialValid: true,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    mapPropsToValues: p => merge(Object.assign({}, p.item), defaults),

    validate: async (values: I, p: FormConfiguration<I>) => {
      try {
        const errors = await getValidationErrorsPlain(itemClass, values);
        const isValid = Object.keys(errors).length === 0;
        const newItem = plainToClass(itemClass, Object.assign({}, p.item, values)); // by cloning

        await p.onChange(newItem, errors);

        if (!isValid) {
          //noinspection ExceptionCaughtLocallyJS
          throw errors; // required by formik
        }
      } catch (e) {
        throw e;
      }
    },

    // we dont submit using this
    // values are getting passed outside onChange (see validate method)
    handleSubmit: () => {}
  });

export default withFormik;
