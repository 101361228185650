import { registerDecorator, ValidationOptions } from "class-validator";
import { ValidationArguments }                  from 'class-validator';

export function IsTsBirthday( validationOptions? : ValidationOptions ) {
    return function ( object : Object, propertyName : string ) {
        registerDecorator( {
            name         : "isTsBirthday",
            target       : object.constructor,
            propertyName : propertyName,
            constraints  : [],
            options      : validationOptions,
            validator    : {
                //eslint-disable-next-line no-empty-pattern
                validate( {}, validationArguments : ValidationArguments ) {
                    const object = validationArguments.object as any;
                    
                    let bday   = object.bday || null;
                    let bmonth = object.bmonth || null;
                    let byear  = object.byear || null;
                    
                    if ( bday ) {
                        bday = parseInt( bday );
                    }
                    if ( bmonth ) {
                        bmonth = parseInt( bmonth );
                    }
                    if ( byear ) {
                        byear = parseInt( byear );
                    }
                    
                    return isValidDate( byear, bmonth - 1, bday );
                }
            }
        } );
    };
}

// Attention:
// Month is 0-based in JS!
// January == 0,
// February == 1
// and so on ...
function isValidDate( year : number, month : number, day : number ) : boolean {
    var d = new Date( year, month, day );
    if ( d.getFullYear() === year && d.getMonth() === month && d.getDate() === day ) {
        return true;
    }
    return false;
}