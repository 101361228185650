import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import { ItemType } from 'Models/UI/ItemType';

export class AddonTransferRoundtrip extends AddonTransferOnewayOutbound {
  type = ItemType.TRANSFER_ROUNDTRIP;

  outboundNr: string;
  outboundDate: string;
  outboundTime: string;
}
