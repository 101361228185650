import {
    IsArray, IsDefined,
    IsNotEmpty, IsString,
    ValidateNested
}                             from 'class-validator';
import { TS_Item }            from '../Models/TS_Item';
import { TS_Booking }         from '../Models/TS_Booking';
import { TS_ResponseApiCall } from './TS_ResponseApiCall';

type WorflowStepId = string;

export class TS_Response {
    
    @IsDefined()
    @IsNotEmpty()
    @IsString()
    for : WorflowStepId;
    
    @IsDefined()
    @IsArray()
    @ValidateNested()
    items : TS_Item[];
    
    @IsDefined()
    @IsArray()
    @IsNotEmpty()
    workflow : string[];
    
    @IsDefined()
    @IsNotEmpty()
    booking : TS_Booking;
    
    meta : any;
    
    _ts_api_calls : { [ key : string ] : TS_ResponseApiCall };
}