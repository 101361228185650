import { DateSelect } from 'Components/Forms/DateSelect';
import { TimeSelect } from 'Components/Forms/TimeSelect';
import i18next from 'i18next';
import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import React from 'react';
import { Label } from 'reactstrap';
import moment from 'moment';
import { IsNotEmpty } from 'class-validator';
import { Field, Form } from 'formik';

import { AddonTransferRoundtrip } from 'Models/UI/AddonTransferRoundtrip';
import { IsMomentFormat } from 'Helpers/Validators/IsMomentFormat';
import { conditionalStyles, join } from 'Helpers/conditionalStyles';
import { Keys } from 'Translation/translations.de.json.keys';

import pepxpressStyles from './Component-pepxpress.module.scss';
import pepxciteStyles from './Component-pepxcite.module.scss';
import FormCollapsible from '../FormCollapsible';
import FormConfiguration from '../FormConfiguration';
import withFormik from '../withFormik';
import { withTheme } from '../../../../Context/withTheme';
import { ThemeProps } from '../../../../Context/AppContext';

require('moment/locale/de');

const Key = Keys.WORKFLOW.AdditionalServices.AddonTransfer;

export class AddonTransferRoundtripFormModel extends AddonTransferRoundtrip {
  @IsNotEmpty({ message: i18next.t(Key.inboundNrIsEmpty) })
  inboundNr: string;

  @IsNotEmpty({ message: i18next.t(Key.inboundDateIsEmpty) })
  @IsMomentFormat(AddonTransferRoundtrip.FORMAT_DAY, { message: i18next.t(Key.inboundDateIsEmpty) })
  inboundDate: string;

  @IsNotEmpty({ message: i18next.t(Key.inboundTimeIsEmpty) })
  @IsMomentFormat(AddonTransferRoundtrip.FORMAT_TIME, {
    message: i18next.t(Key.inboundDateIsEmpty)
  })
  inboundTime: string;

  @IsNotEmpty({ message: i18next.t(Key.outboundNrIsEmpty) })
  outboundNr: string;

  @IsNotEmpty({ message: i18next.t(Key.outboundDateIsEmpty) })
  @IsMomentFormat(AddonTransferRoundtrip.FORMAT_DAY, {
    message: i18next.t(Key.outboundDateIsEmpty)
  })
  outboundDate: string;

  @IsNotEmpty({ message: i18next.t(Key.outboundTimeIsEmpty) })
  @IsMomentFormat(AddonTransferRoundtrip.FORMAT_TIME, {
    message: i18next.t(Key.outboundTimeIsEmpty)
  })
  outboundTime: string;
}

const defaults = new AddonTransferRoundtripFormModel();
defaults.inboundNr = '';
defaults.inboundDate = '';
defaults.inboundTime = '';
defaults.outboundNr = '';
defaults.outboundDate = '';
defaults.outboundTime = '';

const InnerForm = withFormik<AddonTransferRoundtripFormModel>(
  AddonTransferRoundtripFormModel,
  defaults
)((props: any) => {
  const {
    errors,
    item: { travelStart, travelEnd },
    styles
  } = props;
  const css = conditionalStyles(styles);

  const inboundDates = [];
  const outboundDates = [];

  if (travelStart) {
    const inboundDate = moment(travelStart, AddonTransferOnewayOutbound.FORMAT_DAY).utc(true);
    const inboundDateBefore = inboundDate.clone().subtract(1, 'day');
    inboundDates.push({
      value: formatted(inboundDateBefore),
      label: formatted(inboundDateBefore)
    });
    inboundDates.push({
      value: formatted(inboundDate),
      label: formatted(inboundDate)
    });
  }

  if (travelEnd) {
    const outboundDate = moment(travelEnd, AddonTransferOnewayOutbound.FORMAT_DAY).utc(true);
    const outboundDateAfter = outboundDate.clone().add(1, 'day');
    outboundDates.push({
      value: formatted(outboundDate),
      label: formatted(outboundDate)
    });
    outboundDates.push({
      value: formatted(outboundDateAfter),
      label: formatted(outboundDateAfter)
    });
  }

  function formatted(m: moment.Moment) {
    return m.format(AddonTransferOnewayOutbound.FORMAT_DAY);
  }

  return (
    <Form>
      <div className={join(styles.row, styles.row__withBorder)}>
        <strong>{i18next.t(Key.inbound)}</strong>
      </div>
      <div className={join(styles.row, styles.row__withChildren)}>
        <div className={styles.direction}>
          <Label>{i18next.t(Key.flightNo)}*</Label>
          <Field
            name={'inboundNr'}
            placeholder={i18next.t(Key.flightNoPlaceholder)}
            className={css('form-control', {
              'is-invalid': props.showValidation && errors['inboundNr']
            })}
          />
        </div>
        <div className={styles.timestamp}>
          <Label>Datum*</Label>
          <DateSelect
            values={inboundDates}
            label={i18next.t(Key.date)}
            name={'inboundDate'}
            isErrorDisplayed={false}
            className={css('form-control', {
              'is-invalid': props.showValidation && errors['inboundDate']
            })}
          />
        </div>
        <div className={styles.timestamp}>
          <Label>{i18next.t(Key.time)}*</Label>
          <TimeSelect
            label={i18next.t(Key.time)}
            name={'inboundTime'}
            isErrorDisplayed={false}
            className={css('form-control', {
              'is-invalid': props.showValidation && errors['inboundTime']
            })}
          />
        </div>
      </div>
      <div className={join(styles.row, styles.row__withBorder)}>
        <strong>{i18next.t(Key.outbound)}</strong>
      </div>
      <div className={join(styles.row, styles.row__withChildren)}>
        <div className={styles.direction}>
          <Label>{i18next.t(Key.flightNo)}*</Label>
          <Field
            name={'outboundNr'}
            placeholder={i18next.t(Key.flightNoPlaceholder)}
            className={css('form-control', {
              'is-invalid': props.showValidation && errors['outboundNr']
            })}
          />
        </div>
        <div className={styles.timestamp}>
          <Label>{i18next.t(Key.date)}*</Label>
          <DateSelect
            values={outboundDates}
            label={i18next.t(Key.date)}
            name={'outboundDate'}
            isErrorDisplayed={false}
            className={css('form-control', {
              'is-invalid': props.showValidation && errors['outboundDate']
            })}
          />
        </div>
        <div className={styles.timestamp}>
          <Label>{i18next.t(Key.time)}*</Label>
          <TimeSelect
            label={i18next.t(Key.time)}
            name={'outboundTime'}
            isErrorDisplayed={false}
            className={css('form-control', {
              'is-invalid': props.showValidation && errors['outboundTime']
            })}
          />
        </div>
      </div>
    </Form>
  );
});

const AddonTransferRoundtripForm: React.FunctionComponent<
  FormConfiguration<AddonTransferRoundtripFormModel>
> = (p: any & ThemeProps) => {
  const styles = p.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;

  return (
    <div>
      <FormCollapsible item={p.item} isOpen={p.isSelected}>
        <InnerForm {...p} styles={styles} />
      </FormCollapsible>
    </div>
  );
};
export default withTheme(AddonTransferRoundtripForm);
