import { TS_ItemInterface } from './TS_ItemInterface';

export class TS_Item implements TS_ItemInterface {
    public id : any;
    public title : string;
    public description : string;
    public totalPrice : number;
    public countSelectable : number;
    public count : number;
    public type : string;
    public groupID : string;
    public mandatory : boolean;
    public selectType : string;
    public infoURL : string;
    public isVisible : boolean;
    public isBookable : boolean;
}