import { ValidateNested } from 'class-validator';
import { Type }           from 'class-transformer';

import { BusinessPartner } from '../../Participants/Models/BusinessPartner';
import { Passenger } from '../../Participants/Models/Passenger';

export class Meta {

    @ValidateNested()
    @Type( () => BusinessPartner )
    businessPartner : BusinessPartner;

    @ValidateNested()
    @Type( () => Passenger )
    passengers : Passenger[];
}