import React from 'react';
import {inject, observer} from 'mobx-react';

import {ErrorHandler} from 'State/ErrorHandler';
import ErrorDisplay from 'Components/Layouts/Parts/ErrorDisplay';

interface Props {
  errorHandler?: ErrorHandler;
}

@inject('errorHandler')
@observer
export default class ErrorBoundary extends React.Component<Props, {}> {
    
    componentDidCatch( e : Error ) {
        this.props.errorHandler!.catchError( e );
    }
    
    render() {
        if ( this.props.errorHandler!.error ) {
            return <ErrorDisplay error={ this.props.errorHandler!.error }/>;
        }
        
        return this.props.children;
    }
}
