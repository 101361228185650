import { observer }         from 'mobx-react';
import React, { useEffect } from 'react';

import errorHandler, { RouteNotFoundError } from 'State/ErrorHandler';
import workflow                             from 'State/WorkflowService';

import styles from './Workflow.module.scss';

export const Workflow = observer( () => {
    const step     = workflow.currentStep!;
    const steps    = workflow.getWorkflowSteps();
    const hasSteps = step && steps.hasOwnProperty( step );

    useEffect( () => {
        if ( !hasSteps ) {
            errorHandler.catchError( new RouteNotFoundError() );
        }
    } );

    if ( !hasSteps ) {
        return <></>;
    }

    return <div>
        <div className={ styles.content }>
            { steps[ step ] }
        </div>
    </div>;

} );
