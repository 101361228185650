import React                from 'react';
import { computed }         from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './CartImage.module.scss';

import { Hotel }           from 'Models/UI/Hotel';
import { WorkflowService } from 'State/WorkflowService';
import { Flight } from '../../Models/UI/Flight';

interface Props {
    workflow? : WorkflowService
}

@inject( 'workflow' )
@observer
export default class CartImage extends React.Component<Props, {}> {

    render() {
        if ( this.hotel && !this.props.workflow!.isFlightWorkflow ) {
            return <div className={ styles.image }>
                <div style={ { backgroundImage : `url(${ this.hotel.imageURL })` } }></div>
            </div>
        }
        //workaround for TS they deliver the image in hotel item -.-
        if( this.hotel && this.props.workflow!.isFlightWorkflow) {
            return <div className={ styles.image }>
                <div style={ { backgroundImage : `url(${ this.hotel.imageURL })` } }></div>
            </div>
        }
        return '';
    }

    @computed
    get hotel() : Hotel | null {
        const items = this.props.workflow!.selectedItems;
        try {
            return items.filter( i => i instanceof Hotel )[ 0 ] as Hotel;
        } catch ( e ) {
            return new Hotel();
        }
    }

    @computed
    get flight() : Flight | null {
        const items = this.props.workflow!.selectedItems;
        try {
            return items.filter( i => i instanceof Flight )[ 0 ] as Flight;
        } catch ( e ) {
            return new Flight();
        }
    }
}