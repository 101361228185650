import { IsNumber } from 'class-validator';

export class PaymentInfo {
    
    @IsNumber()
    deposit : number;
    
    @IsNumber()
    depositPercentage : number;
    
    paymentTexts : string[];
    
    //paymenttypes : PaymentType[];
    // no longer used here - service layer will return them in items[]
}