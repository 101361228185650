import React, { useMemo, useRef, useState } from 'react';
import { Banner as BannerClass } from '../../../../Models/UI/Banner';

import pepxpressStyles from '../Upsell-pepxpress.module.scss';
import pepxciteStyles from '../Upsell-pepxcite.module.scss';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import { ThemeProps } from '../../../../Context/AppContext';
import { withTheme } from '../../../../Context/withTheme';

export const SingleBanner = (props: { banner: BannerClass } & ThemeProps) => {
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const {
    banner: { infoURL, imageURL, teaser, descriptionList, title, dateFrom, dateUntil }
  } = props;

  return (
    <a
      href={infoURL}
      className={styles.banner}
      target={'_blank'}
      rel="noopener noreferrer"
      //style={{ backgroundImage: `url(${imageURL})` }}
    >
      <img src={imageURL} alt={title} className={styles.bannerImg}/>
      <div className={styles.bannerInner}>
        <div className={styles.bannerContent}>
          <div className={styles.bannerList}>
            <div className={styles.bannerHeadline}>{teaser}</div>
            <ul>
              {descriptionList &&
                descriptionList.length > 0 &&
                descriptionList.filter(item => !!item).map(item => <li key={item}>{item}</li>)}
            </ul>
          </div>
          <div className={styles.bannerDate}>
            <div>{title}</div>
            <div>
              {dateFrom}&nbsp;-&nbsp;{dateUntil}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const Banner = (props: { banners: BannerClass[] } & ThemeProps) => {
  const { banners, theme } = props;
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const animating = useRef<boolean>(false);

  const onExiting = () => {
    animating.current = true;
  };

  const onExited = () => {
    animating.current = false;
  };

  const slides = useMemo(() => {
    return banners.map(banner => {
      return (
        <CarouselItem key={banner.id} onExiting={onExiting} onExited={onExited}>
          <SingleBanner banner={banner} theme={theme} />
        </CarouselItem>
      );
    });
  }, [banners, theme]);

  const next = () => {
    if (animating.current) return;
    setActiveIndex(value => (value === banners.length - 1 ? 0 : value + 1));
  };

  const previous = () => {
    if (animating.current) return;
    setActiveIndex(value => (value === 0 ? banners.length - 1 : value - 1));
  };

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      ride="carousel"
      interval={10000}
      pause="hover"
    >
      {slides}
      {banners.length > 1 && (
        <>
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </>
      )}
    </Carousel>
  );
};
export default withTheme(Banner);
