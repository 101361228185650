import { registerDecorator, ValidationOptions } from "class-validator";
import { isValidMomentFormat }                  from 'Helpers/moment';
import moment                                   from 'moment';

export function IsMomentFormat( format : string, validationOptions? : ValidationOptions ) {
    return function ( object : Object, propertyName : string ) {
        registerDecorator( {
            name         : "isMomentFormat",
            target       : object.constructor,
            propertyName : propertyName,
            constraints  : [],
            options      : validationOptions,
            validator    : {
                validate( value : moment.Moment | any ) {
                    return isValidMomentFormat( value, format );
                }
            }
        } );
    };
}