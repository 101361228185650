import { ClassType } from 'class-transformer/ClassTransformer';
import sortBy from 'lodash-es/sortBy';

import { TS_BookInfo } from 'Models/TravelSeller/Models/TS_BookInfo';
import { AddonTrain } from 'Models/UI/AddonTrain';
import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import { AddonTransferOnewayInbound } from 'Models/UI/AddonTransferOnewayInbound';
import { AddonTransferRoundtrip } from 'Models/UI/AddonTransferRoundtrip';
import { Mapping } from 'Helpers/Mapping';
import { toValidFormatOrNothing } from 'Helpers/moment';
import { getInstance, getValidatedInstance } from 'Helpers/validate';
import mapAddonTransferOnewayOutbound from 'Models/Converter/AddonTransferOnewayOutbound/mapTsToUi';
import mapAddonTransferOnewayInbound from 'Models/Converter/AddonTransferOnewayInbound/mapTsToUi';
import mapAddonTransferRoundtrip from 'Models/Converter/AddonTransferRoundtrip/mapTsToUi';
import { TS_AddonTransferOnewayOutbound } from 'Models/TravelSeller/Models/TS_AddonTransferOnewayOutbound';
import { TS_AddonTransferOnewayInbound } from 'Models/TravelSeller/Models/TS_AddonTransferOnewayInbound';
import { TS_AddonTransferRoundtrip } from 'Models/TravelSeller/Models/TS_AddonTransferRoundtrip';
import { TS_ItemInterface } from 'Models/TravelSeller/Models/TS_ItemInterface';
import { AddonEvent } from 'Models/UI/AddonEvent';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import { BookingItemGroup } from 'Models/UI/BookingItemGroup';
import { AddonCatering } from 'Models/UI/AddonCatering';
import { AddonTrip } from 'Models/UI/AddonTrip';
import { Response } from 'Models/UI/Response';
import { Booking } from 'Models/UI/Booking';
import { TS_Response } from 'Models/TravelSeller/API/TS_Response';
import { AddonWellness } from 'Models/UI/AddonWellness';
import { Hotel } from 'Models/UI/Hotel';
import { Insurance } from 'Models/UI/Insurance';
import { Charges } from 'Models/UI/Charges';
import { ItemIncluded } from 'Models/UI/ItemIncluded';
import { AddonVisa } from 'Models/UI/AddonVisa';
import { Room } from 'Models/UI/Room';
import { PaymentType } from 'Models/UI/PaymentType';
import { BonuscardOrder } from 'Models/UI/BonuscardOrder';
import { BonuscardSaving } from 'Models/UI/BonuscardSaving';
import { Donation } from 'Models/UI/Donation';
import { Coupon } from 'Models/UI/Coupon';
import { Note } from 'Models/UI/Note';
import { Deposit } from 'Models/UI/Deposit';
import { Special } from 'Models/UI/Special';
import { EarlyBird } from 'Models/UI/EarlyBird';
import { Flight } from '../UI/Flight';
import { OfferHotel } from '../UI/OfferHotel';
import { OfferFlight } from '../UI/OfferFlight';
import { OfferEvent } from '../UI/OfferEvent';
import { OfferMoreHotel } from '../UI/OfferMoreHotel';
import { OfferMoreFlight } from '../UI/OfferMoreFlight';
import { OfferMoreEvent } from '../UI/OfferMoreEvent';
import { Offer } from '../UI/Offer';
import { AddonMisc } from '../UI/AddonMisc';
import { ExtraNights } from '../UI/ExtraNights';
import { TC } from '../UI/TC';
import { AddonFlexPep } from '../UI/AddonFlexPep';
import { AddonBus } from '../UI/AddonBus';
import { AddonDinner } from '../UI/AddonDinner';
import { OfferBanner } from '../UI/OfferBanner';

export async function TravelSellerToUiConverter(input: TS_Response): Promise<Response> {
  const mapping = new Mapping<TS_Response, Response>();
  return mapping.of(TS_Response, input).to(Response).withValidation(converter);
}

async function converter(input: TS_Response): Promise<Response> {
  const output: Response = new Response();

  output.for = input.for;
  output.workflow = input.workflow;
  output.meta = input.meta || null;
  output._ts_api_calls = input._ts_api_calls;

  if (output.meta && output.meta.hasOwnProperty('countries')) {
    output.meta.countries = output.meta.countries.map(transformCountry);
  }
  if (output.meta && output.meta.hasOwnProperty('trainConnections')) {
    output.meta.trainConnections = output.meta.trainConnections.map(transformTrainConnection);
  }

  output.booking = new Booking();
  output.booking.bookFailedMessageHtml = input.booking.bookFailedMessageHtml;
  output.booking.jumpToStep = input.booking.jumpToStep;
  output.booking.isValid = input.booking.bookInfo.isValid;
  output.booking.workflow = input.booking.workflow;
  output.booking.totalPrice = input.booking.bookInfo.priceTotal;
  output.booking.bookingNumber = input.booking.bookingNumber;
  output.booking.isOk = input.booking.bookingStatus
    ? input.booking.bookingStatus === 'booked'
    : null;
  output.booking.bookingOnRequest = input.booking.bookingOnRequest;
  output.booking.destination = input.booking.bookInfo.destination;
  output.booking.ritDestination = input.booking.bookInfo.ritDestination;
  output.booking.ritOrigin = input.booking.bookInfo.ritOrigin;
  output.booking.ritMode = input.booking.bookInfo.ritMode;
  output.booking.language = input.booking.bookInfo.language;
  output.booking.travelStart = toValidFormatOrNothing(
    input.booking.bookInfo.checkin,
    TS_BookInfo.FORMAT_DATE,
    Booking.FORMAT_DATE
  );
  output.booking.travelEnd = toValidFormatOrNothing(
    input.booking.bookInfo.checkout,
    TS_BookInfo.FORMAT_DATE,
    Booking.FORMAT_DATE
  );
  output.booking.needPaxDateOfBirth = input.booking.needPaxDateOfBirth;
  output.booking.needPaxNationality = input.booking.needPaxNationality;

  output.booking.items = await mapItems(input.booking.items, output.booking);

  output.booking.termsAndConditionList = input.booking.termsAndConditionList.map(item =>
    getInstance(TC, item)
  );

  const items = await mapItems(input.items, output.booking);
  output.items = mapGroups(items);

  return output;
}

async function mapItems(
  items: BookingItemInterface[],
  booking: Booking
): Promise<BookingItemInterface[]> {
  const filtered = items.filter(onlyVisibleItems).map(consistentApiPlease);

  let mapped = [
    ...(await createInstancesOf<Room>(Room, filtered)),
    ...(await createInstancesOf<AddonCatering>(AddonCatering, filtered)),
    ...(await createInstancesOf<TS_AddonTransferRoundtrip>(TS_AddonTransferRoundtrip, filtered)),
    ...(await createInstancesOf<TS_AddonTransferOnewayOutbound>(
      TS_AddonTransferOnewayOutbound,
      filtered
    )),
    ...(await createInstancesOf<TS_AddonTransferOnewayInbound>(
      TS_AddonTransferOnewayInbound,
      filtered
    )),
    ...(await createInstancesOf<AddonDinner>(AddonDinner, filtered)),
    ...(await createInstancesOf<AddonTrip>(AddonTrip, filtered)),
    ...(await createInstancesOf<AddonWellness>(AddonWellness, filtered)),
    ...(await createInstancesOf<AddonVisa>(AddonVisa, filtered)),
    ...(await createInstancesOf<AddonBus>(AddonBus, filtered)),
    ...(await createInstancesOf<Hotel>(Hotel, filtered)),
    ...(await createInstancesOf<Flight>(Flight, filtered)),
    ...(await createInstancesOf<Insurance>(Insurance, filtered)),
    ...(await createInstancesOf<Charges>(Charges, filtered)),
    ...(await createInstancesOf<ItemIncluded>(ItemIncluded, filtered)),
    ...(await createInstancesOf<PaymentType>(PaymentType, filtered)),
    ...(await createInstancesOf<Deposit>(Deposit, filtered)),
    ...(await createInstancesOf<BonuscardOrder>(BonuscardOrder, filtered)),
    ...(await createInstancesOf<BonuscardSaving>(BonuscardSaving, filtered)),
    ...(await createInstancesOf<Donation>(Donation, filtered)),
    ...(await createInstancesOf<Coupon>(Coupon, filtered)),
    ...(await createInstancesOf<Note>(Note, filtered)),
    ...(await createInstancesOf<Special>(Special, filtered)),
    ...(await createInstancesOf<AddonEvent>(AddonEvent, filtered)),
    ...(await createInstancesOf<AddonMisc>(AddonMisc, filtered)),
    ...(await createInstancesOf<EarlyBird>(EarlyBird, filtered)),
    ...(await createInstancesOf<AddonTrain>(AddonTrain, filtered)),
    ...(await createInstancesOf<OfferHotel>(OfferHotel, filtered)),
    ...(await createInstancesOf<OfferFlight>(OfferFlight, filtered)),
    ...(await createInstancesOf<OfferEvent>(OfferEvent, filtered)),
    ...(await createInstancesOf<OfferBanner>(OfferBanner, filtered)),
    ...(await createInstancesOf<OfferMoreHotel>(OfferMoreHotel, filtered)),
    ...(await createInstancesOf<OfferMoreFlight>(OfferMoreFlight, filtered)),
    ...(await createInstancesOf<OfferMoreEvent>(OfferMoreEvent, filtered)),
    ...(await createInstancesOf<Offer>(Offer, filtered)),
    ...(await createInstancesOf<ExtraNights>(ExtraNights, filtered)),
    ...(await createInstancesOf<AddonFlexPep>(AddonFlexPep, filtered))
  ];

  mapped = await Promise.all(
    mapped.map(async a => {
      if (a instanceof TS_AddonTransferRoundtrip) {
        const aa = await mapAddonTransferRoundtrip(a);
        return addTravelStartToTransfer(aa, booking);
      } else if (a instanceof TS_AddonTransferOnewayOutbound) {
        const aa = await mapAddonTransferOnewayOutbound(a);
        return addTravelStartToTransfer(aa, booking);
      } else if (a instanceof TS_AddonTransferOnewayInbound) {
        const aa = await mapAddonTransferOnewayInbound(a);
        return addTravelStartToTransfer(aa, booking);
      }
      return a;
    })
  );

  mapped = sortBy(mapped, ['type', 'title']);

  return mapped;
}

async function createInstancesOf<T extends BookingItemInterface>(
  cls: ClassType<T>,
  items: BookingItemInterface[]
): Promise<T[]> {
  const type: string = new cls().type;
  return Promise.all(
    items
      .filter(i => i.type === type)
      .map(
        async (i): Promise<T> => {
          return getValidatedInstance<T, {}>(cls, i);
        }
      )
  );
}

function mapGroups(items: BookingItemInterface[]): BookingItemGroup[] {
  return groupIdsFromItems(items).map(gid => {
    const g = new BookingItemGroup();
    g.id = gid;
    g.items = items.filter(i => i.groupID === gid);
    return g;
  });
}

function groupIdsFromItems(items: BookingItemInterface[]): string[] {
  return Array.from(
    new Set( // == get only unique groupIDs
      items.map(i => {
        return i.groupID;
      })
    )
  );
}

function consistentApiPlease(item: TS_ItemInterface): BookingItemInterface {
  item.id = item.id || item.id === 0 ? item.id.toString() : null;
  item.type = item.type ? item.type.toString() : '';
  item.count = item.count ? parseInt(item.count.toString()) : 0;

  if (item.type === '102') {
    item.groupID = 'api-u-no-give-id-for-insurance-group';
  }

  if (item.type === '8') {
    // TODO - wrong type from travelseller - should be "8" == TS_ItemType.TRAIN
    item.groupID = 'api-u-no-give-id-for-train-group';
  }

  if (item.type === '107') {
    item.groupID = 'api-u-no-give-id-for-payment-group';
  }

  if (
    item.type === '26' ||
    item.type === '27' ||
    item.type === '28' ||
    item.type === '29' ||
    item.type === '30' ||
    item.type === '31' ||
    item.type === '33'
  ) {
    // TODO - wrong type from travelseller - should be "8" == TS_ItemType.TRAIN
    item.groupID = 'api-u-no-give-id-for-offer-group';
  }

  return item;
}

function onlyVisibleItems(item: TS_ItemInterface): boolean {
  if (item.hasOwnProperty('isVisible') && item.isVisible !== null) {
    return item.isVisible;
  } else {
    return true;
  }
}

function transformCountry(c: any) {
  return {
    value: c.code || null,
    label: c.title || null
  };
}

function transformTrainConnection(c: any) {
  return {
    value: c.value || null,
    label: c.label || null
  };
}

function addTravelStartToTransfer(
  t: AddonTransferRoundtrip | AddonTransferOnewayOutbound | AddonTransferOnewayInbound,
  b: Booking
) {
  t.travelStart = b.travelStart
    ? toValidFormatOrNothing(
        b.travelStart,
        Booking.FORMAT_DATE,
        AddonTransferOnewayOutbound.FORMAT_DAY
      )
    : null;
  t.travelEnd = b.travelEnd
    ? toValidFormatOrNothing(
        b.travelEnd,
        Booking.FORMAT_DATE,
        AddonTransferOnewayOutbound.FORMAT_DAY
      )
    : null;
  return t;
}
