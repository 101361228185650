import React from 'react';
import { FaInfoCircle } from 'react-icons/all';

interface Props {
  url: string;
  styles: { [key: string]: string };
}

export const Info = ({ url, styles }: Props) => {
  return (
    <a className={styles.insuranceInfo} href={url} target={'_blank'}>
      <FaInfoCircle />
    </a>
  );
};
