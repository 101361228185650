import { classToClass } from 'class-transformer';

import { Mapping } from 'Helpers/Mapping';
import { toValidFormatOrNothing } from 'Helpers/moment';

import { AddonTransferOnewayOutbound as OnewayUi } from 'Models/UI/AddonTransferOnewayOutbound';

import { TS_AddonTransferOnewayOutbound as OnewayTs } from 'Models/TravelSeller/Models/TS_AddonTransferOnewayOutbound';

export default async function (input: OnewayUi) {
  return new Mapping<OnewayUi, OnewayTs>()
    .of(OnewayUi, input)
    .to(OnewayTs)
    .withValidation(async (input: OnewayUi) => ({
      ...classToClass<OnewayUi>(input),
      addonInfo: {
        addonInfoTransfer: {
          flightNr: input.inboundNr,
          flightTime: toTsFormat(input.inboundDate + ' ' + input.inboundTime)
        }
      },
      flightNr: input.inboundNr,
      flightTime: toTsFormat(input.inboundDate + ' ' + input.inboundTime)
    }));
}

function toTsFormat(str: string): string {
  const wtf = toValidFormatOrNothing(
    str,
    OnewayUi.FORMAT_DAY + ' ' + OnewayUi.FORMAT_TIME,
    OnewayTs.FORMAT
  );
  console.log('OnewayTs.FORMAT', OnewayTs.FORMAT);
  console.log('OnewayUi.FORMAT_DAY', OnewayUi.FORMAT_DAY);
  console.log('wtf', wtf);
  return wtf || str;
}
