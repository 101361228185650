import React, { ReactNode } from "react";

import Price from "Components/Workflow/Price";
import { AddonTrain } from "Models/UI/AddonTrain";
import { conditionalStyles, join } from "Helpers/conditionalStyles";

import styles from "../Train.module.scss";

const css = conditionalStyles(styles);

interface Props {
  children?: ReactNode;
  item: AddonTrain;
  selectedItem: AddonTrain | null;
  isSelected: boolean;
  label: string | null;
  toggle: (item: AddonTrain) => void;
}

export const Box = (props: Props) => {
  const { children, item, toggle, label, isSelected } = props;

  const hasPrice =
    !!props.selectedItem &&
    props.selectedItem.id === item.id &&
    props.selectedItem.totalPrice &&
    props.selectedItem.totalPrice > 0;
  const classNames = css("train", "train--fullWidth", {
    "train--isSelected": isSelected
  });

  const trainInputClassNames = css("trainInput", {
    "trainInput--isSelected": isSelected
  });

  return (
    <div
      className={classNames}
      tabIndex={0}
      onKeyPress={e => e.key === "Enter" && toggle(item)}
    >
      {children}

      {label && (
        <div className={join(trainInputClassNames)}>
          <input
            type={"radio"}
            id={item.id}
            onChange={() => toggle(item)}
            checked={isSelected}
          />
          <label htmlFor={item.id} className={styles.trainLabel}>
            <div>
              <span>{label}</span>
              {!!hasPrice && (
                <Price amount={props.selectedItem!!.totalPrice} prefix={"+ "} />
              )}
            </div>
          </label>
        </div>
      )}
    </div>
  );
};
