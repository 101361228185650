import { DateSelect } from 'Components/Forms/DateSelect';
import { TimeSelect } from 'Components/Forms/TimeSelect';
import i18next from 'i18next';
import React from 'react';
import { Label } from 'reactstrap';
import moment from 'moment';
import { IsNotEmpty } from 'class-validator';
import { Field, Form } from 'formik';
import { IsMomentFormat } from 'Helpers/Validators/IsMomentFormat';
import { conditionalStyles, join } from 'Helpers/conditionalStyles';
import { Keys } from 'Translation/translations.de.json.keys';
import pepxpressStyles from './Component-pepxpress.module.scss';
import pepxciteStyles from './Component-pepxcite.module.scss';
import FormCollapsible from '../FormCollapsible';
import FormConfiguration from '../FormConfiguration';
import withFormik from '../withFormik';
import { withTheme } from '../../../../Context/withTheme';
import { ThemeProps } from '../../../../Context/AppContext';
import { AddonTransferOnewayInbound } from '../../../../Models/UI/AddonTransferOnewayInbound';

const Key = Keys.WORKFLOW.AdditionalServices.AddonTransfer;

export class AddonTransferOnewayInboundFormModel extends AddonTransferOnewayInbound {
  @IsNotEmpty({ message: i18next.t(Key.outboundNrIsEmpty) })
  inboundNr: string;

  @IsNotEmpty({ message: i18next.t(Key.outboundDateIsEmpty) })
  @IsMomentFormat(AddonTransferOnewayInbound.FORMAT_DAY, {
    message: i18next.t(Key.outboundDateIsEmpty)
  })
  inboundDate: string;

  @IsNotEmpty({ message: i18next.t(Key.outboundTimeIsEmpty) })
  @IsMomentFormat(AddonTransferOnewayInbound.FORMAT_TIME, {
    message: i18next.t(Key.outboundTimeIsEmpty)
  })
  inboundTime: string;
}

const defaults = new AddonTransferOnewayInbound();

const InnerForm = withFormik<AddonTransferOnewayInboundFormModel>(
  AddonTransferOnewayInboundFormModel,
  defaults
)((props: any) => {
  const { errors, item, styles } = props;
  const css = conditionalStyles(styles);
  const inboundDates = [];

  if (item.travelEnd) {
    const inboundDate = moment(item.travelEnd, AddonTransferOnewayInbound.FORMAT_DAY).utc(true);
    const inboundDateAfter = inboundDate.clone().add(1, 'day');
    inboundDates.push({
      value: formatDate(inboundDate),
      label: formatDate(inboundDate)
    });
    inboundDates.push({
      value: formatDate(inboundDateAfter),
      label: formatDate(inboundDateAfter)
    });
  }

  if (item.addonInfo && item.addonInfo.addonInfoTransfer) {
    const flightInfo = item.addonInfo.addonInfoTransfer;
    defaults.inboundNr = flightInfo.flightNrReturn;
    defaults.inboundDate = '';
    defaults.inboundTime = '';
    /* PEP-250 
    defaults.inboundDate = formatDate(
      moment(flightInfo.flightTimeReturn, AddonTransferOnewayInbound.FORMAT_ADDON_INFO).utc(true)
    );
    defaults.inboundTime = formatTime(
      moment(flightInfo.flightTimeReturn, AddonTransferOnewayInbound.FORMAT_ADDON_INFO).utc(true)
    ); */
  }

  function formatDate(m: moment.Moment) {
    return m.format(AddonTransferOnewayInbound.FORMAT_DAY);
  }

  /*
  function formatTime(m: moment.Moment) {
    return m.format(AddonTransferOnewayInbound.FORMAT_TIME);
  }*/

  return (
    <Form>
      <div>
        <div className={join(styles.row, styles.row__withBorder)}>
          <strong>{i18next.t(Key.outbound)}</strong>
        </div>
        <div className={join(styles.row, styles.row__withChildren)}>
          <div className={styles.direction}>
            <Label>{i18next.t(Key.flightNo)}*</Label>
            <Field
              autoFocus
              name={'inboundNr'}
              placeholder={i18next.t(Key.flightNoPlaceholder)}
              className={css('form-control', {
                'is-invalid': props.showValidation && errors['inboundNr']
              })}
            />
          </div>
          <div className={styles.timestamp}>
            <Label>{i18next.t(Key.date)}*</Label>
            <DateSelect
              values={inboundDates}
              label={i18next.t(Key.date)}
              name={'inboundDate'}
              isErrorDisplayed={false}
              className={css('form-control', {
                'is-invalid': props.showValidation && errors['inboundDate']
              })}
            />
          </div>
          <div className={styles.timestamp}>
            <Label>{i18next.t(Key.time)}*</Label>
            <TimeSelect
              label={i18next.t(Key.time)}
              name={'inboundTime'}
              isErrorDisplayed={false}
              className={css('form-control', {
                'is-invalid': props.showValidation && errors['inboundTime']
              })}
            />
          </div>
        </div>
      </div>
    </Form>
  );
});

const AddonTransferOnewayForm: React.FunctionComponent<
  FormConfiguration<AddonTransferOnewayInboundFormModel>
> = (p: any & ThemeProps) => {
  const styles = p.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;

  return (
    <div>
      <FormCollapsible item={p.item} isOpen={p.isSelected}>
        <InnerForm {...p} styles={styles} />
      </FormCollapsible>
    </div>
  );
};
export default withTheme(AddonTransferOnewayForm);
