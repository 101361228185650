import { createContext } from 'react';

export const themes = ['pepxpress', 'pepxcite'] as const;
export type Theme = typeof themes[number];

export const AppContext = createContext<Theme>('pepxpress');

export interface ThemeProps {
  theme: Theme;
}
