import i18next from 'i18next';
import React from 'react';
import { action, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import workflow, { WorkflowService } from 'State/WorkflowService';
import { ErrorHandler } from 'State/ErrorHandler';
import { AppService } from 'State/AppService';
import { conditionalStyles } from 'Helpers/conditionalStyles';
import { getInstance } from 'Helpers/validate';
import DefaultLayout from 'Components/Workflow/DefaultLayout';
import ContactData from 'Components/Workflow/ContactData';
import Headline from 'Components/Workflow/Headline';
import Cart from 'Components/Workflow/Cart';
import CartIncludedItems from 'Components/Workflow/CartIncludedItems';
import ExtraNights from 'Components/Workflow/ExtraNights';
import { Keys } from 'Translation/Setup';

import { Meta } from './Models/Meta';
import { BusinessPartner } from '../Participants/Models/BusinessPartner';

import pepxpressStyles from './Summary-pepxpress.module.scss';
import pepxciteStyles from './Summary-pepxcite.module.scss';
import Nav from 'Components/Workflow/Nav';
import { Button } from 'reactstrap';
import { ParticipantsData } from '../../../Components/Workflow/ParticipantsData';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {LanguageContext} from "../../../Helpers/useLanguage";
import {ItemType} from "../../../Models/UI/ItemType";

const Key = Keys.WORKFLOW.Summary;

interface Props {
  workflow?: WorkflowService;
  errorHandler?: ErrorHandler;
  app?: AppService;
}

interface State {}

@inject('errorHandler')
@inject('app')
@inject('workflow')
@observer
class Summary extends React.Component<Props & ThemeProps, State> {
  @observable meta: Meta = getInitialMeta();

  static contextType = LanguageContext
  context!: React.ContextType<typeof LanguageContext>

  componentDidMount() {
    const app = this.props.app!;
    const workflow = this.props.workflow!;
    const errorHandler = this.props.errorHandler!;
    const currentStep = workflow.currentStep!;
    const booking = workflow.booking!;

    app.trackTimeLoadStarted(currentStep);
      const couponItem = toJS(workflow.booking)?.items.find(item => item.type === ItemType.COUPON)
      const coupon = couponItem?.title;
    const coupon_value = couponItem?.totalPrice
    workflow
      .loadStep()
      .then(({language}) => {
        const globalLanguage = this.context || language;
        if (!!globalLanguage) {
          i18next.changeLanguage(globalLanguage);
        }
        this.parseMeta.bind(this)();
      })
      .then(() => app.deactivateLoader())
      .then(() => app.tracking.sendPurchaseEvent(booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes, workflow.paymentItem.title, coupon, coupon_value))
      .then(() => window.scrollTo(0, 0))
      .catch(e => errorHandler.catchError(e));
  }

  async parseMeta() {
    const meta = await getInstance<Meta, {}>(Meta, toJS(this.props.workflow!.currentMeta));
    action(() => {
      this.meta = meta;
    })();
  }

  next() {
    workflow.navigateToNextStep(true);
  }

  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    const cs = conditionalStyles(styles);
    const isOk = this.props.workflow!.booking!.isOk;
    const bookingNr = this.props.workflow!.booking!.bookingNumber;

    const right = <Cart bookingOnRequest={workflow.bookingOnRequest} />;

    const bottom = (
      <div>
        <Nav
          leftButton={
            <Button
              outline
              color="primary"
              onClick={() => (window.location.href = this.props.theme === 'pepxcite'
                ? 'https://www.pepxcite.com/de/alle-reisen' : 'https://www.pepxpress.com/de/alle-reisen')}
            >
              {i18next.t(Key.backToHomepage)}
            </Button>
          }
          // onCloseWindow={() => window.top.close()}
          onNext={this.props.theme !== 'pepxcite' ? () => this.next() : undefined}
        />
      </div>
    );

    const top = (
      <div>
        <div className={styles.section}>
          <div className={'d-flex justify-content-between align-items-baseline'}>
            <Headline text={i18next.t(Key.headline)} />
            <Button outline color="primary" onClick={() => window.top.close()}>
              {i18next.t(Key.close)}
            </Button>
          </div>
        </div>
      </div>
    );

    const left = (
      <div>
        <div className={styles.section}>
          <div
            className={cs('status', {
              'status--green': isOk,
              'status--orange': !isOk
            })}
          >
            <div>
              <span>
                {i18next.t(Key.bookingNo)}: <strong>{bookingNr}</strong>,
              </span>{' '}
              <span>
                {i18next.t(Key.bookingStatus)}:{' '}
                <strong>{i18next.t(isOk ? Key.labelOk : Key.labelOption)}</strong>
              </span>
            </div>
            {isOk && <div className={styles.colored}>{i18next.t(Key.textOk)}</div>}
            {!isOk && <div className={styles.colored}>{i18next.t(Key.textOption)}</div>}
          </div>
        </div>

        <div className={styles.section}>
          <ContactData person={this.meta.businessPartner} />
        </div>

        <div className={styles.section}>
          <h5>{i18next.t(Key.Participants)}</h5>
          <ParticipantsData passengers={this.meta.passengers} />
        </div>

        {!workflow.isFlightWorkflow && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.includedItems)}</h5>
            <CartIncludedItems items={this.props.workflow!.selectedItems} />
          </div>
        )}

        {this.props.workflow!.hasExtraNights && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.extraNights)}</h5>
            <ExtraNights items={this.props.workflow!.selectedItems} styles={styles} />
          </div>
        )}
      </div>
    );

    return (
      <>
        <Breadcrumbs next={() => this.next()} />
        <DefaultLayout top={top} left={left} right={right} bottom={bottom} />
      </>
    );
  }
}
export default withTheme(Summary);

function getInitialMeta(): Meta {
  return {
    businessPartner: new BusinessPartner(),
    passengers: []
  };
}
