import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';
import SelectFieldOption from '../../Models/SelectFieldOption';
import { Keys } from 'Translation/Setup';
import styles from './DonationSelect.module.scss';
import { Donation } from '../../Models/UI/Donation';

interface Props {
    id: string
    label: string;
    donations: Donation[];
    selectedDonation: Donation | null;
    disabled: boolean;
    onChange: (value?: Donation) => void;
    onToggle: () => void;
}

export const DonationSelect = ( props: Props ) => {
    const { id, label, donations, selectedDonation, disabled, onChange, onToggle } = props;
    const { t } = useTranslation();
    const placeholder: string = t(Keys.COMPONENTS.DonationSelect.plsSelect);

    const donationOptions: SelectFieldOption[] = useMemo(() =>
        donations.map(d => {
            return {
                label: `${d.totalPrice}€`,
                value: d.totalPrice.toString()
            }
        }).sort((a, b) => Number(a.value) > Number(b.value) ? 1 : -1), [donations]
    );

    return <>
        <Label className={styles.label} onClick={onToggle} for={id}>{label}</Label>
        <Input
            type="select"
            id={id}
            value={selectedDonation ? selectedDonation.totalPrice : ''}
            onChange={e => onChange(donations.find(d => d.totalPrice.toString() === e.target.value))}
            placeholder={placeholder}
            disabled={disabled}>
                {donationOptions.map((d, index) => <option key={index} value={d.value}>{d.label}</option>)}
        </Input>
  </>
};
