import { plainToClass } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import sortBy from "lodash-es/sortBy";
import {
  action,
  computed,
  configure,
  observable,
  runInAction,
  toJS,
} from "mobx";
import { persist } from "mobx-persist";
import { PrepareCcPaymentRequest } from "Models/Requests/PrepareCcPaymentRequest";
import { PrepareCcPaymentResponse } from "Models/Requests/PrepareCcPaymentResponse";
import { Booking } from "Models/UI/Booking";
import { BookingItem } from "Models/UI/BookingItem";
import { BookingItemGroup } from "Models/UI/BookingItemGroup";
import { BookingItemInterface } from "Models/UI/BookingItemInterface";
import { ExtraNights as ExtraNightsItem } from "Models/UI/ExtraNights";
import { Item } from "Models/UI/Item";
import { Payload } from "Models/UI/Payload";
import { PayloadOverview } from "Models/UI/PayloadOverview";
import { PaymentType } from "Models/UI/PaymentType";
import { Response } from "Models/UI/Response";
import { appRouter, appRoutes } from "routes";
import { Keys } from "Translation/Setup";
import {
  EventWorkflowSteps, EventWorkflowStepsEN,
  FlightWorkflowSteps, FlightWorkflowStepsEN,
  HotelFlightWorkflowSteps, HotelFlightWorkflowStepsEN,
  HotelWorkflowSteps, HotelWorkflowStepsEN, trackingEventMapping,
} from "Workflow/WorkflowSteps";
import api from "../API";
import { TC } from "../Models/UI/TC";
import app from "./AppService";
import errorHandler, { DisplayableError } from "./ErrorHandler";

configure({
  enforceActions: "observed",
});

export enum PaymentStatus {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

export enum Workflow {
  HOTEL = "hotel",
  FLIGHT = "flight",
  HOTEL_FLIGHT = "hotelflight",
  EVENT = "nodateevent",
}

type StepType = 'forward' | 'backward';

export class WorkflowService {
  @persist
  @observable
  isPersisted: boolean = false;

  @persist("object")
  @observable
  booking: Booking | null;

  @persist("list")
  @observable
  steps: string[] = [];

  @persist("object")
  @observable
  excludedSteps: { [step: string]: any } = {};

  @persist("object")
  @observable
  stepCounter: { [step: string]: number } = {};

  @persist
  @observable
  bookingSessionId: string | null;

  @observable
  language: string | null = '';

  @observable
  currentMeta: any | null;

  @observable
  paymentErrorKey: string | null = null;
  @observable
  paymentErrorMsg: string | null = null;

  @observable
  currentParams: any | null;

  @observable
  currentStep: string | null;

  @observable
  stepItems: BookingItemGroup[] = [];

  @observable
  selectedItems: BookingItemInterface[] = [];

  @observable
  stepType: StepType = 'forward'

  @persist("list")
  @observable
  availableAdditionalServicesTypes: BookingItemInterface[] = [];

  navigate: (step: string, replace: boolean) => void;

  constructor() {
    if (this.bookingSessionId) {
      api.setBookingSessionId(this.bookingSessionId);
    }
  }

  @action
  setLanguage(language: string | null) {
    this.language = language;
  }

  @action
  excludeCurrentStep() {
    this.excludedSteps[this.currentStep!] = "excluded";
  }

  @action
  addeExcludeStep(step: string) {
    this.excludedSteps[step] = "excluded";
  }

  @computed
  get isCurrentStepExcluded(): boolean {
    return toJS(this.excludedSteps).hasOwnProperty(toJS(this.currentStep!));
  }

  @action
  persistBooking(): boolean {
    console.warn("Booking is persisted now!");
    return (this.isPersisted = true);
  }

  @action
  setPaymentErrorKey(err: string | null) {
    this.paymentErrorKey = err;
  }

  @action
  setPaymentErrorMsg(err: string | null) {
    this.paymentErrorMsg = err;
  }

  @action
  updateStep(step: string | null, params: {} | null) {
    const isPersisted =
      toJS(this.isPersisted) && step !== null && step !== "pep-entrance";

    let lockWorkflowStep = "pep-summary";
    if (isPersisted && (step === "pep-upsell" || step === "pep-summary")) {
      lockWorkflowStep = step;
    }
    const currentStep = isPersisted ? lockWorkflowStep : step;
    this.currentParams = isPersisted ? null : params;

    if (currentStep === this.currentStep) {
      app.deactivateLoader();
    }
    this.currentStep = currentStep;

    console.log(`Workflow step changed to "${toJS(this.currentStep)}".`);

    this.countStep(this.currentStep!);

    if (this.currentParams) {
      console.log(`Workflow params:`, toJS(this.currentParams));
    }
  }

  @action
  resetBooking() {
    this.booking = new Booking();
    this.isPersisted = false;
    this.excludedSteps = {};
    this.stepCounter = {};
  }

  @action
  setBooking(booking: Booking) {
    this.booking = booking;
  }

  @action
  toggleItem<T extends BookingItem>(cls: ClassType<T>, itemId: string) {
    if (this.isItemSelected(itemId)) {
      this.removeItem(itemId);
    } else {
      this.addItem<T>(cls, itemId);
    }

    console.log("Successfully toggled item!");
    console.log(" --> selected items", toJS(this.selectedItems));
  }

  @action
  removeItem(itemId: string) {
    const items = toJS(this.selectedItems);
    const toBeRemoved = items.filter((i) => i.id === itemId)[0];

    if (toBeRemoved) {
      this.selectedItems = items.filter((i) => i.id !== toBeRemoved.id);

      console.log("Successfully removed item!");
      console.log(" --> removed item", toBeRemoved);
      console.log(" --> selected items", toJS(this.selectedItems));
    }
  }

  @action
  addItem<T extends BookingItem>(cls: ClassType<T>, itemId: string) {
    const stepItem = this.allStepItems.filter((item) => itemId === item.id)[0];

    let items = this.selectedItems;
    items.push(plainToClass<T, {}>(cls, Object.assign({}, stepItem)) as T); // pushes a new clone
    items = sortBy(items, ["type", "title"]);
    this.selectedItems = items;

    console.log("Successfully added item!");
    console.log(" --> selected items", toJS(this.selectedItems));
  }

  @computed get allStepItems(): BookingItemInterface[] {
    const items: BookingItemInterface[] = [];
    this.stepItems.forEach((group) => {
      group.items.forEach((item) => items.push(item));
    });
    return items;
  }

  @computed get allStepIds(): string[] {
    return this.allStepItems.map((item) => {
      return item.id;
    });
  }

  @computed get hasStepItems() {
    return workflow.allStepItems.length > 0;
  }

  @computed get paymentItem() {
    return this.selectedItems.find((i: any) => {
      return i instanceof PaymentType;
    }) as PaymentType;
  }

  @computed get needExternalPayment(): boolean | undefined {
    if (!this.paymentItem) {
      return undefined;
    }
    return this.paymentItem.isWallet;
  }

  @computed get bookingOnRequest(): boolean | undefined {
    return this.booking?.bookingOnRequest;
  }

  isItemSelected(itemId: string) {
    return this.selectedItems.filter((i) => i.id === itemId).length > 0;
  }

  @action
  setBookingSessionId(bookingSessionId: string) {
    this.bookingSessionId = bookingSessionId;
  }

  @action
  public loadStep(pepxite?: boolean) {
    api.setBookingSessionId(this.bookingSessionId || "");
    return api
      .loadStep(this.currentStep!, pepxite)
      .then((response: Response) => {
        runInAction(() => {
          this.setBooking(response.booking);
          this.steps = response.workflow;
          this.selectedItems = response.booking.items.slice(0);
          this.stepItems = response.items;
          this.currentMeta = response.meta || null;

          console.log("Successfully loaded step!");
          console.log(" --> booking:", toJS(this.booking));
          console.log(" --> booked items:", toJS(this.booking!.items));
          console.log(" --> selected items", toJS(this.selectedItems));
          console.log(" --> step items", toJS(this.stepItems));
          console.log(" --> step meta", toJS(this.currentMeta));
        });
        if (!this.language) {
          this.setLanguage(response.booking.language);
        }
        return {language: response.booking.language};
      })
      .catch((e) => {
        if (e instanceof DisplayableError) {
          throw e;
        }
        throw new Error(e);
      });
  }

  @action
  public saveStep(payload: Payload) {
    const prevBooking = plainToClass<Booking, {}>(
      Booking,
      Object.assign({}, this.booking)
    );

    return api
      .saveStep(this.currentStep!, payload)
      .then((response: Response) => {
        runInAction(() => {
          this.setBooking(response.booking);
          this.selectedItems = response.booking.items.slice(0);
          this.stepItems = response.items;
          this.currentMeta = response.meta || null;

          console.log("Successfully saved step!");
          console.log(" --> booking:", toJS(this.booking));
          console.log(" --> booked items:", toJS(this.booking!.items));
          console.log(" --> selected items", toJS(this.selectedItems));
          console.log(" --> step meta", toJS(this.currentMeta));
        });
      })
      .catch((e: Error) => {
        runInAction(() => {
          this.booking = prevBooking;
          this.selectedItems = prevBooking.items.slice(0);

          console.log("Failed to saved step! " + e.message);
          console.log(" --> Restored booked items:", toJS(this.booking.items));
          console.log(" --> Restored selected items", toJS(this.selectedItems));
        });
        throw e;
      });
  }

  @action
  public bookCheck() {
    return api.bookCheck(this.currentStep).then((response: Response) => {
      return response;
    }).catch((e: Error) => {
      throw e;
    })
  }

  public navigateToNextStep(replace: boolean = false) {
    const steps = Object.keys(this.getWorkflowSteps());
    const index = this.currentStepIndex;
    const nextIndex = index + 1;
    if (typeof steps[nextIndex] !== "undefined") {
      this.stepType = 'forward';
      this.navigate(steps[nextIndex], replace);
    }
  }

  public navigateToPrevStep(replace: boolean = false) {
    const steps = Object.keys(this.getWorkflowSteps());
    const index = this.currentStepIndex;
    let nextIndex = index - 1;
    if (typeof steps[nextIndex] !== "undefined") {
      do {
        if (!!this.excludedSteps[steps[nextIndex]]) {
          nextIndex--;
        }
      } while (!!this.excludedSteps[steps[nextIndex]]);

      this.stepType = 'backward';
      this.navigate(steps[nextIndex], replace);
    }
  }

  public navigateToStep(step: string) {
    const steps = Object.keys(this.getWorkflowSteps());
    if (steps.includes(step)) {
      this.navigate(step, true);
    }
  }

  @action
  private countStep(step: string) {
    if (!this.stepCounter.hasOwnProperty(step)) {
      this.stepCounter[step] = 0;
    }
    this.stepCounter[step]++;
  }

  get isCurrentStepInitiallyVisited() {
    if (!this.stepCounter.hasOwnProperty(this.currentStep!)) {
      return false;
    }
    return this.stepCounter[this.currentStep!] === 1;
  }

  get currentStepIndex() {
    const steps = Object.keys(this.getWorkflowSteps());
    return steps.findIndex((s) => s === this.currentStep);
  }

  get currentStepTrackingCustomEvent() {
    const steps = Object.keys(this.getWorkflowSteps());
    const currentStepName = !this.currentStep || this.currentStep === 'pep-entrance' ?
      steps[1] :
      steps.find((s) =>  s === this.currentStep) || '';
    return trackingEventMapping[currentStepName];
  }

  getIndexForStep(step: string) {
    const steps = Object.keys(this.getWorkflowSteps());
    return steps.indexOf(step);
  }

  @action
  public updateSelectedItem(newValues: Item) {
    const items = this.selectedItems.filter((i) => i.id === newValues.id);
    if (items.length === 1) {
      const item = items[0];

      Object.assign(item, newValues);
      console.log("updateSelectedItem", newValues, toJS(this.selectedItems));
    } else if (items.length > 2) {
      console.warn(
        "Found more than one Item withValidation given ID.",
        newValues,
        items
      );
      throw new Error("Found more than one Item withValidation given ID.");
    }
  }

  @computed get isFlightWorkflow() {
    return !!workflow.booking && workflow.booking.workflow === Workflow.FLIGHT;
  }

  @computed get showParticipantBirthday() {
    return !!workflow.booking && (workflow.booking.workflow === Workflow.FLIGHT || workflow.booking.needPaxDateOfBirth) ;
  }

  @computed get showParticipantNationality() {
    return !!workflow.booking && (workflow.booking.workflow === Workflow.FLIGHT || workflow.booking.needPaxNationality) ;
  }

  @computed get workflowName() {
    return !!workflow.booking && workflow.booking.workflow;
  }

  @computed
  get hasExtraNights(): boolean {
    if (this.booking) {
      return !!this.booking.items.find((i) => i instanceof ExtraNightsItem);
    }
    return false;
  }

  public getWorkflowSteps() {
    if (this.booking) {
      switch (this.booking.workflow) {
        case Workflow.FLIGHT:
          return this.language === 'en' ? FlightWorkflowStepsEN : FlightWorkflowSteps;
        case Workflow.EVENT:
          return this.language === 'en' ? EventWorkflowStepsEN : EventWorkflowSteps;
        case Workflow.HOTEL_FLIGHT:
          return this.language === 'en' ? HotelFlightWorkflowStepsEN : HotelFlightWorkflowSteps;
        default:
          return this.language === 'en' ? HotelWorkflowStepsEN : HotelWorkflowSteps;
      }
    }
    return this.language === 'en' ? HotelWorkflowStepsEN : HotelWorkflowSteps;
  }

  @action
  async preparePayment(): Promise<string> {
    const baseUrl = window.location.protocol + "//" + window.location.host;

    let response: PrepareCcPaymentResponse | null;
    const request: PrepareCcPaymentRequest = {
      successUrl:
        baseUrl +
        appRouter.buildUrl(appRoutes.PAYMENT_CHECK.name, {
          bookingSession: toJS(this.bookingSessionId),
          status: PaymentStatus.SUCCESS,
        }),
      failUrl:
        baseUrl +
        appRouter.buildUrl(appRoutes.PAYMENT_CHECK.name, {
          bookingSession: toJS(this.bookingSessionId),
          status: PaymentStatus.FAIL,
        }),
    };

    console.warn("preparePayment: request", request);

    try {
      response = await api.post("/preparePayment", request);
      console.warn("preparePayment: response", response);
    } catch (e) {
      response = null;
      throw e;
    }

    if (response && response.url && response.url !== "") {
      return response!.url;
    } else {
      throw new Error("No URL found in response.");
    }
  }

  @action
  async finalizePayment() {
    const errors = Keys.WORKFLOW.Overview;

    const gotoPayment = () => this.navigate("pep-payment", true);
    const gotoSummary = () => this.navigate("pep-summary", true);

    this.setPaymentErrorKey(null);
    this.setPaymentErrorMsg(null);

    // get url params

    const state = toJS(app.state);
    const params = state.params || {};
    const bookingSession = params.bookingSession || null;
    const status = params.status || null;

    if (!bookingSession || !status) {
      this.setPaymentErrorKey(errors.paymentErrorUrlParams);
      gotoPayment();
      return;
    }

    this.setBookingSessionId(bookingSession);
    api.setBookingSessionId(bookingSession);

    // check if booking is still available

    try {
      const booking = await api.post("/getBooking", {});
      workflow.setBooking(booking);
    } catch (e) {
      // booking is gone - we can't do anything here
      errorHandler.catchError(e);
      return;
    }

    // check url status

    if (status !== PaymentStatus.SUCCESS) {
      this.setPaymentErrorKey(errors.paymentErrorExternalNotSuccessful);
      gotoPayment();
      return;
    }

    // check travelseller status

    let response = null;
    try {
      response = await api.post("/checkPayment", {});
    } catch (e) {
      this.setPaymentErrorKey(errors.paymentErrorApiCheckFailed);
      gotoPayment();
    }

    console.warn("check travelseller status: response", response);

    const paymentSuccessful = !!(
      response &&
      (response.status === "AUTHORIZED" || response.status === "OK")
    );

    if (!paymentSuccessful) {
      if (response && !!response.msg) {
        this.setPaymentErrorMsg(response.msg);
      } else {
        this.setPaymentErrorKey(errors.paymentErrorInternalNotSuccessful);
      }
      gotoPayment();
      return;
    }

    // store booking

    try {
      // TODO same as in overview...
      const payload = new PayloadOverview();

      payload.acceptTermsConditionAndDataPolicy = true;
      payload.acceptRemarks = true;
      payload.subscribeToNewsletter = true;

      await api.post("/storePayment", { payload });
    } catch (e) {
      this.setPaymentErrorKey(errors.paymentErrorApiStoreFailed);
      gotoPayment();
      return;
    }

    // everything fine

    gotoSummary();
    return;
  }

  @computed get termsAndConditionList(): TC[] {
    if (this.booking && this.booking.termsAndConditionList) {
      return this.booking.termsAndConditionList;
    }
    return [];
  }

  @action
  setAvailableAdditionalServicesTypes(availableAdditionalServicesTypes: BookingItemInterface[]) {
    this.availableAdditionalServicesTypes = availableAdditionalServicesTypes;
  }

  @computed get getAvailableAdditionalServicesTypes() {
    return this.availableAdditionalServicesTypes;
  }

  @action
  setStepType(stepType: StepType) {
    this.stepType = stepType;
  }

  get currentStepType() {
    return this.stepType;
  }
}

const workflow = new WorkflowService();

export default workflow;
