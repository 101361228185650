import {
    IsArray, IsDefined,
    IsNotEmpty, IsString, ValidateNested
}               from 'class-validator';
import { Type } from 'class-transformer';

import { Booking }            from './Booking';
import { BookingItemGroup }   from './BookingItemGroup';
import { TS_ResponseApiCall } from '../TravelSeller/API/TS_ResponseApiCall';

type WorflowStepId = string;

export class Response {
    
    @IsDefined()
    @IsNotEmpty()
    @IsString()
    for : WorflowStepId;
    
    @IsDefined()
    @IsNotEmpty()
    @Type( () => Booking )
    booking : Booking;
    
    @IsDefined()
    @IsArray()
    @ValidateNested()
    items : BookingItemGroup[];
    
    @IsDefined()
    @IsArray()
    @IsNotEmpty()
    workflow : WorflowStepId[];
    
    meta : any;
    
    _ts_api_calls : { [ key : string ] : TS_ResponseApiCall };
    
    get errorCalls() : TS_ResponseApiCall[] {
        return Object.values( this._ts_api_calls ).filter( call =>
            call.response.status !== 200
        );
    }
    
    get errorTexts() : string[] {
        return this.errorCalls.map( call => {
            return call.response.json.message || null;
        } );
    }
    
}