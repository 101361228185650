import { classToClass } from 'class-transformer';

import { toValidFormatOrNothing } from 'Helpers/moment';
import { Mapping } from 'Helpers/Mapping';

import { AddonTransferRoundtrip as RoundtripUi } from 'Models/UI/AddonTransferRoundtrip';

import {
  TS_AddonTransferRoundtrip as RoundtripTS,
  DumbNestingAgain
} from 'Models/TravelSeller/Models/TS_AddonTransferRoundtrip';

export default async function (input: RoundtripTS) {
  return new Mapping<RoundtripTS, RoundtripUi>()
    .of(RoundtripTS, input)
    .to(RoundtripUi)
    .withValidation(async (input: RoundtripTS) => ({
      ...classToClass<RoundtripTS>(input),
      inboundNr: getInfo(input).flightNr || '',
      inboundDate: toTimeString(getInfo(input).flightTime),
      inboundTime: toDateString(getInfo(input).flightTime),
      outboundNr: getInfo(input).flightNrReturn || '',
      outboundDate: toTimeString(getInfo(input).flightTimeReturn),
      outboundTime: toDateString(getInfo(input).flightTimeReturn),
      isRoundtrip: false,
      travelEnd: '', // TODO how to fill these?
      travelStart: ''
    }));
}

function getInfo(input: RoundtripTS): DumbNestingAgain {
  try {
    // TS does not always provide it....
    return input.addonInfo.addonInfoTransfer;
  } catch (e) {
    return new DumbNestingAgain();
  }
}

function toTimeString(str: string): string {
  return toValidFormatOrNothing(str, RoundtripTS.FORMAT, RoundtripUi.FORMAT_DAY) || str;
}

function toDateString(str: string): string {
  return toValidFormatOrNothing(str, RoundtripTS.FORMAT, RoundtripUi.FORMAT_TIME) || str;
}
