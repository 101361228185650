import React                 from 'react';
import { Field, FieldProps } from 'formik';
import { Input }             from 'reactstrap';

import ValidationMessage     from 'Components/Forms/ValidationMessage';
import { getPropertyByPath } from 'Helpers/objectStructure';

interface TextAreaFieldProps {
    label : string;
    name : string;
    className? : string;
    isDisabled? : boolean;
    mask? : any;
    hideInput? : boolean;
}

const TextAreaField : React.FunctionComponent<TextAreaFieldProps> = ( props : TextAreaFieldProps ) => {

    return (
        <Field name={ props.name } render={ ( { field, form } : FieldProps ) => {

            const isDisabled = props.isDisabled || false;
            const hideInput  = props.hideInput || false;

            const isErrorVisible = !!(getPropertyByPath( props.name, form.touched )
                                      && getPropertyByPath( props.name, form.errors ));

            return <div className={ props.className || '' }>

                <Input { ...field }
                       disabled={ isDisabled }
                       type={ hideInput ? 'hidden' : 'textarea' }
                       placeholder={ props.label }
                       invalid={ isErrorVisible }
                       />

                { isErrorVisible &&
                  <ValidationMessage text={ getPropertyByPath( props.name, form.errors ).toString() }/> }
            </div>;
        } }/>
    );

};

export default TextAreaField;
