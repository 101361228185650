import { SelectField, SelectFieldProps } from 'Components/Forms/SelectField';
import i18next                           from 'i18next';
import React                             from 'react';
import { Keys }                          from 'Translation/translations.de.json.keys';

const steps : string[] = [ i18next.t( Keys.COMPONENTS.TimeSelect.select ) ];

// generating time options from 00:00 to 23:55 in 5min steps
for ( let h = 0; h < 24; h++ ) {
    for ( let m = 0; m < 60; m += 5 ) {
        steps.push( withLeadingZero( h ) + ':' + withLeadingZero( m ) );
    }
}

export const TimeSelect : React.FunctionComponent<SelectFieldProps> = ( p : SelectFieldProps ) => {
    return (
        <SelectField { ...p } values={ steps.map( step => ({
            value : step,
            label : step
        }) ) }/>
    );
};

function withLeadingZero( n : number ) {
    return n.toString().padStart( 2, '0' );
}
