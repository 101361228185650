import { createContext }                      from 'react';
import { action, computed, observable, toJS } from 'mobx';

import { BookingItemGroup } from 'Models/UI/BookingItemGroup';
import { Insurance }        from 'Models/UI/Insurance';
import workflow             from 'State/WorkflowService';

export class InsuranceState {
    
    @observable ready : boolean                 = false;
    @observable isSubscriptionOpen : boolean    = false;
    @observable apiErrorMessage : string | null = null;
    
    @action toogleSub() {
        this.isSubscriptionOpen = !this.isSubscriptionOpen;
    }
    
    @action updateApiErrorMessage( msg : string | null ) {
        this.apiErrorMessage = msg;
    }
    
    @computed get itemGroup() : BookingItemGroup | null {
        const groups = workflow.stepItems.filter( g => g.id === 'api-u-no-give-id-for-insurance-group' );
        if ( groups.length === 1 ) {
            return groups[ 0 ];
        }
        return null;
    }
    
    @action readyNow() {
        this.ready = true;
    }
    
    @computed get none() {
        return this.getItemById( 'd-0' );
    }
    
    @computed get medium() {
        return this.getItemById( '1102' );
    }
    
    @computed get large() {
        return this.getItemById( '2102' );
    }
    
    @computed get selectedItems() {
        return workflow.selectedItems.filter( ( i : any ) => {
            return i instanceof Insurance;
        } );
    }
    
    @computed get hasStepItems() {
        return workflow.allStepIds.length > 0
    }
    
    @computed get getSelectedItemIds() {
        return this.selectedItems.map( i => { return i.id } );
    }
    
    private getItemById( id : string ) {
        const g = toJS( this.itemGroup );
        
        if ( !g ) {
            return null;
        }
        
        const items = g.items.filter( i => i.id === id );
        
        if ( items.length === 1 ) {
            return items[ 0 ];
        }
        
        return null;
    }
    
}

export const insuranceState = createContext( new InsuranceState() );
