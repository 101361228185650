import React from 'react';

import styles from './ValidationMessage.module.scss';

interface Props {
    text : string;
}

const ValidationMessage : React.FunctionComponent<Props> = ( props ) => {
    return (
        <div className={ styles.message }>
            { props.text }
        </div>
    );
};

export default ValidationMessage;