import { Item } from 'Models/UI/Item';
import { ItemType } from 'Models/UI/ItemType';

export class AddonTransferOnewayInbound extends Item {
  type = ItemType.TRANSFER_ONEWAY_INBOUND;

  public static FORMAT_DAY = 'DD.MM.YYYY';
  public static FORMAT_TIME = 'HH:mm';
  public static FORMAT_ADDON_INFO = 'YYYY-MM-DD HH:mm';

  inboundNr: string;
  inboundDate: string;
  inboundTime: string;

  // for form validation
  travelStart: string | null;
  travelEnd: string | null;
}
