import React from 'react';

import styles from './Headline.module.scss';

interface Props {
    text : string;
}

const Headline : React.FunctionComponent<Props> = ( props ) => <div>
    <h1 className={ styles.headline }>{ props.text }</h1>
</div>;

export default Headline;