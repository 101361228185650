import { ValidateNested } from 'class-validator';
import { Type }           from 'class-transformer';

import { Passenger }       from './Passenger';
import { BusinessPartner } from './BusinessPartner';

export class Meta {
    
    @ValidateNested()
    @Type( () => BusinessPartner )
    businessPartner : BusinessPartner;
    
    @ValidateNested()
    @Type( () => Passenger )
    passengers : Passenger[];
}

