import React              from 'react';
import { useTranslation } from 'react-i18next';
import { observer }       from 'mobx-react';

import { ExpectedError, RouteNotFoundError, EnpointError } from 'State/ErrorHandler';
import { Keys }                                            from 'Translation/Setup';

import styles from './ErrorDisplay.module.scss';

interface P {
    error : Error;
}

const ErrorDisplayComponent = ( { error } : P ) => {
    
    const { t } = useTranslation();
    
    let key = Keys.COMPONENTS.ErrorDisplay.DefaultError;
    if ( error instanceof RouteNotFoundError ) {
        key = Keys.COMPONENTS.ErrorDisplay.RouteNotFoundError;
    } else if ( error instanceof EnpointError ) {
        key = Keys.COMPONENTS.ErrorDisplay.EnpointError;
    } else if ( error instanceof ExpectedError ) {
        key = Keys.COMPONENTS.ErrorDisplay.ExpectedError;
    }
    
    return <div>
        <h1 className={ styles.h1 }>{ t( key.h1 ) }</h1>
        <h2 className={ styles.h2 }>{ t( key.h2 ) }</h2>
        { error.message && <p className={ styles.p }>{ error.message }</p> }
    </div>;
    
};

const ErrorDisplay = observer( ErrorDisplayComponent );
export default ErrorDisplay;
