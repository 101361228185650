import i18next               from 'i18next';
import { defaultsDeep }      from 'lodash-es';
import React                 from 'react';
import { Input }             from 'reactstrap';
import { Field, FieldProps } from 'formik';

import ValidationMessage     from 'Components/Forms/ValidationMessage';
import { getPropertyByPath } from 'Helpers/objectStructure';
import SelectFieldOption     from 'Models/SelectFieldOption';
import { Keys }              from 'Translation/translations.de.json.keys';

export interface SelectFieldProps {
    label : string;
    name : string;
    className? : string;
    values? : SelectFieldOption[];
    isDisabled? : boolean;
    isErrorDisplayed? : boolean;
}

export const SelectField : React.FunctionComponent<SelectFieldProps> = ( props : SelectFieldProps ) => {
    return (
        <Field name={ props.name } render={ ( { field, form } : FieldProps ) => {

            const p : SelectFieldProps = defaultsDeep( { ...props }, {
                className        : '',
                values           : [],
                isDisabled       : false,
                isErrorDisplayed : true
            } );

            const showError = p.isErrorDisplayed
                              && !!(getPropertyByPath( props.name, form.touched )
                              && getPropertyByPath( props.name, form.errors ));

            return <div>
                <Input
                    type="select" { ...field }
                    className={ p.className + (showError ? ' is-invalid' : '')}
                    disabled={ p.isDisabled }
                    >
                    { p.values!.map( ( o, i ) =>
                        <option key={ i } value={ o.value! }>{ o.label }</option>
                    ) }
                </Input>
                { showError &&
                  <ValidationMessage text={ getPropertyByPath( props.name, form.errors ).toString() }/> }
            </div>;
        } }/>
    );
};

interface SalutationFieldProps {
    label : string;
    name : string;
    className? : string;
    values? : SelectFieldOption[];
    isDisabled? : boolean;
    isChild? : boolean;
}

export const salutations : SelectFieldOption[] = [
    { label : i18next.t( Keys.COMPONENTS.SelectField.men ), value : "2" },
    { label : i18next.t( Keys.COMPONENTS.SelectField.women ), value : "1" },
    { label : i18next.t( Keys.COMPONENTS.SelectField.child ), value : "3" }
];

export function getSalutationLabel( value : string ) : string {
    const filtered = salutations.filter( s => s.value === value );
    if ( filtered.length === 1 ) {
        return filtered[ 0 ].label;
    }

    return '';
}

export const SalutationField : React.FunctionComponent<SalutationFieldProps> = ( p : SalutationFieldProps ) => {
    const isChild    = p.isChild || false;
    const isDisabled = p.isDisabled || false;
    let values;
    if ( isChild ) {
        values = [ { label : i18next.t( Keys.COMPONENTS.SelectField.child ), value : 'Kind' } ];
    } else {
        values = [ { label : i18next.t( Keys.COMPONENTS.SelectField.salutation ), value : '' },
                   { label : i18next.t( Keys.COMPONENTS.SelectField.men ), value : "2" },
                   { label : i18next.t( Keys.COMPONENTS.SelectField.women ), value : "1" } ];
    }

    return (
        <SelectField
            { ...p }
            isDisabled={ isChild ? true : isDisabled }
            values={ values }/>
    );

};
