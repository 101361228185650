import i18next from 'i18next';
import React from 'react';
import { FormGroup } from 'reactstrap';
import { IsNotEmpty } from 'class-validator';
import { Form } from 'formik';

import { join } from 'Helpers/conditionalStyles';
import { Keys } from 'Translation/Setup';

import pepxpressStyles from './AddonBus-pepxpress.module.scss';
import pepxciteStyles from './AddonBus-pepxcite.module.scss';
import FormCollapsible from '../FormCollapsible';
import FormConfiguration from '../FormConfiguration';
import withFormik from '../withFormik';
import { AddonBus } from '../../../../Models/UI/AddonBus';
import TextAreaField from '../../../../Components/Forms/TextAreaField';
import { ThemeProps } from '../../../../Context/AppContext';
import { withTheme } from '../../../../Context/withTheme';

const Key = Keys.WORKFLOW.AdditionalServices.AddonBus;

export class AddonBusFormModel extends AddonBus {
  @IsNotEmpty({ message: i18next.t(Key.addText) })
  description: string;
}

const defaults = new AddonBusFormModel();
defaults.description = '';

const AddonBusFormInner = withFormik<AddonBusFormModel>(
  AddonBusFormModel,
  defaults
)((props: any) => {
  return (
    <Form>
      <div
        className={join(
          props.styles.row,
          props.styles.row__withBorder,
          props.styles.row__withChildren
        )}
      >
        <FormGroup>
          <TextAreaField
            className="w-100"
            label={i18next.t(Key.defaultOption)}
            name={'description'}
          />
        </FormGroup>
      </div>
    </Form>
  );
});

const AddonBusForm: React.FunctionComponent<FormConfiguration<AddonBusFormModel>> = (
  p: any & ThemeProps
) => {
  const styles = p.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  return (
    <div>
      <FormCollapsible item={p.item} isOpen={p.isSelected}>
        <AddonBusFormInner {...p} styles={styles} />
      </FormCollapsible>
    </div>
  );
};
export default withTheme(AddonBusForm);
