import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { isDev } from "env";

import de from "./translations.de.json";
import en from "./translations.en.json";

export { Keys } from "./translations.de.json.keys";

export class Language {
  static DE = "de";
  static EN = "en";
}

export default i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      de: { translation: de },
      en: { translation: en }
    },
    fallbackLng: Language.DE,
    debug: isDev,
    saveMissing: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false, // we are going to load on init
      transSupportBasicHtmlNodes: false // allow <br/> other html elements in translations
    }
  });
