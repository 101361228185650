import { TS_Item } from './TS_Item';
import { TS_ItemInterface } from './TS_ItemInterface';

export class TS_Offer extends TS_Item implements TS_ItemInterface {
    id : string;
    type : string;
    mandatory : boolean;
    selectType : string;
    groupID : string;
    title : string;
    description : string;
    totalPrice : number;
    infoURL : string;
    imageURL : string;
    dateFrom: string;
    dateUntil: string;
    stars: number;
    destination: string;
    teaser: string;
}