import { action, computed, observable, runInAction } from 'mobx';

import { getInstance, getValidationErrorsPlain, ErrorsPlain } from 'Helpers/validate';

import workflow            from 'State/WorkflowService';
import { BusinessPartner } from './Models/BusinessPartner';
import { Passenger }       from './Models/Passenger';
import { Meta }            from './Models/Meta';

export class ParticipantsState {

    @observable values : Meta | null;
    @observable errors : ErrorsPlain = {};

    @observable wasSubmitted : boolean          = false;
    @observable apiErrorMessage : string | null = null;

    @action updateApiErrorMessage( msg : string | null ) {
        this.apiErrorMessage = msg;
    }

    @action setWasSubmitted( b : boolean ) {
        this.wasSubmitted = b;
    }

    @action
    async setValues( m : Meta ) {

        const meta           = getInstance<Meta, {}>( Meta, m );
        meta.businessPartner = ParticipantsState.setDefaultsOnBusinessPartner( meta.businessPartner );
        meta.passengers      = meta.passengers.map( ParticipantsState.setDefaultsOnPassenger );

        const errors = await getValidationErrorsPlain<Meta, {}>(
            Meta, meta || {}, { skipMissingProperties : true } );

        runInAction( () => {
            this.values = meta;
            this.errors = errors;
        } );

        console.log( 'errors', errors );
    }

    @computed get isFirstStep() : boolean {
        // TODO this component has not to know / do this!
        return (
            (workflow.excludedSteps.hasOwnProperty( 'pep-additional-services' ) && workflow.excludedSteps.hasOwnProperty( 'pep-train' ))
            || (workflow.isFlightWorkflow)
            );
    }

    @computed get isValid() {
        return Object.keys( this.errors ).length === 0;
    }

    @computed get showErrors() {
        return !this.isValid && this.wasSubmitted;
    }

    @computed get hasBusinessPartnerError() {
        return this.errors.hasOwnProperty( 'businessPartner' );

    }

    @computed get hasPassengersError() {
        return this.errors.hasOwnProperty( 'passengers' );
    }


    private static setDefaultsOnBusinessPartner( bp : BusinessPartner ) {
        bp.affiliateMode             = bp.affiliateMode || false;
        bp.salutation                = bp.salutation || '';
        bp.surname                   = bp.surname || '';
        bp.firstname                 = bp.firstname || '';
        bp.company                   = bp.company || '';
        bp.street                    = bp.street || '';
        bp.number                    = bp.number || '';
        bp.postcode                  = bp.postcode || '';
        bp.city                      = bp.city || '';
        bp.country                   = bp.country || 'DE';
        bp.phone                     = bp.phone || '';
        bp.email                     = bp.email || '';
        bp.mobile                    = bp.mobile || '';
        bp.hasNewsletterSubscription = bp.hasNewsletterSubscription || false;
        return bp;
    }

    private static setDefaultsOnPassenger( p : Passenger, i : number ) {
        p.isBusinessPartner = p.isBusinessPartner
                              ? p.isBusinessPartner
                              : i === 0;
        p.type              = p.type || 0;
        p.salutation        = p.salutation || '';
        p.surname           = p.surname || '';
        p.firstname         = p.firstname || '';
        // p.country           = p.country || '';
        p.nationality       = p.nationality || '';
        p.bday              = p.bday || 0;
        p.bmonth            = p.bmonth || 0;
        p.byear             = p.byear || 0;
        p.dateOfBirthNeeded = p.dateOfBirthNeeded || false;
        p.passportNeeded = p.passportNeeded || false;
        p.passportNumber = p.passportNumber || '';
        p.passportValidThru = p.passportValidThru || '';
        return p;
    }

    private static getInitialMeta() : Meta {
        return {
            businessPartner : ParticipantsState.setDefaultsOnBusinessPartner( new BusinessPartner() ),
            passengers      : [ ParticipantsState.setDefaultsOnPassenger( new Passenger(), 0 ) ]
        };
    }

}
