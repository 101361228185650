import React from 'react'
import { Passenger } from '../../Workflow/Steps/Participants/Models/Passenger'
import { Row, Col } from 'reactstrap'
import { getSalutationLabel } from '../Forms/SelectField'
import { Booking } from '../../Models/UI/Booking'
import moment from 'moment'

interface Props {
  passengers: Passenger[]
}

export const ParticipantsData = (props: Props) => {
  const getBirthday = (p: Passenger) => {
    if(p.bday && p.bmonth - 1 && p.byear) {
      const d = new Date( p.byear, p.bmonth - 1, p.bday );
      const date = moment(d).format(Booking.FORMAT_DATE);
      return date;
    }
    return;
  };
  //TODO translation
  return (
    <>
      {props.passengers && props.passengers.length > 0 && props.passengers.map((passenger, i) =>
        <Row key={"participantsData_" + i}>
          <Col >
            {getSalutationLabel(passenger.salutation)}
            {" "}
            {passenger.firstname}
            {" "}
            {passenger.surname}
            {getBirthday(passenger) && <>
              <span> geb. </span> {getBirthday(passenger)}
              </>
            }
          </Col>
        </Row>
      )}
    </>
  )
}
