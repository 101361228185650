import React from 'react';
import { TiTick } from 'react-icons/ti';
import { observer } from 'mobx-react';
import sortBy from 'lodash-es/sortBy';

import pepxpressStyles from './CartIncludedItems-pepxpress.module.scss';
import pepxciteStyles from './CartIncludedItems-pepxcite.module.scss';
import { BookingItemInterface } from 'Models/UI/BookingItemInterface';
import { ItemIncluded } from 'Models/UI/ItemIncluded';
import { withTheme } from '../../Context/withTheme';
import { ThemeProps } from '../../Context/AppContext';

@observer
class CartIncludedItems extends React.Component<
  {
    items: BookingItemInterface[];
  } & ThemeProps,
  {}
> {
  private KEY_PREFIX = 'includedItem_';

  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    return (
      <ul className={styles.items}>
        {this.includedSorted.map(item => {
          return (
            <li className={styles.item} key={this.KEY_PREFIX + item.id}>
              <TiTick /> <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
            </li>
          );
        })}
      </ul>
    );
  }

  get included() {
    return this.props.items.filter(i => i instanceof ItemIncluded);
  }

  get includedSorted() {
    return sortBy(this.included, function (item) {
      return parseInt(item.id);
    });
  }
}
export default withTheme(CartIncludedItems);
