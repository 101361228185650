import i18next from 'i18next';
import { action, computed, observable, runInAction, toJS } from 'mobx';

import workflow from 'State/WorkflowService';
import { Addon } from 'Models/UI/Addon';
import { AddonEvent } from 'Models/UI/AddonEvent';
import { AddonTransferOnewayOutbound } from 'Models/UI/AddonTransferOnewayOutbound';
import { AddonTransferOnewayInbound } from 'Models/UI/AddonTransferOnewayInbound';
import { AddonTransferRoundtrip } from 'Models/UI/AddonTransferRoundtrip';
import { AddonWellness } from 'Models/UI/AddonWellness';
import { BookingItem } from 'Models/UI/BookingItem';
import { BookingItemGroup } from 'Models/UI/BookingItemGroup';
import { getValidationErrorsPlain } from 'Helpers/validate';
import { Keys } from 'Translation/Setup';

import { AddonErrors } from './AddonErrors';
import { AddonEventFormModel } from './AddonEvent/Component';
import { AddonTransferOnewayOutboundFormModel } from './AddonTransferOnewayOutbound/Component';
import { AddonTransferOnewayInboundFormModel } from './AddonTransferOnewayInbound/Component';
import { AddonTransferRoundtripFormModel } from './AddonTransferRoundtrip/Component';
import { AddonBus } from '../../../Models/UI/AddonBus';
import { AddonBusFormModel } from './AddonBus/AddonBus';
import { AddonInfoSeats } from '../../../Models/TravelSeller/Models/TS_AddonEvent';

const Key = Keys.WORKFLOW.AdditionalServices.AddonEvent;

export class AdditionalServicesState {
  @observable showValidation: boolean = false;
  @observable apiErrorMessage: string | null = null;
  @observable groupErrorMessages: { [groupId: string]: string } = {};
  @observable itemErrorMessages: { [groupId: string]: AddonErrors } = {};
  @observable addonsToSave: { [id: string]: Addon } = {};

  @action updateShowValidation(b: boolean) {
    this.showValidation = b;
  }

  @action updateApiErrorMessage(msg: string | null) {
    this.apiErrorMessage = msg;
  }

  @computed get selectedItems() {
    return workflow!.selectedItems;
  }

  @computed get groups() {
    const groups = workflow.stepItems
      .map(g => {
        g.items = g.items.filter(i => i.isBookable);
        return g;
      })
      .filter(g => g.items.length > 0);
    return groups;
  }

  @computed get getSelectedItemIds() {
    return this.selectedItems.map(i => {
      return i.id;
    });
  }

  @computed get hasGroupErrors() {
    return Object.keys(this.groupErrorMessages).length > 0;
  }

  @computed get hasItemErrors() {
    return Object.keys(this.itemErrorMessages).length > 0;
  }

  @computed get hasErrors() {
    return this.hasGroupErrors || this.hasItemErrors;
  }

  @computed get addons() {
    const selectedItems = toJS(workflow.selectedItems).filter(
      i =>
        i instanceof AddonWellness ||
        i instanceof AddonEvent ||
        i instanceof AddonBus ||
        i instanceof AddonTransferRoundtrip ||
        i instanceof AddonTransferOnewayOutbound ||
        i instanceof AddonTransferOnewayInbound
    );

    // Workaround for TS: groupID is not there... -_-
    return selectedItems.map(si => {
      const match = workflow.allStepItems.find(i => i.id === si.id);
      if (match) {
        si.groupID = match.groupID;
      }
      return si;
    });
  }

  @action
  async validateSelectedItems() {
    this.itemErrorMessages = {};
    console.group('validateSelectedItems', this.addons);
    await Promise.all(
      this.addons.map(async i => {
        console.log('sending ' + i.title + '... ');
        return this.validateItem(i, this.getGroupById(i.groupID));
      })
    );
    console.groupEnd();
  }

  @action
  async validateItem(i: BookingItem, g: BookingItemGroup) {
    let errors: AddonErrors = {};
    if (!i.id.startsWith('d-')) {
      const current = toJS(this.addonsToSave);
      const item = current.hasOwnProperty(i.id) ? current[i.id] : i;
      if (item instanceof AddonEvent) {
        errors = await getValidationErrorsPlain(AddonEventFormModel, item);
        if (item.floorplanReqired) {
          const foundItem = workflow.selectedItems.find(singleItem => singleItem.id === item.id);
          if (!!foundItem) {
            const numberOfPassengers = workflow.currentMeta?.passengers?.length || 0;
            if (
              (foundItem.addonInfo as AddonInfoSeats)?.addonInfoSeats?.length !== numberOfPassengers
            ) {
              errors = {
                ...errors,
                selectedShow: i18next.t(Key.selectedShowWrongNrOfParticipants, {
                  seats: numberOfPassengers
                })
              };
            }
          }
        }
      } else if (item instanceof AddonBus) {
        errors = await getValidationErrorsPlain(AddonBusFormModel, item);
      } else if (item instanceof AddonTransferRoundtrip) {
        errors = await getValidationErrorsPlain(AddonTransferRoundtripFormModel, item);
      } else if (item instanceof AddonTransferOnewayOutbound) {
        errors = await getValidationErrorsPlain(AddonTransferOnewayOutboundFormModel, item);
      } else if (item instanceof AddonTransferOnewayInbound) {
        errors = await getValidationErrorsPlain(AddonTransferOnewayInboundFormModel, item);
      }
      console.log('validateItem', item, errors);
    }
    runInAction(() => {
      this.updateErrorMessages(errors, g);
    });
  }

  @action
  updateErrorMessages(errors: AddonErrors, g: BookingItemGroup) {
    if (Object.keys(errors).length === 0) {
      if (this.itemErrorMessages.hasOwnProperty(g.id)) {
        delete this.itemErrorMessages[g.id];
      }
    } else {
      this.itemErrorMessages[g.id] = errors;
    }
  }

  getGroupById(groupID: string): BookingItemGroup {
    return workflow.stepItems.filter(g => g.id === groupID)[0];
  }

  @computed get firstErrorId() {
    return Object.keys(this.groupErrorMessages)[0];
  }

  @action validateGroups() {
    const errors: { [groupId: string]: string } = {};

    this.groups.forEach(group => {
      const groupItemIds = group.items.map(i => {
        return i.id;
      });
      const groupItemsSelected = this.selectedItems.filter(i => groupItemIds.includes(i.id)).length;

      if (group.isSelectionObligatory && groupItemsSelected === 0) {
        errors[group.id] = i18next.t(
          group.isMultipleSelection
            ? Keys.WORKFLOW.AdditionalServices.multiSelectMandatoryError
            : Keys.WORKFLOW.AdditionalServices.multiSelectError
        );
      }

      if (group.isSingleSelection && groupItemsSelected > 1) {
        errors[group.id] = i18next.t(Keys.WORKFLOW.AdditionalServices.singleSelectError);
      }

      if (this.itemErrorMessages.hasOwnProperty(group.id)) {
        errors[group.id] = i18next.t(Keys.WORKFLOW.AdditionalServices.itemError);
      }
    });

    this.groupErrorMessages = errors;

    console.log('groupErrorMessages', toJS(this.groupErrorMessages));
  }

  @action removeItemError(g: BookingItemGroup) {
    if (this.itemErrorMessages.hasOwnProperty(g.id)) {
      delete this.itemErrorMessages[g.id];
    }
  }

  @action
  async handleAddonChange(item: BookingItem, errors: AddonErrors) {
    console.log('handleAddonChange "' + item.title + '"', item, errors);

    this.addonsToSave[item.id] = item;

    await this.validateSelectedItems();
    await this.validateGroups();
  }
}
