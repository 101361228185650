import Headline from 'Components/Workflow/Headline';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import app from 'State/AppService';
import errorHandler from 'State/ErrorHandler';
import workflow from 'State/WorkflowService';
import { Keys } from 'Translation/Setup';
import UpsellLayout from '../../../Components/Workflow/UpsellLayout';
import { OfferLayout } from './Components/OfferLayout';
import { UpsellState, upsellState } from './UpsellState';

import iconTransfer from './Icons/pep-circle-transfers.png';
import iconNewsletter from './Icons/pep-circle-newsletter.png';
import iconSki from './Icons/pep-circle-skireisen.png';
import iconParking from './Icons/pep-circle-parkplatz.png';
import iconEvents from './Icons/pep-circle-ausfluege.png';
import iconRentalCar from './Icons/pep-circle-mietwagen.png';
import iconWideTeaser from './Icons/HanseMerkur_Extras.png';
import iconWideTeaser2 from './Icons/PEP-Ausweis_Banner.jpg';

import pepxpressStyles from './Upsell-pepxpress.module.scss';
import pepxciteStyles from './Upsell-pepxcite.module.scss';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import Banner from './Components/Banner';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {useLanguage} from "../../../Helpers/useLanguage";

// interface Props {
//   workflow?: WorkflowService;
//   errorHandler?: ErrorHandler;
//   app?: AppService;
// }
//
// interface State {}

const Key = Keys.WORKFLOW.Upsell;

// const teasers = [
//   {id: 1, url: "#", text: "Text", alt: "Text", img: ""},
//   {id: 2, url: "#", text: "Text", alt: "Text", img: ""},
//   {id: 3, url: "#", text: "Text", alt: "Text", img: ""},
//   {id: 4, url: "#", text: "Text", alt: "Text", img: ""},
//   {id: 5, url: "#", text: "Text", alt: "Text", img: ""},
//   {id: 6, url: "#", text: "Text", alt: "Text", img: ""}
// ];

const Upsell = observer((props: ThemeProps) => {
  const styles = props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
  const state = useContext<UpsellState>(upsellState);
  const { t, i18n } = useTranslation();
  const iframeSrcLanguage = useLanguage();

  const smallTeasers = [
    {
      id: 1,
      url: 'https://www.pepxpress.com/de/partner/urlaubstransfers',
      icon: iconTransfer,
      alt: 'Text',
      headline: t(Key.transfer)
    },
    {
      id: 2,
      url: 'https://www.pepxpress.com/de/mein-konto/kontodaten',
      icon: iconNewsletter,
      alt: 'Text',
      headline: t(Key.newsletter)
    },
    {
      id: 3,
      url: 'https://www.pepxpress.com/de/partner/snowtrex',
      icon: iconSki,
      alt: 'Text',
      headline: t(Key.ski)
    },
    {
      id: 4,
      url: 'https://www.pepxpress.com/de/vorteilspartner/parkplaetze',
      icon: iconParking,
      alt: 'Text',
      headline: t(Key.parking)
    },
    {
      id: 4,
      url: 'https://www.pepxpress.com/de/vorteilspartner/ausfluege',
      icon: iconEvents,
      alt: 'Text',
      headline: t(Key.events)
    },
    {
      id: 4,
      url: 'https://www.pepxpress.com/de/vorteilspartner/mietwagen',
      icon: iconRentalCar,
      alt: 'Text',
      headline: t(Key.rentalCar)
    }
  ];

  useEffect(() => {
    if (
      workflow.isCurrentStepExcluded ||
      (workflow.booking && workflow.booking.ritMode !== 'RIT') ||
      props.theme === 'pepxcite'
    ) {
      workflow.excludeCurrentStep();
      return;
    }
    app.trackTimeLoadStarted(workflow.currentStep!);
    workflow
      .loadStep()
      .then(({language}) => {
        const globalLanguage = iframeSrcLanguage || language;
        if (!!globalLanguage) {
          i18n.changeLanguage(globalLanguage);
        }
        state.readyNow();
        app.deactivateLoader();
      })
      .then(() => app.trackTimeLoadFinished(workflow.currentStep!))
      .then(() => {
        // if (state.selectedItems.length > 0) {
        //   toggleItem(state.selectedItems[0] as AddonTrain);
        // } else {
        //   toggleItem(state.none as AddonTrain);
        // }
      })
      .then(() => window.scrollTo(0, 0))
      .catch(e => errorHandler.catchError(e));
    // eslint-disable-next-line
  }, []);

  function renderTop() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-baseline">
          <Headline text={i18next.t(Key.headline)} />
          <Button outline color="primary" onClick={() => window?.top?.close()}>
            {i18next.t(Key.close)}
          </Button>
        </div>
        <div className={`mt-4 ${styles.subHeadline}`}>{t(Key.subHeadline)}</div>
      </>
    );
  }

  function renderBottom() {
    return <></>;
  }

  function renderLeft() {
    return (
      <>
        <div className="my-3">
          {state.banners && state.banners.length > 0 && <Banner banners={state.banners} />}
        </div>
        <OfferLayout
          headline={t(Key.hotelHeadline)}
          offers={state.hotels}
          button={state.hotelMore}
          theme={props.theme}
        />
        <OfferLayout
          headline={t(Key.flightHeadline)}
          offers={state.flights}
          button={state.flightMore}
          theme={props.theme}
        />
        <OfferLayout
          headline={t(Key.eventHeadline)}
          offers={state.events}
          button={state.eventMore}
          theme={props.theme}
        />
        {/*<Row className="mb-4">
          {teasers.map(teaser =>
            <Col key={teaser.id} className={`p-3 ${styles.teaser}`} xs={{size: 12}} sm={{size: 6}} md={{size: 4}}>
              <a
                href={teaser.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.image}
                  src={teaser.img}
                  alt={teaser.alt}
                />
                <div className={styles.teaser__text}>{teaser.text}</div>
              </a>
            </Col>
          )}
        </Row>*/}
        <h2 className={styles.headline}>{t(Key.benefitsPartner)}</h2>
        <div className={`${styles.info__container} mb-5`}>
          <Row>
            {smallTeasers.map((smallTeaser, idx: number) => (
              <Col
                key={smallTeaser.id}
                xs={{ size: 12 }}
                lg={{ size: 2 }}
                className={`mb-lg-3 ${idx !== smallTeasers.length - 1 ? ' mb-5' : ' mb-2'} ${
                  styles.info
                }`}
              >
                <a
                  className={`d-block w-100 text-center ${styles.teaserLink}`}
                  href={smallTeaser.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={smallTeaser.icon} alt={smallTeaser.alt} />
                  <h3>{smallTeaser.headline}</h3>
                </a>
              </Col>
            ))}
          </Row>
        </div>
        <h2 className={styles.headline}>{t(Key.insurances)}</h2>
        <a
          href="https://www.pepxpress.com/vorteilspartner/hansemerkur"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="w-100 mb-5" src={iconWideTeaser} alt="HanseMerkur" />
        </a>
        <h2 className={styles.headline}>{t(Key.pepAusweis)}</h2>
        <a href="https://www.pep-ausweis.de/" target="_blank" rel="noopener noreferrer">
          <img className="w-100 mb-4" src={iconWideTeaser2} alt="PEP-Ausweis" />
        </a>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs />
      <UpsellLayout
        top={renderTop()}
        left={renderLeft()}
        bottom={renderBottom()}
        theme={props.theme}
      />
    </>
  );
});
export default withTheme(Upsell);
