import React                         from 'react';
import { useTranslation }            from 'react-i18next';
import { TiArrowLeft, TiArrowRight } from 'react-icons/ti';
import { Button }                    from 'reactstrap';

import { Keys } from 'Translation/Setup';

import styles from './Nav.module.scss';

type Props = {
    onPrev? : () => void | Promise<void>;
    onNext? : () => void | Promise<void>;
    textPrev? : string;
    textNext? : string;
    onCloseWindow?: () => void
    leftButton?: JSX.Element
    nextDisabled?: boolean;
}

const Nav = ( props : Props ) => {

    const { t } = useTranslation();

    const nextText = props.textNext || t( Keys.COMPONENTS.Nav.next );
    const prevText = props.textPrev || t( Keys.COMPONENTS.Nav.prev );
    const closeText = t( Keys.COMPONENTS.Nav.close );
    const {nextDisabled} = props;

    const hasNext = typeof props.onNext === 'function';
    const hasPrev = typeof props.onPrev === 'function';
    const hasCloseWindow = typeof props.onCloseWindow === 'function';

    return <div className={ styles.nav }>
        {hasCloseWindow &&
            <Button outline color="primary"
                onClick={ closeWindow }
                tabIndex={ 0 }
                >
                <span>{ closeText }</span>
            </Button>
        }
        {props.leftButton}


        { hasPrev ? <Button outline color="primary"
                            onClick={ prev }
                            tabIndex={ 0 }
                            onKeyPress={ prevOnKeypressEnter }>
                      <TiArrowLeft/>
                      <span>{ prevText }</span>
                  </Button>
                  : <span></span> }
        { hasNext ? <Button color="primary"
                            onClick={ next }
                            tabIndex={ 0 }
                            onKeyPress={ nextOnKeypressEnter }
                            style={{flexBasis: '25%'}}
                            disabled={nextDisabled}
                            >
                                <div>
                      <span>{ nextText }</span>
                      <TiArrowRight/>
                      </div>
                  </Button>
                  : <span></span> }
    </div>;

    function next( e ? : React.MouseEvent ) {
        e && e.preventDefault();
        props.onNext && props.onNext();
    }

    function prev( e ? : React.MouseEvent ) {
        e && e.preventDefault();
        props.onPrev && props.onPrev();
    }

    function closeWindow( e ? : React.MouseEvent ) {
        e && e.preventDefault();
        props.onCloseWindow && props.onCloseWindow();
    }

    async function prevOnKeypressEnter( e : React.KeyboardEvent ) {
        e.key === "Enter" && prev();
    }

    async function nextOnKeypressEnter( e : React.KeyboardEvent ) {
        e.key === "Enter" && next();
    }
};

export default Nav;
