import i18next from 'i18next';
import { runInAction } from 'mobx';
import { PayloadOverview } from 'Models/UI/PayloadOverview';
import React from 'react';
import { Trans } from 'react-i18next';
import { TiInfoLarge } from 'react-icons/ti';
import { action, computed, observable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import {Alert, Button, Collapse, Modal, ModalBody, ModalFooter} from 'reactstrap';
import { Element as ScrollTo, scroller } from 'react-scroll';
import workflow, { WorkflowService } from 'State/WorkflowService';

import { ErrorHandler } from 'State/ErrorHandler';
import { AppService } from 'State/AppService';
import CartIncludedItems from 'Components/Workflow/CartIncludedItems';
import DefaultLayout from 'Components/Workflow/DefaultLayout';
import ContactData from 'Components/Workflow/ContactData';
import Headline from 'Components/Workflow/Headline';
import Cart from 'Components/Workflow/Cart';
import Nav from 'Components/Workflow/Nav';
import PepText from 'Components/PepText';
import ExtraNights from 'Components/Workflow/ExtraNights';
import { getInstance } from 'Helpers/validate';
import { conditionalStyles } from 'Helpers/conditionalStyles';
import { Note } from 'Models/UI/Note';
import { Keys } from 'Translation/Setup';

import { BusinessPartner } from '../Participants/Models/BusinessPartner';
import { Meta } from './Models/Meta';
import pepxpressStyles from './Overview-pepxpress.module.scss';
import pepxciteStyles from './Overview-pepxcite.module.scss';
import { ParticipantsData } from '../../../Components/Workflow/ParticipantsData';
import Breadcrumbs from '../../../Components/Layouts/Parts/Breadcrumbs';
import { TC } from '../../../Models/UI/TC';
import { withTheme } from '../../../Context/withTheme';
import { ThemeProps } from '../../../Context/AppContext';
import {LanguageContext} from "../../../Helpers/useLanguage";

const Key = Keys.WORKFLOW.Overview;

interface Props {
  workflow?: WorkflowService;
  errorHandler?: ErrorHandler;
  app?: AppService;
}

interface State {}

@inject('errorHandler')
@inject('app')
@inject('workflow')
@observer
class Overview extends React.Component<Props & ThemeProps, State> {
  genericErrorName = 'OverviewError';

  @observable meta: Meta = getInitialMeta();

  @observable isNotesExpanded: boolean = false;
  @observable isLegalChecked: boolean = false;
  @observable isNotesChecked: boolean = false;
  @observable isNewsletterChecked: boolean = false;
  @observable isSubmitted: boolean = false;
  @observable apiErrorMessage: string | null = null;
  @observable termsAndConditionList: TC[] = [];
  @observable bookCheckNegativeMessage: string | null = null;
  @observable bookCheckNegativeStep: string | null = null;
  @observable isLoading: boolean = false;

  static contextType = LanguageContext
  context!: React.ContextType<typeof LanguageContext>

  @action setTermsAndConditionList(values: TC[]) {
    this.termsAndConditionList = values;
  }

  async componentDidMount() {
    const app = this.props.app!;
    const workflow = this.props.workflow!;
    const errorHandler = this.props.errorHandler!;
    const currentStep = workflow.currentStep!;
    const booking = workflow.booking!;

    app.trackTimeLoadStarted(currentStep);
    this.setIsLoading(true);
    workflow
      .loadStep(this.props.theme === 'pepxcite')
      .then(({language}) => {
        const globalLanguage = this.context || language;
        if (!!globalLanguage) {
          i18next.changeLanguage(globalLanguage);
        }
        this.parseMeta.bind(this)();
      })
      .then(app.deactivateLoader.bind(app))
      .then(() => app.trackTimeLoadFinished(currentStep))
      .then(() => app.tracking.sendCheckoutStepEvent(booking, workflow.currentStepTrackingCustomEvent, workflow.getAvailableAdditionalServicesTypes, workflow?.paymentItem?.title))
      .then(() => {
        window.scrollTo(0, 0);
        if (workflow.booking && workflow.booking.termsAndConditionList) {
          this.setTermsAndConditionList(workflow.booking.termsAndConditionList);
        }
      }).then(() => {
        return this.bookCheck();
    })
      .catch(e => {
        errorHandler.catchError(e)
      }).finally(() => {
        this.setIsLoading(false);
    });
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setBookCheckNegativeMessage(value: string) {
    this.bookCheckNegativeMessage = value;
  }

  @action
  setBookCheckNegativeStep(value: string) {
    this.bookCheckNegativeStep = value;
  }

  @action
  async bookCheck() {
    const { errorHandler } = this.props;
    try {
      const response = await workflow!.bookCheck();
      if (!!response.booking?.bookFailedMessageHtml || !response.booking.isValid) {
        this.setBookCheckNegativeMessage(response.booking.bookFailedMessageHtml);
        this.setBookCheckNegativeStep(response.booking.jumpToStep)
      }
    } catch (e) {
      errorHandler!.catchError(e);
    }
  }

  async parseMeta() {
    const meta = await getInstance<Meta, {}>(Meta, toJS(this.props.workflow!.currentMeta));
    action(() => {
      this.meta = meta;
    })();
  }

  @action
  handleBookCheckNegative() {
    this.bookCheckNegativeMessage = null;
    if (this.bookCheckNegativeStep) {
      if (this.bookCheckNegativeStep.startsWith('pep-')) {
        workflow.navigateToStep(this.bookCheckNegativeStep);
      } else if (this.bookCheckNegativeStep.includes('close-tab')) {
        window.top.close();
      } else {
        window.location.href = this.bookCheckNegativeStep;
      }
    }
  }

  render() {
    const styles = this.props.theme === 'pepxcite' ? pepxciteStyles : pepxpressStyles;
    const css = conditionalStyles(styles);
    const right = <Cart bookingOnRequest={workflow.bookingOnRequest} />;

    const top = (
      <div>
        <Headline text={i18next.t(Key.headline)} />
        {this.props.workflow!.paymentErrorKey && (
          <Alert color={'danger'}>{i18next.t(this.props.workflow!.paymentErrorKey)}</Alert>
        )}
        {this.props.workflow!.paymentErrorMsg && (
          <Alert color={'danger'}>{i18next.t(this.props.workflow!.paymentErrorMsg)}</Alert>
        )}
      </div>
    );

    const left = (
      <div>
        <div className={styles.section}>
          <ContactData person={this.meta.businessPartner} />
        </div>

        <div className={styles.section}>
          <h5>{i18next.t(Key.Participants)}</h5>
          <ParticipantsData passengers={this.meta.passengers} />
        </div>

        {!workflow.isFlightWorkflow && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.includedItems)}</h5>
            <CartIncludedItems items={this.props.workflow!.selectedItems} />
          </div>
        )}

        {this.props.workflow!.hasExtraNights && (
          <div className={styles.section}>
            <h5>{i18next.t(Key.extraNights)}</h5>
            <ExtraNights items={this.props.workflow!.selectedItems} styles={styles} />
          </div>
        )}

        <div className={styles.section}>
          <h5>{i18next.t(Key.completeBooking)}</h5>

          <ScrollTo name={this.genericErrorName}>
            <Alert color={'warning'} isOpen={this.isErrorVisible}>
              {i18next.t(Key.notesAlert)}
            </Alert>
            <Alert color={'warning'} isOpen={!!this.apiErrorMessage}>
              {this.apiErrorMessage}
            </Alert>
          </ScrollTo>

          <div
            className={css('optionInput', 'optionBorder', {
              'optionBorder--isSelected': this.isLegalChecked
            })}
            tabIndex={0}
            onKeyPress={this.toggleLegalOnEnter.bind(this)}
          >
            <input
              type={'checkbox'}
              id={'isLegalChecked'}
              onChange={this.toggleLegal.bind(this)}
              checked={this.isLegalChecked}
            />
            <label htmlFor={'isLegalChecked'} className={styles.optionLabel}>
              {i18next.t(Key.legalFirst)}
              {this.termsAndConditionList &&
                this.termsAndConditionList.length > 0 &&
                this.termsAndConditionList.map((item: TC, i: number) => {
                  return (
                    <div key={i}>
                      {i > 0 && <>{', '}</>}
                      <a target={'_blank'} href={item.url}>
                        {item.title}
                      </a>
                    </div>
                  );
                })}
              <Trans i18nKey={Key.legalSecond}>
                {/* 1 */} {' und die '}
                {/* 2 */}
                <a target={'_blank'} href={this.props.theme === 'pepxcite'
                  ? 'https://www.pepxcite.com/de/hilfe/datenschutz'
                  : 'https://www.pepxpress.com/de/info/hilfe/datenschutz'}>
                  Datenschutzbestimmungen
                </a>
                {/* 3 */}
                {' von '}
                {/* 4 */}
                <PepText text={this.props.theme === 'pepxcite' ? 'pepXcite' : 'pepXpress'}/>
                {/* 5 */}.
              </Trans>
            </label>
          </div>

          <div
            className={css('optionInput', 'optionBorder', {
              'optionBorder--isSelected': this.isNotesChecked
            })}
            tabIndex={0}
            onKeyPress={this.toggleNotesOnEnter.bind(this)}
          >
            <input
              type={'checkbox'}
              id={'isNotesChecked'}
              onChange={this.toggleNotes.bind(this)}
              checked={this.isNotesChecked}
            />
            <label htmlFor={'isNotesChecked'} className={styles.optionLabel}>
              <div>
                {this.hasNotes ? (
                  <Trans i18nKey={Key.legal2}>
                    Ich habe die
                    {/* eslint-disable-next-line */}
                    <a href="#" onClick={this.toggleNotesExpanded.bind(this)}>
                      Hinweise zur Buchung
                    </a>
                    sowie die
                    <a
                      target={'_blank'}
                      href={this.props.theme === 'pepxcite'
                        ? 'https://www.pepxcite.com/de/hilfe/reiseinfos'
                        : 'https://www.pepxpress.com/de/info/hilfe/impf-und-einreisebestimmungen'}
                    >
                      Einreise- und Gesundheitsbedingungen des Reiselandes
                    </a>
                    gelesen.
                  </Trans>
                ) : (
                  <Trans i18nKey={Key.legal3}>
                    Ich habe die
                    <a
                      target={'_blank'}
                      href={this.props.theme === 'pepxcite'
                        ? 'https://www.pepxcite.com/de/hilfe/reiseinfos'
                        : 'https://www.pepxpress.com/de/info/hilfe/impf-und-einreisebestimmungen'}
                    >
                      Einreise- und Gesundheitsbedingungen des Reiselandes
                    </a>
                    gelesen.
                  </Trans>
                )}
              </div>
              {this.hasNotes && (
                <Collapse isOpen={this.isNotesExpanded}>
                  {this.notes.map((n: Note, i) => (
                    <div className={styles.note} key={'note_' + i}>
                      <TiInfoLarge />
                      <div dangerouslySetInnerHTML={{ __html: n.title }}/>
                    </div>
                  ))}
                </Collapse>
              )}
            </label>
          </div>

          {this.isSubscribeCheckboxVisible && (
            <div
              className={css('optionInput', 'optionBorder', {
                'optionBorder--isSelected': this.isNewsletterChecked
              })}
              tabIndex={0}
              onKeyPress={this.toggleNewsletterOnEnter.bind(this)}
            >
              <input
                type={'checkbox'}
                id={'isNewsletterChecked'}
                onChange={this.toggleNewsletter.bind(this)}
                checked={this.isNewsletterChecked}
              />
              <label htmlFor={'isNewsletterChecked'} className={styles.optionLabel}>
                {i18next.t(Key.newsletter, {brand: this.props.theme === 'pepxcite' ? 'pepXcite' : 'pepXpress'})}
              </label>
            </div>
          )}
        </div>

        <Modal isOpen={!!this.bookCheckNegativeMessage} size="md">
          <ModalBody>
            <div dangerouslySetInnerHTML={{__html: this.bookCheckNegativeMessage || ''}}/>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.handleBookCheckNegative()}>
              {this.bookCheckNegativeStep?.includes('close-tab')
                ? i18next.t(Key.validationModal.closeTab)
                : i18next.t(Key.validationModal.confirm)}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );

    const bottom = (
      <div className={styles.section}>
        <Nav
          onPrev={this.prev.bind(this)}
          onNext={this.next.bind(this)}
          textNext={i18next.t(Key.bookNow)}
          nextDisabled={!!this.bookCheckNegativeMessage || this.isLoading}
        />
        {this.props.workflow!.needExternalPayment === true && (
          <div className={styles.paymentRedirect}>
            {i18next.t(Key.paymentRedirect1)}
            <br />
            {i18next.t(Key.paymentRedirect2)}
          </div>
        )}
      </div>
    );

    return (
      <>
        <Breadcrumbs next={() => this.next()} />
        <DefaultLayout top={top} left={left} right={right} bottom={bottom} />
      </>
    );
  }

  @action
  toggleLegal() {
    this.isLegalChecked = !this.isLegalChecked;
  }

  @action
  toggleNotes() {
    this.isNotesChecked = !this.isNotesChecked;
  }

  @action
  toggleNewsletter() {
    this.isNewsletterChecked = !this.isNewsletterChecked;
  }

  @action
  async toggleNewsletterOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleNewsletter();
    }
  }

  @action
  async toggleNotesOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleNotes();
    }
  }

  @action
  async toggleLegalOnEnter(ev: React.KeyboardEvent) {
    if (ev.key === 'Enter') {
      await this.toggleLegal();
    }
  }

  @action
  toggleNotesExpanded(e: React.MouseEvent) {
    e.preventDefault();
    this.isNotesExpanded = !this.isNotesExpanded;
  }

  @computed
  get isSubscribeCheckboxVisible() {
    return this.meta.businessPartner.hasNewsletterSubscription;
  }

  @computed
  get isErrorVisible() {
    return this.isSubmitted && (!this.isLegalChecked || !this.isNotesChecked);
  }

  @action
  next() {
    this.isSubmitted = true;
    if (this.isErrorVisible) {
      this.scrollToError();
    } else {
      this.props.app!.activateLoader();
      if (this.props.workflow!.needExternalPayment === true) {
        this.props
          .workflow!.preparePayment()
          .then(paymentUrl => (window.top.location.href = paymentUrl))
          .catch(e =>
            runInAction(() => {
              this.apiErrorMessage = i18next.t(Key.preparePaymentError);
              this.props.app!.deactivateLoader();
            })
          );
      } else {
        this.props.app!.activateLoader();
        this.apiErrorMessage = null;
        this.saveStep()
          .then(() => {
            this.props.workflow!.navigateToNextStep();
          })
          .catch(e => {
            action(() => {
              this.apiErrorMessage = e.message;
            })();
            this.props.app!.deactivateLoader();
            this.scrollToError();
          });
      }
    }
  }

  scrollToError() {
    scroller.scrollTo(this.genericErrorName, {
      duration: 1000,
      smooth: true,
      offset: -20
    });
  }

  async saveStep() {
    const app = this.props.app!;
    const workflow = this.props.workflow!;

    let bookingNumber;
    let totalPrice;

    app.trackTimeSaveStarted(workflow);

    try {
      const payload = new PayloadOverview();

      payload.acceptTermsConditionAndDataPolicy = this.isLegalChecked;
      payload.acceptRemarks = this.isNotesChecked;
      payload.subscribeToNewsletter = this.isNewsletterChecked;

      await workflow.saveStep(payload);

      bookingNumber = workflow.booking!.bookingNumber;
      totalPrice = workflow.booking!.totalPrice;
    } catch (e) {
      bookingNumber = null;
      totalPrice = null;
      throw e;
    }

    app.trackTimeSaveFinished(workflow);

    if (bookingNumber && totalPrice) {
      workflow.persistBooking();
    }
  }

  prev() {
    this.props.workflow!.navigateToPrevStep();
  }

  @computed
  get notes() {
    return this.props.workflow!.selectedItems.filter(Overview.onlyNotes);
  }

  @computed
  get hasNotes() {
    return this.notes.length > 0;
  }

  static onlyNotes(i: any) {
    return i instanceof Note;
  }
}

function getInitialMeta(): Meta {
  return {
    businessPartner: new BusinessPartner(),
    passengers: []
  };
}
export default withTheme(Overview);
