const transitionEvent = whichTransitionEvent();

export default transitionEvent;

function whichTransitionEvent() : string {
    const el = document.createElement( 'fakeelement' ) as HTMLElement;
    
    const transitions : { [ key : string ] : string } = {
        'transition'       : 'transitionend',
        'OTransition'      : 'oTransitionEnd',
        'MozTransition'    : 'transitionend',
        'WebkitTransition' : 'webkitTransitionEnd'
    };
    
    var which = '';
    Object.keys( transitions ).forEach( ( t : string ) => {
        if ( el.style.hasOwnProperty( t ) ) {
            which = transitions[ t ];
        }
    } );
    
    return which;
}
